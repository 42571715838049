.home-no-swiper-container {
  @include mobile {
    display: block;
  }
  @include landscape {
    display: block;
  }

  &:not(.news-swiper) {

    .home-item {

      background: transparent;
      &__container {
        padding-top: 0;
        color: $color-white;
      }
    }

  }

  &.news-swiper {
    .home-item {
      &:hover,
      &:focus {
        box-shadow: 0 4px 13px 0 rgba(0, 0, 0, .4);
      }
    }
  }

}


.home-block .news-swiper .link__icon svg {
  fill: $color-black;
}
//mobile swiper
.home-swiper-container {

  @include mobile-only {
    margin-bottom: 20px;
  }

  .home-item {

    background: transparent;
    &__container {
      padding-top: 20px;
      color: $color-white;
    }
  }

  @include idangerous-swiper-base($pagination:true);

  @include landscape {
    display: none;
  }
  .swiper-button--prev,
  .swiper-button--next {
    display: inline-block;
    height: 20px;
    margin: 0px 2px;
  }
  .swiper-pagination {
    display: inline-block;
    height: 20px;
    margin: 0 13px;
    position: relative;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid $color-white;
    opacity: 1;

    &-active {
      background: $color-white;
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .swiper {
    &-navigation {
      display: flex;
      justify-content: center;
    }

    &-button--next {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
