// font definitions go here

// Use the same font-family name for different weights/variants

// Example:

@font-face {
  font-family: 'Foundry Monoline';
  src:
    static-url('fonts/343c931d-1f3d-444a-bce1-f3ed83e9f245.woff2') format('woff2'),
    static-url('fonts/e089faeb-6b44-4090-91fc-906aec84c145.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Foundry Monoline';
  src:
    static-url('fonts/06360e61-af51-48c4-abf9-470a879168cb.woff2') format('woff2'),
    static-url('fonts/521b91a5-9beb-42a7-8f83-eddf02ee1290.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Foundry Monoline';
  src:
    static-url('fonts/4a7f9937-dcef-476e-a87e-93d9ceb78502.woff2') format('woff2'),
    static-url('fonts/64801b4f-134a-4c23-a1bf-2f6fe6a479ab.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Foundry Monoline';
  src:
    static-url('fonts/bad05f96-5196-4e32-ba19-fd8a9cb00b7e.woff2') format('woff2'),
    static-url('fonts/c9f7fefc-34c7-437e-bbf0-664b1258bd0a.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

$font-stack: 'Foundry Monoline', 'Helvetica', 'Arial', sans-serif;
