
@mixin steps-bar {
  .steps-bar__item__title,
  .steps-bar__item__index {
    @include typography-paragraph;
  }

  @include mobile {
    background: $color-white;
    color: $color-black;
    overflow: hidden;

    .steps-bar__items {
      display: flex;
      flex-direction: row;
    }

    .steps-bar__item {
      flex: 1;
      white-space: nowrap;

      &.steps-bar__item--active {
        background: $color-yellow;
        color: $color-black;
      }

      &.steps-bar__item--disabled {
        background: $color-white;
        color: $color-light-grey;
      }

      &.steps-bar__item--active,
      &.steps-bar__item--disabled {
        pointer-events: none;
      }
    }

    .steps-bar__item__anchor {
      display: block;
      padding: 13px;
    }

    .steps-bar__item__index {
      border: 1px solid currentColor;
      border-radius: 3px;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      margin: 3px;
      text-align: center;
      transition: background-color $cubic-200, color $cubic-200, border-color $cubic-200;
      vertical-align: middle;
      width: 20px;

      + .steps-bar__item__title {
        margin-left: 3px;
      }
    }

    .steps-bar__item__title {
      display: inline-block;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

@mixin steps-bar-collapse {
  @include mobile-portrait-only {
    .steps-bar__item {
      &.steps-bar__item--active {
        flex: 4;
      }

      &:not(.steps-bar__item--active) {
        .steps-bar__item__title {
          display: none;
        }
      }
    }
  }
}

@mixin steps-bar-borders {
  @include mobile {
    .steps-bar__item {
      border-bottom: 1px solid $color-light-grey;
      border-right: 1px solid $color-light-grey;

      &.steps-bar__item--active {
        border-color: $color-yellow;
      }

      &.steps-bar__item--disabled {
        border-color: $color-light-grey;
      }

      &:first-child {
        border-left-width: 0;
      }

      &:last-child {
        border-right-width: 0;
      }
    }
  }
}

@mixin steps-bar-hover-states {
  @include mobile {
    .steps-bar__item {
      &:hover {
        .steps-bar__item__index {
          background: $color-black;
          border-color: $color-black;
          color: $color-white;
        }

        &.steps-bar__item--active {
          .steps-bar__item__index {
            background: $color-black;
            border-color: $color-black;
            color: $color-yellow;
          }
        }

        &.steps-bar__item--disabled {
          .steps-bar__item__index {
            background: $color-white;
            border-color: $color-light-grey;
            color: $color-white;
          }
        }
      }
    }
  }
}

.steps-bar {
  @include layout(6, 12, 12, 12);
  @include steps-bar;
  @include steps-bar-collapse;
  @include steps-bar-borders;
  @include steps-bar-hover-states;
}
