
@mixin idangerous-swiper-arrows {
  .swiper-button-prev,
  .swiper-button-next {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 27px 44px;
    cursor: pointer;
    height: 44px;
    margin-top: -22px;
    position: absolute;
    top: 50%;
    width: 27px;
    z-index: 10;

    &.swiper-button-disabled {
      cursor: auto;
      opacity: .35;
      pointer-events: none;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    content: '>';
    left: 10px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    content: '<';
    left: auto;
    right: 10px;
  }
}

@mixin idangerous-swiper-pagination {
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transform: translate3d(0, 0, 0);
    transition: 300ms;
    z-index: 10;

    &.swiper-pagination-hidden {
      opacity: 0;
    }
  }

  .swiper-pagination-bullet {
    background: #000;
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    opacity: .2;
    width: 8px;
  }

  button.swiper-pagination-bullet {
    appearance: none;
    border-width: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .swiper-pagination-clickable {
    .swiper-pagination-bullet {
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    background: #007aff;
    opacity: 1;
  }

  .swiper-container-vertical {
    > .swiper-pagination {
      right: 10px;
      top: 50%;
      transform: translate3d(0, -50%, 0);

      .swiper-pagination-bullet {
        display: block;
        margin: 5px 0;
      }
    }
  }

  .swiper-container-horizontal {
    > .swiper-pagination {
      bottom: 10px;
      left: 0;
      width: 100%;

      .swiper-pagination-bullet {
        margin: 0 5px;
      }
    }
  }
}

@mixin idangerous-swiper-3d {
  .swiper-container-3d {
    perspective: 1200px;

    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
      transform-style: preserve-3d;
    }

    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }

    .swiper-slide-shadow-left {
      background-image: linear-gradient(to left, rgba($color-black, .5), rgba($color-black, 0));
    }

    .swiper-slide-shadow-right {
      background-image: linear-gradient(to right, rgba($color-black, .5), rgba($color-black, 0));
    }

    .swiper-slide-shadow-top {
      background-image: linear-gradient(to top, rgba($color-black, .5), rgba($color-black, 0));
    }

    .swiper-slide-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba($color-black, .5), rgba($color-black, 0));
    }
  }
}

@mixin idangerous-swiper-coverflow {
  .swiper-container-coverflow {
    .swiper-wrapper {
      // Windows 8 IE 10 fix
      -ms-perspective: 1200px; /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
}

@mixin idangerous-swiper-fade {
  .swiper-container-fade {
    .swiper-slide,
    .swiper-slide .swiper-slide { // Is this needed? double?
      pointer-events: none;
    }

    .swiper-slide-active,
    .swiper-slide-active .swiper-slide-active { // Is this needed? double?
      pointer-events: auto;
    }

    &.swiper-container-free-mode {
      .swiper-slide {
        transition-timing-function: ease-out;
      }
    }
  }
}

@mixin idangerous-swiper-cube {
  .swiper-container-cube {
    overflow: visible;

    .swiper-slide {
      backface-visibility: hidden;
      height: 100%;
      pointer-events: none;
      transform-origin: 0 0;
      visibility: hidden;
      width: 100%;
    }

    &.swiper-container-rtl {
      .swiper-slide {
        transform-origin: 100% 0;
      }
    }

    .swiper-slide-active,
    .swiper-slide-next,
    .swiper-slide-prev,
    .swiper-slide-next + .swiper-slide {
      pointer-events: auto;
      visibility: visible;
    }

    .swiper-cube-shadow {
      background: #000;
      bottom: 0;
      filter: blur(50px);
      height: 100%;
      left: 0;
      opacity: .6;
      position: absolute;
      width: 100%;
    }

    &.swiper-container-vertical {
      .swiper-cube-shadow {
        z-index: 0;
      }
    }
  }
}

@mixin idangerous-swiper-scrollbar {
  .swiper-scrollbar {
    background: rgba($color-black, .1);
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none; /* stylelint-disable-line property-no-vendor-prefix */
  }

  .swiper-container-horizontal {
    > .swiper-scrollbar {
      bottom: 3px;
      height: 5px;
      left: 1%;
      position: absolute;
      width: 98%;
      z-index: 50;
    }
  }

  .swiper-container-vertical {
    > .swiper-scrollbar {
      height: 98%;
      position: absolute;
      right: 3px;
      top: 1%;
      width: 5px;
      z-index: 50;
    }
  }

  .swiper-scrollbar-drag {
    background: rgba($color-black, .5);
    border-radius: 10px;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }
}

@mixin idangerous-swiper-preloader {
  .swiper-lazy-preloader {
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
    height: 42px;
    left: 50%;
    margin-left: -21px;
    margin-top: -21px;
    position: absolute;
    top: 50%;
    transform-origin: 50%;
    width: 42px;
    z-index: 10;

    &::after {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '...';
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  @keyframes swiper-preloader-spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin idangerous-swiper-base($arrows: false, $pagination: false, $container-3d: false, $coverflow: false, $fade: false, $cube: false, $scrollbar: false, $preloader: false) {
  .swiper-container {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1; // Webkit flickering fix

    // a11y
    .swiper-notification {
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      z-index: -1000;
    }
  }

  .swiper-container-no-flexbox {
    .swiper-slide {
      float: left;
    }
  }

  .swiper-container-vertical {
    > .swiper-wrapper {
      flex-direction: column;
    }
  }

  .swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition-property: transform;
    width: 100%;
    z-index: 1;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out;
  }

  .swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    width: 100%;
  }

  // IE10 Windows Phone 8 Fixes
  .swiper-wp8-horizontal {
    touch-action: pan-y;
  }

  .swiper-wp8-vertical {
    touch-action: pan-x;
  }

  @if ($arrows) {
    @include idangerous-swiper-arrows;
  }

  @if ($pagination) {
    @include idangerous-swiper-pagination;
  }

  @if ($container-3d) {
    @include idangerous-swiper-3d;
  }

  @if ($coverflow) {
    @include idangerous-swiper-coverflow;
  }

  @if ($fade) {
    @include idangerous-swiper-fade;
  }

  @if ($cube) {
    @include idangerous-swiper-cube;
  }

  @if ($scrollbar) {
    @include idangerous-swiper-scrollbar;
  }

  @if ($preloader) {
    @include idangerous-swiper-preloader;
  }
}
