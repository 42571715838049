.page-header {
  color: $color-white;
  position: relative;
  min-height: 160px;
  @include container;

  &__container {

    @include ui-state;
    @include layout(6,12,12,12);

    /* BlackBerry menu fix */
    @include mobile-only() {
      margin-top: 70px;
    }
  }

  margin-bottom: 40px;
  @include desktop {
    min-height: 250px;
    margin-bottom: 100px;
  }
}

.image__background_fixed {
  display: block;
  .playing & { // on homepage when video is playing dont show
    display: none;
  }
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
}

.content-header__title {
  @include typography-h1;
  position: absolute;
  bottom: 0;
}

.content-header__subtitle {
  @include typography-h4;
  @include mobile-only {
    max-width: 260px;
  }
  margin-bottom: 29px;
  @include portrait {
    margin-bottom: 15px;
  }
}
