//menu
.menu-row {
  @include layout(6, 12, 12, 12);
  background: white;
  display: flex;
  list-style: none;
  height: 60px;
  @include mobile-only {
    width: 100%;
    margin-left: 0;
  }
  @include grid-media($portrait-grid) {
    width: 98%;
    margin: 0 -10px 0 10px;
  }
  @include grid-media($landscape-grid) {
    margin: 0;
    @include layout(6, 12, 12, 12);
  }
}

.menu-item {
  width: 20%;
  background: white;
  border: 1px solid #D3D3D3;
  border-top: none;
  border-left: none;
  padding: 16px 0 19px 18px;
  box-sizing: content-box;
  &:hover {
    border-color: $color-yellow;
    background: $color-yellow;
    &:last-of-type {
      border-right: 1px solid $color-yellow;
    }
  }

  &:last-of-type {
    border-right: 1px solid white;
  }
}

.menu-item--active {
  border-color: $color-yellow;
  background: $color-yellow;
}

.menu-link {
  @include typography-undertitle;
}
//white block vertical mijnpassen+mytesohome
.white-row {
  background: white;
  @include layout(6, 12, 12, 12);
  height: 60px;
  @include mobile-only {
    width: 100%;
    margin-left: 0;
  }
}
