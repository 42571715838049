.price-pair-block {

  @include layout(6, 8, 8, 8);
  @include offset(0, 2, 2, 2);
  @include column_padding(.7, 1, 1, 1);
  @include content-theme(false);

  padding-top: 0px;

  span {
    @include typography-paragraph;
    line-height: 1.4;
    margin-bottom: 10px;

    &.align-left {
      display: inline-block;
      vertical-align: top;
      width: 60%;
      padding-right: 10px;
      @include landscape {
        width: 40%;
      }
    }
    &.align-right {
      display: inline-block;
      vertical-align: top;
    }
  }

}
