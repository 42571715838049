
.form-errors {
  margin: 0px 0px 20px 0px;
  @include typography-paragraph;
  p {
    font-weight: 700;
  }
  .field-errors {
    color: $color-red;
  }
  strong {
    font-weight: 700;
  }

  &.hidden {
    display: none;
  }
}

@mixin form-field {
  .form-field__text {
    @include typography-list;
  }

  @include mobile {
    position: relative;

    .form-field__field-wrapper {
      display: block;
      max-width: 100%;
      position: relative;
    }

    .form-field__text {
      margin: 5px 0;
    }

    .form-field__label {
      font-weight: bold;
    }

    input,
    textarea,
    select {
      flex: 1 1 100%;
    }

    input:not([type='radio']):not([type='checkbox']) {
      padding-left: 5px !important;
    }

    input:not([type='radio']):not([type='checkbox']):not([type='file']),
    textarea,
    select {
      width: 100%;
    }

    input:not([type='radio']):not([type='checkbox']):not([type='file']) {
      @include placeholder {
        color: inherit;
      }
    }

    label {
      @include disable-user-select;

      &.is-hidden {
        display: none;
      }
    }

    .form-field__error-wrapper {
      margin: 5px 0;
    }
  }

  .form-field__label {
    font-weight: bold;
  }

  @include landscape {
    .form-field {
      margin-left: 20px;
    }
  }

  &.is-hidden {
    @include mobile {
      display: none;
    }
  }
}

@mixin form-field__label {
  font-weight: bold;
  @include typography-list;

  &:first-letter {
    text-transform: uppercase;
  }

  @include mobile {
    @include typography-link;
    cursor: pointer;
    display: block;
    font-weight: 500;
    margin: 0px 0px 5px 0;
    min-height: 24px;
  }
}

@mixin form-field--input {
  //@include typography-button;
@include typography-undertitle;
  @include mobile {
    $border-width: 1px;
    background-color: #f7f7f7;
    border:none;
    border-bottom: $border-width solid #E9E9E9;
    color: currentColor;
    display: inline-block;
    height: 40px;
    line-height: 22px;
    margin: 0;
    min-height: 40px;
    min-width: 50px;
    overflow: hidden;
    padding: #{5px - $border-width} 0;
    position: relative;
    transition: color $cubic-200, background-color $cubic-200, border-color $cubic-200;
    vertical-align: bottom;
    white-space: nowrap;

    &:hover,
    &:focus {
      background-color: rgba($color-black, .1);
    }

    + .button {
      border-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
        color: inherit;
      }
    }
  }
}

@mixin form-field--input--disabled {
  @include mobile {
    &:disabled,
    &.is-disabled {
      opacity: .4;
      pointer-events: none;
    }
  }
}

.birthdate-dropdown {

  margin-bottom: 15px;

  .form-field__select-container {
    display: flex;

    .birthdate_day,
    .holder_birthdate_day {
      flex-grow: 1;
      flex-basis: 0;
      margin-right: 10px;
      position: relative;
      &::after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 40px 40px;
        color: currentColor;
        content: '';
        height: 40px;
        line-height: 40px;
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 40px;
      }
    }
    .birthdate_month,
    .holder_birthdate_month {
      flex-grow: 3;
      flex-basis: 0;
      margin-right: 10px;
      position: relative;
      &::after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 40px 40px;
        color: currentColor;
        content: '';
        height: 40px;
        line-height: 40px;
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 40px;
      }
    }
    .birthdate_year,
    .holder_birthdate_year {
      flex-grow: 2; // TODO check this logic of widths
      flex-basis: 0;
      position: relative;
    }
  }
}


@mixin form-field--dropdown {
  display: block;

  select {
    border-radius: 0;
    cursor: pointer;
    padding-right: 38px;
    width: 100%;

    option {
      color: $color-black;
    }
  }

  .form-field__select-container {
    position: relative;
    width: 100%;

    &::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==');
      background-position: center right;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      color: currentColor;
      content: '';
      height: 40px;
      line-height: 40px;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 40px;
    }
  }
}

@mixin form-field--radio {
  input[type='radio'] {
    + label {
      //@include typography-paragraph($margins: false);

      text-transform: none;

      a {
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  @include mobile {
    input[type='radio'] {
      float: left;
      height: 0;
      margin-left: -150vw;
      width: 0;

      + label {
        display: block;
        font-weight: normal;
        padding-left: 20px;
        width: 100%;

        &::before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNNDYwLjUgMzMyLjVjMTQxLjM0IDAgMjU2IDExNC42NiAyNTYgMjU2cy0xMTQuNjYgMjU2LTI1NiAyNTZjLTE0MS4zODEgMC0yNTYtMTE0LjY2LTI1Ni0yNTZzMTE0LjYxOS0yNTYgMjU2LTI1NnoiLz48L3N2Zz4=');
          background-repeat: no-repeat;
          background-size: 0 0;
          border: 1px solid $color-black;
          border-radius: 50%;
          color: transparent;
          content: '';
          display: inline-block;
          float: left;
          height: 15px;
          line-height: 13px;
          margin-left: -20px;
          margin-right: 10px;
          text-align: center;
          transition: border-color $cubic-200, color $cubic-200;
          width: 15px;
        }

        + input {
          + label {
            margin-top: 10px;
          }
        }
      }

      &:checked,
      &.is-checked {
        + label {
          &::before {
            background-size: 13px 13px;
            color: currentColor;
            line-height: 13px;
          }
        }
      }
    }
  }
}

@mixin form-field--radio--inline {
  @include mobile {
    input[type='radio'] {
      + label {
        display: inline-block;
        margin-right: 20px;
        width: auto;
      }
    }
  }
}

@mixin form-field--checkbox {
  input[type='checkbox'] {
    + label {
      //@include typography-paragraph($margins: false);

      text-transform: none;
    }
  }

  @include mobile {
    input[type='checkbox'] {
      height: 0;
      margin-left: -150vw;
      width: 0;

      + label {
        display: block;
        font-weight: normal;
        padding-left: 0px;
        width: 100%;
        cursor: pointer;
        line-height: 26px;
        color: $flatpickr-light-grey;

        &::before {
          //background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPjxwYXRoIGZpbGw9IiNDMzNFNEUiIGQ9Ik04MDMuODQgMzEyLjMybC02Ni4wOTUtNjYuMDk1LTIxMi43MTMgMjEzLjE3OEwzMTIuMzIgMjQ2LjIyNWwtNjYuMDk1IDY2LjA5NSAyMTMuMTc4IDIxMi43MTItMjEzLjE3OCAyMTIuNzEzIDY2LjA5NSA2Ni4wOTUgMjEyLjcxMi0yMTMuMTc4TDczNy43NDUgODAzLjg0bDY2LjA5NS02Ni4wOTUtMjEzLjE3OC0yMTIuNzEzeiIvPjwvc3ZnPg==');
          background-color: $color-white;
          background-repeat: no-repeat;
          background-size: 20px 20px;
          border: 1px solid $color-black;
          border-radius: 3px;
          color: transparent;
          content: '';
          display: inline-block;
          float: left;
          height: 20px;
          width: 20px;
          line-height: 20px;
          margin-right: 12px;
          margin-bottom: 2em;
          text-align: center;
          transition: border-color $cubic-200, color $cubic-200;
          position: relative;
          top: 3px;
        }

        a {
          text-decoration: underline;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        + input {
          + label {
            margin-top: 10px;
          }
        }
      }

      &:checked,
      &.is-checked {
        + label {
          &::before {
            background-image: url('/static/icons/teso-icon-checkmark.svg');
            //background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPjxwYXRoIGZpbGw9IiMxQ0I2NkIiIGQ9Ik00OTQuNzc4IDgzMS43NjdMMjAwLjE0NiA1NDUuOTc4bDY1LjE2My02Ny4wMjYgMjE0LjU3NSAyMDguNTI0IDI5NS41NjMtNDM0LjI2OSA3Ni44IDUyLjEzMXoiLz48L3N2Zz4=');
            color: currentColor;
            line-height: 13px;
          }
        }
      }
    }
  }
}

@mixin form-field--checkbox--inline {
  @include mobile {
    input[type='checkbox'] {
      + label {
        display: inline-block;
        margin-right: 20px;
        width: auto;
      }
    }
  }
}

@mixin form-field--textarea {
  @include mobile {
    textarea {
      min-height: 10em;
    }
  }
}

@mixin form-field--required {
  @include mobile {
    //
  }
}

@mixin form-field--invalid {
  @include mobile {
    input:not([type='radio']):not([type='checkbox']),
    textarea,
    select {
      &.has-activated:invalid {
        border-color: $color-red !important;
      }
    }

    input[type='radio'],
    input[type='checkbox'] {
      &.has-activated:invalid {
        + label {
          &::before {
            border-color: $color-red !important;
          }
        }
      }
    }
  }
}

@mixin form-field--choice-list {
  .item__title {
    @include typography-intro;
  }

  .item__text {
    @include typography-list;
  }

  input[type='radio'] {
    + label {
      //text-transform: none;
    }
  }

  @include mobile {
    .item__icon {
      clear: left;
      float: left;
      height: 30px;
      width: 30px;
    }


    .checkout-page--ticket-choice & {
      .item__icon {
        width: 36px;
        height: 36px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .item__title,
    .item__text {
      display: block;
      padding-left: 45px;
    }

    .item__text {
      font-weight: 500;
      color: $color-grey;
    }

    input[type='radio'] {
      float: left;
      height: 0;
      margin-left: -150vw;
      width: 0;

      + label {
        display: block;
        font-weight: normal;
        padding: 16px;
        width: 100%;
        border: 1px solid $color-light-grey;
        border-radius: 3px;
        margin: 20px 0;

        //+ input {
        //  + label {
        //    margin-top: 20px;
        //  }
        //}
        &:hover {
          background-color: $flatpickr-grey;
        }
      }

      &:checked,
      &.is-checked {
        + label {
          border: 2px solid $color-yellow;
          padding: 15px;
        }
      }
    }
  }
}

.form__row {
  &.has-errors,
  .has-errors {

    input[type="text"],
    input[type="email"],
    input[type="number"] {
      border-bottom: 1px solid $color-red !important;
    }
    input[type="radio"] {
      & + label {
        border-bottom: 1px solid $color-red !important;
      }
      &:checked + label,
      &.is-checked + label {
        border: 2px solid $color-yellow !important;
      }
    }

    select {
      border-bottom: 1px solid $color-red !important;
    }
  }

  &.account_prefilled {
    display: block;
    padding-bottom: 15px;
    span {
      @include form-field__label;
      @include mobile {
        cursor: default;
      }
    }
  }
  p {
    @include typography-list;
    min-height: 40px;
    line-height: 22px;
  }

  &--small {
    p {
      min-height: 0;
    }
  }

  .form__col {
    width: 100%;

    .form-field--single-line {
      margin-bottom: 0;
    }

    .form-field--checkboxes input[type='checkbox'] + label {
      color: $color-black;
    }
  }

  .form-field__field-wrapper .form-field--dropdown {
    margin-left: 0;
  }
}

.form-field {
  @include form-field;
  @include form-field--invalid;

  label {
    @include form-field__label;
  }

  input:not([type='radio']):not([type='checkbox']):not([type='file']),
  textarea,
  select {
    @include form-field--input;
    @include form-field--input--disabled;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &.form-field--single-line {
    margin-bottom: 15px;
    //
  }

  &.form-field--number {

    .field-legend {
      float: left;
    }

    .form-field__field-wrapper {
      width: 180px;
      float: right;

      // kill the annoying arrows
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        width: 48px;
        margin: 0px 10px;
        font-size: 15px;
        border-radius: 3px;
        line-height: 40px;
        font-weight: 500;
        border: 1px solid $color-light-grey;
        text-align: center;
        background-color: transparent;
      }
      a.counter_button {
        display: inline-block;
        border-radius: 3px;
        height: 40px;
        width: 48px;
        border: 1px solid $color-light-grey;
        line-height: 40px;
        text-align: center;
        &:hover {
          background: $color-yellow;
        }
      }

    }

  }

  &.form-field--multi-line {
    @include form-field--textarea;
  }

  &.form-field--dropdown {
    @include form-field--dropdown;
  }

  &.form-field--radio-buttons {
    //@include form-field--radio;
    //&.form-field--inline {
    //  @include form-field--radio--inline;
    //}
    @include form-field--choice-list;
  }

  &.form-field--checkboxes {
    @include form-field--checkbox;

    @include clearfix;

    &.form-field--inline {
      @include form-field--checkbox--inline;
    }
  }

  &.is-required {
    @include form-field--required;
  }
}

.form__row_dropdown {

  margin-bottom: 15px;

  label {
    @include form-field__label;
  }

  .form-field__field-wrapper,
  label {
    flex-grow: 1;
  }

}

.departure_day__container,
.tickets__container {
  label {
    @include form-field__label;
    @include mobile {
      margin-bottom: -10px;
    }
  }
}

.form__row.tickets__container {
  display: block;
}

.form__row--extra-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.form__row--extra-info-image {
  margin-right: 10px;
}

.form__row--extra-info-text {
  font-weight: unset !important; // sorry everyone
  margin-bottom: 10px;
}
