$menuheight: 98px; // 70 + 28 margin
$timesheight: 280px; // roughly

.home-page {
  background-size: cover;
  background-repeat: no-repeat;

  background-color: grey !important;

  .video-top-spacer {
    display: none;
  }

  &.show-one-card {
    .page-header {
      @include mobile-only {
        height: auto;

        &__container {
          margin-top: 30px;
        }

      }
    }
  }

  .page-header {

    $heightcorrection: $menuheight + $timesheight;

    display: flex;
    align-items: center;
    height: calc(90vh - 260px);

    margin-bottom: 0px;

    @include portrait {
      //height: calc(77vh - 230px);
      height: calc(100vh - #{$heightcorrection});
    }

    @include desktop {
      //height: calc(85vh - 230px);
      height: calc(100vh - #{$heightcorrection});
    }

    &__logo {
      width: 14em;
      margin-bottom: 20px;
      @include portrait {
        margin-bottom: 15px;
      }
    }

    @include desktop {
      .page-header__logo {
        width: 16em;
      }
    }
  }

  //&.ui-active .page-header__container {
  //}

  &.no-scroll {
    overflow: hidden;
  }

  /* This needs some refactoring */
  .button {
    margin-right: 1em;

    &--transparent {
      margin-bottom: 10px;
    }
    &--primary {
      margin-top: 0;
      vertical-align: top;
    }
  }

  @include mobile-only() {

    .page-navigation {
      .button--primary {
        margin-top: 0;
      }
    }
  }

  @include mobile-only {
    .home-cta-container {
      display: flex; //swoop swoop!
      flex-wrap: wrap;
      .button--primary {
        order: 1;
        margin-bottom: 10px;
      }
      .button--transparent {
        order: 2;
      }
    }
  }

  .navigation_logo {
    display: none;
  }
  .page-content {
    transition: transform 1s ease 1s;
    &--video-finished {
      transform: translateY(-270px);
    }
  }
}

.cleared {
  clear: both;
}

//login part of the homepage
.home-card-desktop-only {
  display: none;
  @include portrait {
    display: block;
  }
}

.teso-card__homepage {
  padding-top: 0px;
  max-width: 300px;
  display: none;

  @include mobile-only {
    displaY: block;
  }

  @include typography-paragraph;

  .qr_inner {
    width: 50%;
    //padding-bottom: 50%;
    height: 120px;
    position: relative;
    z-index: 5;

    svg {
      background: $color-white;
      width: 100%;
      height: 100%;
      max-height: 100px;
      max-width: 100px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 0;
      transition: transform $easing-change, left $easing-change;
      transform-origin: 0% 0%;
    }

  }

  &.zoomed {
    z-index: 10;
    svg {
      transform: scale(2) translateX(0%);
      left: 0%;
      max-height: 500px;
      max-width: 500px;
    }
    &:before {
      display: block;
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: $color-black;
      position: fixed;
      opacity: 0.4;
      z-index: 3;
    }
  }

  .card-head {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .card_ticket {
    clear: both;
    margin-bottom: 10px;
    height: 21px;
    overflow: hidden;
    .credits {
      float: right;
      width: 16px;
      margin-left: 12px;
      text-align: right;
    }
    .ticket_name {

    }

  }
}

.home-block {
  color: $color-white;

  @include container;

  padding-top: 80px;

  @include portrait {
    padding-top: 110px;
    padding-bottom: 20px;
    @include lowviewport {
      padding-top: 50px;
    }
  }

  &:first-child {
    padding-top: 0;
    padding-bottom: 20px;
    @include lowviewport {
      padding-top: 50px;
    }
  }

  &_intro {
    margin-bottom: 40px;
    //@include container();
    @include layout(6, 8, 8, 6);
    .home-block_heading-h2 {
      margin-bottom: 15px;

      @include typography-h2();
    }

    .home-block_heading-h4 {
      //@include layout(6,12,12,12);
      @include typography-h4();
    }

    .home-block_paragraph {
      //@include layout(6,6,6,6);
      @include typography-paragraph();
    }
  }

  .home-swiper-container {
    clear: left;
  }
  .info_blocks {
    //@include layout(6,12,12,12);
  }

  &_button {
    margin-bottom: 60px;
    @include layout(6, 12, 12, 12);
  }

  &_video {
    @include container;

    &-item {
      @include layout(6, 6, 6, 6);

      @include mobile-only {
        .video-block {
          margin-top: 60px;
        }
      }
    }

    .home-block_heading-h2 {
      margin-bottom: 20px;
      @include typography-h2();
    }

    .home-block_paragraph {
      @include typography-paragraph();
    }
  }

  .link {
    font-weight: bold;
    @include typography-list;
    @include layout(6, 12, 12, 12);

    &__text {
      @include underline($color-white);
    }
  }

  .video-block { // the video player, overrule some stuff for this
    width: 100%;
    margin-left: 0;
  }

  @include ui-state;

  //ui-active, ui-inactive

  //ui-active: transitietijd: 350ms
  //ui-inactive: opacity: 0, transitietijd: 10s
  //ui-reactivate: opacity: 1
}

.departure_time_container {

  .date_info,
  .departure_times {
    @include layout(5.5, 4, 4, 4);
  }

  .scroll-element {
    @include layout(4, 4, 12, 12);
    display: none;
    transition: opacity .5s ease;

    @include landscape {

      display: block;
      text-align: center;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }
    &--clicked {
      opacity: 0;
      pointer-events: none;
    }
  }

  .date_info > p {
    @include typography-paragraph;
    margin-bottom: 10px;
  }

  .departure_times {
    @include portrait {
      text-align: right;
    }
    a {
      margin-top: 34px;
    }
  }

  @include ui-state;
}

.fullscreen_background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  width: 100%;
  height: 100%;
  display: none;
  .playing & {
    display: block;
  }

  .is_mobile & {
    display: none !important;
  }

  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100vh;
    max-width: 177.777778vh; /* 16/9 * 100 = 177.7777778 */
  }
}

.home-video-button-constrainer {
  @include container;
  transition: opacity 1s ease;

  &--hide {
    opacity: 0;
  }

  .home-video.button-container {

    @include layout(6, 12, 12, 12);
    text-align: right;

    @include portrait {
      margin-top: $timesheight - 100px; // mitigate the padding
    }

    .video_button,
    .audio_button {
      margin-bottom: 0px;
      min-width: 180px;
      padding-left: 13px;
      margin-right: 0;
      text-align: left;

      display: none;
      .is_mobile & {
        display: none;
      }
      @include portrait {
        display: inline-block;
      }
      background: $color-white;
      svg {
        height: 36px;
        width: 36px;
        fill: $color-black;
        position: relative;
        top: 5px;

        @include desktop()  {
          top: 1px;
        }

        path {
          fill: $color-black;
        }
      }
      .button__text--display {
        vertical-align: top;
        color: $color-black;
      }
      .button__icon--before {
        margin-right: 0;
      }

    }
    .video_button {
      margin-right: 20px;
    }

  }

}

.video_button {

  svg:nth-of-type(1) {
    display: inline-block;
  }
  .playing & svg:nth-of-type(1) {
    display: none;
  }

  .text-play {
    display: inline-block;
  }
  .text-pause {
    display: none;
  }

  .playing & {
    .text-play {
      display: none;
    }
    .text-pause {
      display: inline-block;
    }
  }

  svg:nth-of-type(2) {
    display: none;
  }

  .playing & svg:nth-of-type(2) {
    display: inline-block;
  }
}

.audio_button {
  margin-right: 0;

  svg:nth-of-type(1) {
    display: inline-block;
  }
  .muted & svg:nth-of-type(1) {
    display: none;
  }

  svg:nth-of-type(2) {
    display: none;
  }

  .muted & svg:nth-of-type(2) {
    display: inline-block;
  }

  .text-mute {
    display: inline-block;
  }
  .text-unmute {
    display: none;
  }

  .muted & {
    .text-mute {
      display: none;
    }
    .text-unmute {
      display: inline-block;
    }
  }

  .touch & {
    display: none !important; // we do it in the native player...
  }
}
