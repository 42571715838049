body {
  //background-image: url("/static/images/airlie_beach_bg_istock.jpg");
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-attachment: fixed;
}

body.tinted {
  background-color: $color-black;
  //background-image: url("/static/images/webcampaal9blur.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

//the page
.my-teso-page {
  //the base for a myteso page
  //background: CornflowerBlue; // my new fav color!

  .page-header {
    min-height: 30px;
    margin-bottom: 0;
    margin-top: 50px;
    .content-header__title {
      position: relative;
      margin-bottom: 60px;
    }
    .page-header__container figure {
      background-color: $color-black;
      //background-image: url("/static/images/webcampaal9blur.jpg");
      background-size: cover;
    }
  }
}

.my-teso-page-login_wrapper {
  @include layout(6, 12, 12, 12);
  background: $color-white;
  padding: 60px 0px;

  h2 {
    @include typography-h2;
    @include vertical_margin($mobile-half-margin, $desktop-half-margin);
  }

  .login-form {
    @include layout(6, 5, 4, 4);
    @include offset(0, 0, 1, 1);
  }

  .login__no-account {
    @include layout(6, 6, 6, 6);
    @include offset(0, 1, 1, 1);
  }

  .login_teso-account-usps {
    @include style-richtext;
    margin-bottom: 20px;
    h3 {
      @include typography-paragraph;
      padding-top:0;
      margin-bottom: 0;
      font-weight: 700;
    }
    p {
      margin-bottom: 0;
    }
  }

}

.my-teso {

  &__white-bottom-row {
    @include layout(6, 12, 12, 12);
    @include mobile-only {
      width: 100%;
      margin: 0;
    }
    @include portrait-only {
      width: 100%;
      margin: 0;
    }
    background: $color-white;
    height: 60px;
  }

  &__white-row-padder {
    background: $color-white;
    width: 100%;
    height: 61px;
    position: absolute;
    transform: translateY(-100%);
    left: 0;
    display: none;
  }

  @include grid-media($portrait-grid) {
    &__white-row-padder {
      display: block;
    }
  }
  @include landscape {
    &__white-row-padder {
      width: calc(100% + 42px);
      left: -21px;
    }
  }

  &__white-fill-column { // for card removal, where it needs to fill 2 card slots
    @include layout(0, 0, 6.75, 7.5);

    background-color: $color-white;
    height: 500px;
    position: relative;
    display: none;

    @include grid-media($landscape-grid) {
      display: inline-block;
      &:before {
        content: '';
        width: $mobile-gutter;
        position: absolute;
        height: 100%;
        background-color: white;
        top: 0;
        bottom: 0;
        left: -1 * $mobile-gutter;
      }
    }

  }

  &__white-column {
    @include layout(0, 0, 0.75, 1.5);

    background-color: $color-white;
    height: 500px;
    position: relative;
    display: none;

    @include grid-media($landscape-grid) {
      display: inline-block;

      &.first {
        &:after {
          content: '';
          width: $mobile-gutter;
          position: absolute;
          height: 100%;
          background-color: transparent;
          top: 0;
          bottom: 0;
          right: -1 * $mobile-gutter;
        }
      }
      &.last {
        &:before {
          content: '';
          width: $mobile-gutter;
          position: absolute;
          height: 100%;
          background-color: white;
          top: 0;
          bottom: 0;
          left: -1 * $mobile-gutter;
        }
      }
    }
      //@include grid-media($landscape-grid){
      //    @include layout(0, 0, 0.75, 1.5);
      //background-color: white;
      //height: 100%;
      //position: relative;
      //display: none;
      //}
  }
}

@mixin my-teso-content-layout {
  @include layout(6, 12, 12, 12);
  @include column-padding-with-gutter(0, 0, 0, 0);

  @include mobile-only {
    $gutter: _retrieve-neat-setting($mobile-grid, gutter);

    padding-left: $gutter;
    padding-right: $gutter;
  }
}

//the container and content
.my-teso-page-content {
  @include container;

  section.content {
    @include my-teso-content-layout;
  }
}


.myteso-cards--container {
  @include container;
}

.myteso-card--container {
  background-color: transparent;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    width: 10px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    right: -11px;
  }

  &::before {
    content: '';
    width: 10px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    left: -11px;
  }

  @include mobile-only {
    &::before {
      top: -21px;
      left: -21px;
      width: 20px;
      height: 110%;
      background-color: $color-white;
    }
    &::after {
      top: -21px;
      left: -2px;
      height: 20px;
      background-color: $color-white;
      width: 102%;
    }
  }
  @include grid-media($portrait-grid) {

    &::after {
      content: '';
      width: 14px;
      position: absolute;
      height: 115%;
      background-color: white;
      top: -61px;
      right: -14px;
    }

    &::before {
      content: '';
      width: 11px;
      position: absolute;
      height: 115%;
      background-color: white;
      top: -61px;
      left: -11px;
    }
  }

  @include grid-media($landscape-grid) {
    background-color: transparent;
    height: 100%;
    position: relative;
      &:after {
        content: '';
        width: 10px;
        position: absolute;
        height: 101%;
        background-color: white;
        top: -1px;
        right: -11px;
      }

      &:before {
        content: '';
        width: 10px;
        position: absolute;
        height: 101%;
        background-color: white;
        top: -1px;
        left: -11px;
      }
  }
}

.myteso-cards--list {
  list-style: none;
  padding: 0;
  font-size: 0;
  height: 440px;

  & + .white-row {
    height: 100px;
    background: white;
    @include layout(6, 12, 12, 12);
    @include mobile-only {
      margin-left: 0;
      width: 100%;
    }
    @include grid-media($portrait-grid) {
      width: 98%;
      margin: -50px 10px 0 10px;
    }
    @include grid-media($landscape-grid) {
      margin-top: 0;
      @include layout(6, 12, 12, 12);
    }
  }
}


.whiteblock {
  @include layout(0, 0, 0.75, 1.5);
  background-color: white;
  height: 100%;
  position: relative;
  display: none;

  @include grid-media($landscape-grid) {
    display: inline-block;

    &:first-of-type {
      &:after {
        content: '';
        width: 10px;
        position: absolute;
        height: 100%;
        background-color: white;
        top: 0;
        bottom: 0;
        right: -10px;
      }
    }
    &:last-of-type {
      &:before {
        content: '';
        width: 10px;
        position: absolute;
        height: 100%;
        background-color: white;
        top: 0;
        bottom: 0;
        left: -10px;
      }
    }
  }
  //@include grid-media($landscape-grid){
  //    @include layout(0, 0, 0.75, 1.5);
  //background-color: white;
  //height: 100%;
  //position: relative;
  //display: none;
  //}
}

//cards
.myteso-card {
  text-align: left;
  margin: 0;
  display: inline-block;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  border: 1px solid white;
  @include layout(6, 6, 3.5, 3);

  @include mobile-only {
    &:after {
      content: "";
      position: absolute;
      top: -21px;
      right: -21px;
      background: white;
      height: 110%;
      width: 20px;
    }
    margin-top: 20px;
  }
  @include grid-media($portrait-grid) {
    margin-bottom: 50px;
  }
  @include grid-media($landscape-grid) {
    margin-bottom: 0;
  }
}

.myteso-card:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 0 6px white;
  width: 100%;

}

.myteso-card--white {
  background-color: white;

}

.myteso-card--content {
  @include typography-paragraph;
  padding: 18px 20px 20px 25px;
  border: 1px solid #D3D3D3;
  border-radius: 6px;
  box-sizing: border-box;
  height: 100%;
}

.teso-card-header {
  @include typography-h4;
  margin-bottom: 25px;
}

.qrcode {

}

.myteso-card--buttonblock {
  position: absolute;
  bottom: 25px;
  margin-left: 20px;
  margin-right: 20px;
  left: 0;
}

.myteso-buttonblock--primary {
  width: 100%;
  margin-bottom: 24px;
}

.myteso-buttonblock-icon {
  position: absolute;
  right: 20px;
}

.link {
  font-weight: bold;
}

.card-menu-item {
  display: inline-block;
  @include typography-undertitle;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 14.5px;
  margin-bottom: 12.5px;
  &:last-of-type {
    border: none;
  }
  @include mobile-only {
    display: block;
  }
}

.myteso-card--text {
  color: #909090;
}

.myteso--name {
  display: inline-block;
}

//card transparent

.myteso-card--transparent {
  background-image: url("/static/images/logo-teso.svg");
  background-repeat: no-repeat;
  background-size: 50px 20px;
  background-position: bottom 30px right 20px;
  color: white;
}

.myteso-card-text--transparent {
  color: white;
  font-weight: bold;
}

.myteso-card-content--transparent {
  border: none;
}



.myteso-logout-button {
  margin-right: 0px;
  margin-top: 6px;
  float: right;
  z-index: 2;
}

