
$vertical-beat: 20px;
$easing-change-reverse: 500ms;
$easing-change: 500ms;
$tooltip-overlay-z-index: 999;

@mixin tooltip {

  display: inline-block;
  height: 1em;//#{$vertical-beat * 2};
  line-height: 1em;//#{$vertical-beat * 2};
  vertical-align: top;
  white-space: nowrap;
  width: #{$vertical-beat * 2};

  .e-text {
    height: auto;
  }

  @include mobile {
    display: inline-block;
    line-height: #{$vertical-beat * 2};
    position: relative;
    vertical-align: text-top;
    top: -4px;

    .e-span {
      margin-left: .2em;
      white-space: nowrap;
    }

    .e-text,
    .e-close {
      display: none;
    }

    &:focus,
    .e-text:focus,
    .e-close:focus,
    .e-span:focus {
      outline: none; // iOS
    }
  }
}

@mixin tooltip-overlay {
  .e-text {
    @include typography-paragraph;
  }

  @include mobile {
    .e-text {
      bottom: 100%;
      display: none;
      left: 50%;
      margin: $vertical-beat;
      min-width: 200px;
      padding: #{$vertical-beat * 1.5} #{$vertical-beat * 2};
      position: absolute;
      text-align: left;
      transform: translateX(-50%);
      width: 100%;
      z-index: 9999;

      background-color: $flatpickr-grey;
      color: $color-black;
      border: 1px solid $color-light-grey;
      border-radius: 3px;

    }

    &.has-hover,
    &.has-focus,
    &.has-click {
      .e-text {
        display: block;
      }
    }

    &.is-below {
      .e-text {
        bottom: auto;
        margin-top: #{$vertical-beat};
        top: 100%;
      }
    }

    &.is-align-left {
      .e-text {
        left: -#{$vertical-beat * 1.5};
        transform: none;
      }
    }

    &.is-align-right {
      .e-text {
        left: auto;
        right: -#{$vertical-beat * 1.5};
        transform: none;
      }
    }
  }

  @include portrait {
    .e-text {
      min-width: 280px;
    }
  }
}

@mixin tooltop-overlay-hovertarget {
  @include mobile {
    .e-text {
      &::before {
        content: '';
        display: block;
        height: calc(100% + #{$vertical-beat * 2});
        left: -#{$vertical-beat};
        position: absolute;
        top: -#{$vertical-beat};
        width: calc(100% + #{$vertical-beat * 2});
        z-index: -1;
      }
    }
  }
}

@mixin tooltip-overlay-arrow {
  @include mobile {
    .e-text {
      &::after {
        border: 5px solid transparent;
        bottom: 0;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(100%);
        width: 0;


      }
    }

    &.is-below {
      .e-text {
        &::after {
          bottom: auto;
          top: 0;
          transform: translateX(-50%) translateY(-100%);


        }
      }
    }

    &.is-align-left {
      .e-text {
        &::after {
          left: #{$vertical-beat};
        }
      }
    }

    &.is-align-right {
      .e-text {
        &::after {
          left: auto;
          right: #{$vertical-beat};
        }
      }
    }

    &.is-align-left,
    &.is-align-right {
      .e-text {
        &::after {
          transform: translateY(100%);
        }
      }

      &.is-below {
        .e-text {
          &::after {
            transform: translateY(-100%);
          }
        }
      }
    }
  }
}

@mixin tooltip-overlay-animation {
  @include mobile {
    .e-text {
      display: block;
      opacity: 0;
      pointer-events: none;
      transform: translateX(-50%) translateY($vertical-beat);
      transition: transform $easing-change-reverse, opacity $easing-change-reverse;
      white-space: normal;

      body.is-ie10 & {
        display: none; // IE10 has no pointer-events
      }
    }

    &.is-align-left,
    &.is-align-right {
      .e-text {
        transform: translateY($vertical-beat);
      }
    }

    &.has-hover,
    &.has-focus,
    &.has-click {
      .e-text {
        display: block;
        opacity: 1;
        pointer-events: inherit;
        transform: translateX(-50%) translateY(0);
        transition: transform $easing-change, opacity $easing-change;

        body.is-ie10 & {
          display: block;
        }
      }

      &.is-align-left,
      &.is-align-right {
        .e-text {
          transform: translateY(0);
        }
      }
    }
  }
}

@mixin tooltip-overlay-close-icon {

  @include mobile {
    &.has-click {
      .e-close {
        cursor: pointer;
        display: block;
        font-size: #{$vertical-beat * 3};
        height: #{$vertical-beat * 3};
        line-height: #{$vertical-beat * 3};
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        vertical-align: middle;
        width: #{$vertical-beat * 3};
        img {  // the cross
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }
    }
  }
}

@mixin tooltip-overlay-backdrop {
  @include mobile {
    &::after {
      content: '';
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: #{$tooltip-overlay-z-index - 1};


    }

    &.has-click {
      &::after {
        display: block;
      }
    }
  }
}

@mixin tooltip-hover-icons {
  @include mobile {
    &.icon-content-22-info {
      &:hover {
      }
    }

    &.icon-content-33-info {
      &:hover {
      }
    }
  }
}

@mixin tooltip-no-icon {
  @include mobile {
    &::before {
      display: none;
    }

    .e-span {
      margin-left: -.2em;
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      .e-span {
        text-decoration: none;
      }
    }
  }
}

.c-tooltip {
  @include tooltip;
  @include tooltip-overlay;
  @include tooltip-overlay-backdrop;
  @include tooltop-overlay-hovertarget;
  @include tooltip-overlay-arrow;
  @include tooltip-overlay-animation;
  @include tooltip-overlay-close-icon;
  @include tooltip-hover-icons;

  &.v-no-icon {
    @include tooltip-no-icon;
  }

  .text-icon-block_icon {
    width: 26px;
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }

  dd {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid $color-yellow;
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    vertical-align: top;
    margin-left: 10px;
  }

}
