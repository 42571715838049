.intro-block {
  @include typography-intro;
  @include layout(5,8,8,8);
  @include offset(.5,2,2,2);

  @include vertical_margin(50px,60px); // 50 mobile margin, 60 desktop

  color: $color-white;

}
