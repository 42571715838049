
.table {
  display: flex;
  flex-wrap: wrap;

  &__cell {
    flex-grow: 1;
    width: 20%; // override on class depending on the size of the table
    overflow: hidden;

    &--date::first-letter {
      text-transform: capitalize;
    }
  }
}
