.button-small {
  @include typography-button--small;
  line-height: 28px;
  height: 30px;
  border-radius: 3px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;

  &.button-edit {

    svg {
      position: relative;
      top: 3px;
    }
    @include mobile-only{
      display: block;
    }
  }

  &.button-remove {
    svg {
      position: relative;
      top: 1px;
    }
     @include mobile-only{
      display: block;
    }
  }

}

.button-small--icon {
  margin-right: 6px;
  height: 10px;
  width: 10px;
}

