
.pagination {
  @include typography-paragraph;

  padding: 25px;
  text-align: center;

  .prev,
  .page,
  .next {
    padding: 5px;
  }

  a.page:hover,
  .prev:hover,
  .next:hover {
    font-weight: 600;
    text-decoration: underline;
  }
  .current {
    font-weight: 600;
  }
}
