.overview-page {
  background-color: lightskyblue;
}

.overview-item {
  position: relative;
  height: 420px;
  margin-bottom: $mobile-gutter; // this is always 20px so we use mobile only
  transition: box-shadow $cubic-250;
  color: $color-black;
  background: $color-white;
  @include layout(6,5,3,3);

  &.overview-with-button {
    //background: transparent;
    //color: $color-white;
  }

  &:hover,
  &:focus {
    box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.56);

    .overview-item__title {
      text-decoration: underline;
    }

    //&.overview-with-button {
    //  box-shadow: none;
    //  .overview-item__title {
    //    text-decoration: none;
    //  }
    //}

  }

  @media only screen and (max-width: $breakpoint-landscape - 1px) {
    &:nth-child(2n+1) { // every second item, starting at the first
      @include offset(0,1,1.5,1.5);
    }
  }

  @media only screen and (min-width: $breakpoint-landscape) {
    &:nth-child(3n+1) { // every third item, starting at the first
      @include offset(0,1,1.5,1.5);
    }
  }

  &__container {
    padding: 15px 20px;
  }

  .read-more-aligner {
    position: absolute;
    padding: 0px 20px 0px 20px;
    bottom: 15px;
  }

  .button {
    position: absolute;
    bottom: 15px;
    left: 20px;
  }

  &__title {
    margin-bottom: 20px;
    @include typography-h3-overview;
  }

  &__label {
    @include typography-paragraph;
  }

  &__hit-target {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__image {
    width: 100%;
  }
}
