$ipad-portrait-height: 1024px - 96px;
$ipad-landscape-height: 768px - 96px;

$breakpoint-mobile: 320px;
$breakpoint-portrait: 560px;
$breakpoint-landscape: 900px;
$breakpoint-desktop: 1120px;
$breakpoint-desktop-large: 1300px;

$lowviewportheight: 560px;

$mobile-columns-count:  6;
$portrait-columns-count:  12;
$landscape-columns-count:  12;
$desktop-columns-count: 12;

$mobile-max-width: 100%;
$portrait-max-width: 100%;
$landscape-max-width: 980px;
$desktop-max-width: 1180px;

$show-grid-visual: false;

$mobile-gutter: 20px;
$portrait-gutter: 20px;
$landscape-gutter: 20px;
$desktop-gutter: 20px;

$mobile-margin: 50px;
$desktop-margin: 60px;
$mobile-half-margin: 14px;
$desktop-half-margin: 20px;

$mobile-grid: (
    columns: $mobile-columns-count,
    gutter: $mobile-gutter,
    media: $breakpoint-mobile,
    color: yellow,
);

$portrait-grid: (
    columns: $portrait-columns-count,
    gutter: $portrait-gutter,
        color: green,
        media: $breakpoint-portrait,
);

$landscape-grid: (
    columns: $landscape-columns-count,
    gutter: $landscape-gutter,
    media: $breakpoint-landscape,
    color: blue,
);

$desktop-grid: (
    columns: $desktop-columns-count,
    gutter: $desktop-gutter,
    media: $breakpoint-desktop,
    color: #ff00f9,
);

$mobile-grid--gutterless: (
    columns: $mobile-columns-count,
    gutter: 0px,
    media: $breakpoint-mobile,
    color: yellow,
);

$portrait-grid--gutterless: (
    columns: $portrait-columns-count,
    gutter: 0px,
    media: $breakpoint-portrait,
    color: green,
);

$landscape-grid--gutterless: (
    columns: $landscape-columns-count,
    gutter: 0px,
    media: $breakpoint-landscape,
    color: blue,
);

$desktop-grid--gutterless: (
    columns: $desktop-columns-count,
    gutter: 0px,
    media: $breakpoint-desktop,
    color: #ff00f9,
);

@mixin mobile() {
  @content;
}

@mixin mobile-only() {
  @media all and (max-width: $breakpoint-portrait - 1) {
    @content;
  }
}

@mixin portrait() {
  @media all and (min-width: $breakpoint-portrait) {
    @content;
  }
}

@mixin portrait-only() {
  @media all and (min-width: $breakpoint-portrait) and (max-width: $breakpoint-landscape - 1) {
    @content;
  }
}

@mixin landscape() {
  @media all and (min-width: $breakpoint-landscape) {
    @content;
  }
}

@mixin landscape-only() {
  @media all and (min-width: $breakpoint-landscape) and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin landscape-down() {
  @media all and (max-width: $breakpoint-landscape) {
    @content;
  }
}

@mixin desktop() {
  @media all and (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin lowviewport() {
  @media all and (max-height: $lowviewportheight) {
    @content;
  }
}

@mixin desktop-only() {
  @media all and (min-width: $breakpoint-desktop) and (max-width: $breakpoint-desktop-large - 1) {
    @content;
  }
}

@mixin desktop-large() {
  @media all and (min-width: $breakpoint-desktop-large) {
    @content;
  }
}

@mixin mobile-portrait-only() {
  @media all and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-landscape - 1) {
    @content;
  }
}

@mixin ipad-portrait-only() {
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    @content;
  }
}

@mixin ipad-landscape-only() {
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    @content;
  }
}
