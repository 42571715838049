.notification-block {
  background-color: $color-yellow;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 80px;
  padding: 20px 0px;
  z-index: 999;
  display: block;
  align-items: center;
  transition: transform $cubic-250;

  a {
    text-decoration: underline;
  }

  &_wrapper {
    @include container();

    h2 {
      @include typography-h4();
      display: inline-block;
      margin-right: 1em;
      max-width: calc(100% - 45px - 64px);
    }

  }

  &_container {
    @include layout(6,12,12,12);
    position: relative;
    opacity: 1;
    transition: opacity .5s ease;
  }

  &_close {
    background: none;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    vertical-align: top;
    float: right;
    position: absolute;
    right: 0;

    &--icon {
      display: block;
      position: relative;
      top: -4px;
    }
  }

  &_icon {
    margin-right: 1em;
    display: inline-block;
    vertical-align: top;
  }

  &--disable {
    transform: translateX(-100%) translateX(70px); /* IE 11 */
    max-height: 80px;

    .notification-block__show-icon {
      opacity: 1;
      transition: opacity .25s ease .5s;
      z-index: 10;
    }
    .notification-block_container {
      opacity: 0;
    }
  }
  &__show-icon {
    opacity: 0;
    position: absolute;
    right: 18px;
    transition: opacity .25s ease;
    top: 50%;
    transform: translateY(-50%);
  }
}
