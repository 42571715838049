.webcam-image-block {
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  //@include column_padding(.7,1,1,1);
  padding-top: 20px;

  @include content-theme(false);
  font-size: 0;
  .webcam-image {
    display: inline-block;
    width: 100%;
    margin-bottom: $mobile-half-margin;

    @include landscape {
      width: 50%;
      padding: 0px $mobile-half-margin 0px $mobile-half-margin;
    }
  }
}

.image-block {

  &.image-block--narrow {
    @include layout(6,8,8,8);
    @include offset(0,2,2,2);
    @include column_padding(.7,1,1,1);
    @include content-theme(false);
    padding-top: $mobile-half-margin;
    @include desktop {
      padding-top: $desktop-half-margin;
    }
  }

  &.image-block--wide {
    @include layout(6,9,9,9);
    @include offset(0,1.5,1.5,1.5);

    @include mobile-only {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__image {
    float: left; // remove the bottom margin because of inlinyness
    max-width: 100%;
  }

  &.no-caption {
    .content-page-transparent & {
      margin-bottom: 35px;
      @include desktop {
        margin-bottom: 45px;
      }
    }
  }

}

.image-caption {
  padding-top: $mobile-half-margin;
  @include desktop {
    padding-top: $desktop-half-margin;
  }
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  @include column_padding(.7,1,1,1);
  @include typography-undertitle;

  @include content-theme(false, false);
  color: $color-grey; // Overwrite undertitle grey color

  .content-page-transparent & {
    margin-bottom: 35px;
    @include desktop {
      margin-bottom: 45px;
    }
  }

  &.last-of-text {
    padding-bottom: 50px;
    margin-bottom: 0;
    @include desktop {
      padding-bottom: 80px;
    }
    .content-page-transparent & {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

@include content-opacity;
