
// use this as a replacement for text and background color on affected components on the content page

@mixin content-theme($padding:true, $disable_padding:true) {
  color: $color-black;
  background: $color-white;
  border-bottom-color: $color-black;

  .content-page-transparent & {
    color: $color-white;
    background: transparent;
    border-bottom-color: $color-white;
    @if $disable_padding {
      padding-top: 0;
    }
  }

  @if $padding {
    padding-top: $mobile-margin; // 50px
    @media only screen and (min-width: $breakpoint-landscape) {
      padding-top: $desktop-margin; // 60px
    }
  }
}

@mixin content-opacity {
  .content-page-transparent {
    .image-caption {
      opacity: .5;
    }

    .video-caption {
      opacity: .5;
    }
  }
}
