@mixin stroke($color1, $color2) {
  transition: stroke $cubic-250;

  svg,
  path {
    stroke: $color1;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $color2;
    border-color: transparent;

    svg,
    path {
      stroke: $color2;
    }
  }
}

@mixin underline($color) {
  border-bottom: 1px solid transparent;
  transition: border-bottom $cubic-200;

  &:hover,
  &:focus {
    border-bottom: 1px solid $color;
  }
}

@mixin arrow($color1, $color2) {
  .button__icon--shape {
    transition: fill $cubic-250;
    fill: $color1;
  }

  svg {
     fill: $color1;
  }

  &:hover,
  &:focus {
    background-color: $color1;
    color: $color2;

    svg {
      fill: $color2;
    }
  }
}
