
.teso-cards-row-container {
  @include container;
}

.teso-row-opwaarderen {
  display: inline-block;
  vertical-align: top;
}

.teso-card-row-header {
  @include typography-h3;
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;

  &__card-number {
    margin-left: 20px;
  }
}

.table-opwaarderen-btn {
  @include typography-paragraph;
  font-size: 14px;
}

.myteso-row-opwaardeer-button {
  @include typography-button--small;
  height: 30px;
  text-align: center;
  padding: 0 9px;

}

.pas-menu {
  clear: both;
  padding-top: 15px;

  margin-left: 20px;
  margin-right: 20px;

  @include portrait {
    margin-left: 50px;
    margin-right: 50px;
  }

  border-top: #D3D3D3 solid 1px;
}

.pas-menu-header {
  @include typography-h4;

}

.table-row-content {
  height: 40px;
  vertical-align: bottom;
  @include typography-paragraph;
}

.pas-menu-item {
  margin-top: 9px;
  @include typography-paragraph;
}

.table-row-product {
  @include clearfix;

  padding-top: 20px;

  padding-left: 20px;
  padding-right: 20px;

  @include portrait {
    padding-left: 50px;
    padding-right: 50px;
  }

  .ticket_name {
    float: left;
    width: 50%;
    @include mobile-only {
      width: 70%;
    }
  }

  .table-row-retours {
    float: left;
    width: 10%;
    @include mobile-only {
      width: 30%;
      text-align: right;
    }
  }

  .table-row-opwaarderen {
    float: left;
    width: 40%;
    text-align: right;
    @include typography-button;

    @include mobile-only {
      display: block;
      float: none;
      clear: left;
      width: 100%;
      text-align: left;
    }

  }

}

.table-row-headers {
  // display: inline-block;
  border-bottom: #D3D3D3 solid 1px;
}

.wide-card-product-header {

  margin-left: 20px;
  margin-right: 20px;

  @include portrait {
    margin-left: 50px;
    margin-right: 50px;
  }
  @include landscape {
    margin-left: 50px;
    margin-right: 50px;
  }

  padding-top: 15px;
  margin-bottom: 15px;
  border-bottom: #D3D3D3 solid 1px;
  font-size: 0;
  .col-head {
    width: 30%;
    text-align: left;
    display: inline-block;
    padding-bottom: 10px;
    @include typography-h4;

    &.col-name {
      width: 50%;
    }
    &.col-retours {
      width: 20%;
    }

    @include mobile-only {
      &.col-name {
        width: 70%;
      }
      &.col-retours {
        width: 30%;
        text-align: right;
      }
    }

  }

}

.card-product-content {
  @include typography-paragraph;
  padding-bottom: 20px;
}

.card-row-container {
  background-color: transparent;
  height: auto;
  //transition: height $easing-change;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 20%;
    background-color: white;
    top: 100%;
    right: 0;
    width: 100%;
  }
  .teso-card-row-row--open & {
    &:after {
      height: 20px;
    }
  }
}

.whiteblock-row {
  background: white;
  position: relative;
  @include layout(0, 1, 1.5, 1.5);
  min-height: 132px;

  @include mobile-only {
    width: 20px;
    height: 100%;
    margin: 0;
    min-height: 152px;
    &::after, &::before {
      display: none !important;
    }
  }

  &::after, &::before {
    background-color: white;
    content: '';
    display: none;
    height: 100%;
    width: 20px;
    position: absolute;
    top: 0;
  }

  &::after {
    display: block;
    right: -20px;
  }

  &::before {
    display: block;
    left: -20px;
  }
  //transition: height $easing-change;

  @include grid-media($landscape-grid) {
    height: 110px *1.20;
  }
}

.card-row-uitklapper {
  width: 100%;
  border: 1px #D3D3D3 solid;
  background: #f7f7f7;
  border-radius: 0;
  position: relative;
  z-index: 10;
  //display: none;
  height: 0px;
  overflow: hidden;
  //transition: height $easing-change;
}

.teso-cards-row-list {
  list-style: none;
  padding: 0;
  font-size: 0;
  height: auto;
  & + .white-row {
    height: 100px;
    background: white;
    @include layout(6, 12, 12, 12);
    @include grid-media($landscape-grid) {
      margin-top: 0;
      @include layout(6, 12, 12, 12);
    }

    @include mobile-only {
      width: 100%;
      margin: 0;
    }
    @include portrait-only {
      width: 100%;
      margin: 0;
    }

  }
}

.right-block {
  position: absolute;
  right: 40px;
  top: 25px;
  display: block;
  width: auto;

  @include mobile-only {
    position: relative;
    top: auto;
    right: auto;

    p {
      text-align: left;
      padding-left: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

  }

}

.uitklapper-icon {
  width: 20px;
  height: 20px;
  float: right;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  @include portrait {
    right: 40px;
  }
}

.vinkje-txt-sub {
  margin-left: 25px;
  display: inline-block;
  @include typography-paragraph;
  color: #8C8C8C;
}

.personalinfo {
  padding-top: 40px;
  white-space: nowrap;
  float: bottom;
  text-align: right;
  @include typography-paragraph;
  padding-left: 20px !important;// sorry
  color: white;
}

.sub-content-button-btn {
  height: 30px;
  width: 90px;
}

.icon-sub {
  margin: 0;
}

.btn-sub {
  font-size: 12px;
}

//&__qrcode {
//  clear: both;
//  text-align: left;
//  padding: 10px 0px;
//  position: relative;
//  height: 120px;
//  z-index: 8;
//
//
//
//}

.qr-row {
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 15px;
  width: 80px;
  height: 80px;
  display: inline-block;
  vertical-align: top;

  .qr_inner {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    z-index: 5;
  }

  &.wide_qr { // temporary
    width: 0px;
    margin-right: 5px;
    margin-left: 15px;
  }


  svg {
    background: $color-white;
    width: 100%;
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform $easing-change, left $easing-change;
    transform-origin: 0% 0%;
  }

  &.zoomed {
    z-index: 10;
    svg {
      transform: scale(3) translateX(0%);
      left: 0%;
      max-height: 500px;
      max-width: 500px;
    }
    &:before {
      display: block;
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: $color-black;
      position: fixed;
      opacity: 0.4;
      z-index: 3;
    }
  }

}

.card_removal-confirm {
  .white-container {
    @include layout(6, 12, 12, 12);
    @include column-padding-with-gutter(0, 1, 1.5, 1.5);
    background: $color-white;
    .rich-text {
      max-width: 600px;
      @include style-richtext;
      //@include desktop {
      padding: $desktop-half-margin 0px $desktop-half-margin 0px;
      //}
      margin-bottom: -36px; // todo see if we can fix this nice with the weird card setup
    }
    .confirm-buttons {
      max-width: 600px;
      padding: $desktop-half-margin 0px $desktop-half-margin 0px;
      .link--right {
        float: right;
      }
    }
  }
}

.wide-card-vspacer {
  background: $color-white;
  height: 10px;
  @include layout(6, 12, 12, 12);
  @include mobile-only {
    width: 100%;
    margin: 0;
  }
  @include portrait-only {
    width: 100%;
    margin: 0;
  }
}

.teso-card-row-row {
  @include layout(6, 12, 12, 12);

  @include mobile-only {
    width: 100%;
    margin: 0;
  }
  @include portrait-only {
    width: 100%;
    margin: 0;
  }

  &.teso-card-row-row--open {
    .card-row-container {
      position: relative;

      @include grid-media($landscape-grid) {
        min-height: 440px;
      }

      .card-row-uitklapper {
        //overflow: visible;
        height: auto;
        padding-bottom: 20px;
      }

    }

    .uitklapper-icon {
      transform: rotate(90deg);
      transform-origin: 50% 50%;
    }

    .whiteblock-row {
      @include grid-media($portrait-grid) {
        min-height: 510px;
        height: 100%;
      }
    }

    .card-row-uitklapper {
      display: block;
      min-height: 380px;
    }
  }
}

.my-teso__top_button_bar {
  min-height: 130px;
  height: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.buttons {
  text-align: right;
  @include layout(6, 10, 9, 9);
  @include offset(0, 1, 1.5, 1.5);
  display: inline-block;

  .button__text--display {
    display: inline-block;
  }

}

.btn-cards {
  position: relative;
  margin-left: 20px;
  margin-bottom: 20px;
}

//white blocks
.white-row {
  background: white;
  @include layout(6, 12, 12, 12);
  height: 120px;

}

.card-row-content {
  min-height: 110px;
  position: relative;
  z-index: 12;
}

//cards
.teso-card-row {
  text-align: left;
  margin: 0;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  margin-bottom: 10px;
  @include layout(6, 10, 9, 9);

  @include mobile-only {
    margin-left: 0;
    margin-right: 0;
  }

}

.teso-card-row:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  height: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 0 0 8px white;
  width: 100%;
  z-index: 0;
}

.connect-licenses {
  a {
    margin-left: 0 !important;
    line-height: 0;
    display: block;
    color: $flatpickr-grey;
    input[type='checkbox'] {

      + label {
        &:before {
          margin-bottom: 0 !important;
        }
      }

      &:checked,
      &.is-checked {
        + label {
          &:before {
            background-color: $color-yellow;
          }
        }

      }
    }

  }
  clear: both;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;

}


