
.select {
  @include typography-button;

  appearance: none;
  background: $color-white url('/static/icons/chevron-down.svg') no-repeat 95% 50%;
  border-radius: 3px;
  border: solid 1px $color-black;
  height: 40px;
  padding: 10px 20px;
  width: 100%;
}
