@charset "UTF-8";
@import '~reset-css/reset.css';
@import '~flatpickr/dist/flatpickr.min.css';
@font-face {
  font-family: "Foundry Monoline";
  src: url("/static/fonts/343c931d-1f3d-444a-bce1-f3ed83e9f245.woff2") format("woff2"), url("/static/fonts/e089faeb-6b44-4090-91fc-906aec84c145.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Foundry Monoline";
  src: url("/static/fonts/06360e61-af51-48c4-abf9-470a879168cb.woff2") format("woff2"), url("/static/fonts/521b91a5-9beb-42a7-8f83-eddf02ee1290.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}
@font-face {
  font-family: "Foundry Monoline";
  src: url("/static/fonts/4a7f9937-dcef-476e-a87e-93d9ceb78502.woff2") format("woff2"), url("/static/fonts/64801b4f-134a-4c23-a1bf-2f6fe6a479ab.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Foundry Monoline";
  src: url("/static/fonts/bad05f96-5196-4e32-ba19-fd8a9cb00b7e.woff2") format("woff2"), url("/static/fonts/c9f7fefc-34c7-437e-bbf0-664b1258bd0a.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
.flatpickr-calendar {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 210px;
  box-sizing: border-box;
  touch-action: manipulation;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.hasWeeks {
  width: auto;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #eceef1;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid #eceef1;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #eceef1;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #eceef1;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eceef1;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #eceef1;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-month {
  border-radius: 3px 3px 0 0;
  background: #f7f7f7;
  color: #222;
  fill: #222;
  height: 48px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 22px;
  height: 28px;
  padding: 20px calc(10.57% - 1.5px);
  z-index: 3;
}

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative;
}

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
          /*rtl:begin:ignore*/
  /*
          */
  left: 0;
  /*
          /*rtl:end:ignore*/
  /*
          */
}

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
          /*rtl:begin:ignore*/
  /*
          */
  right: 0;
  /*
          /*rtl:end:ignore*/
  /*
          */
}

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #bbb;
}

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #ffd700;
}

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px;
}

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, 0.05);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
  top: 33%;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(90, 97, 113, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 81%;
  left: 12.5%;
  padding: 20px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #5a6171;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #5a6171;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: inherit;
  height: initial;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(90, 97, 113, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-weekdays {
  background: #f7f7f7;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 20px;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #f7f7f7;
  color: #222;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 307.875px;
  border-left: 1px solid #eceef1;
  border-right: 1px solid #eceef1;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0 20px 26px;
  outline: 0;
  text-align: left;
  width: 210px;
  min-width: 210px;
  max-width: 0;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  color: #222;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 22px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #ffd700;
  border-color: #e2e2e2;
}

.flatpickr-day.today {
  border-color: transparent;
  background-color: #ffd700;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #ffd700;
  box-shadow: none;
  color: #fff;
  border-color: #ffd700;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #ff5a5f;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: #8c8c8c;
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: #8c8c8c;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #ff5a5f, 5px 0 0 #ff5a5f;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid #eceef1;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  background: #f7f7f7;
  border-bottom: 1px solid #eceef1;
  border-radius: 0px 0px 3px 3px;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
  width: 210px;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #484848;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #ececec;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
  }
}
@keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
  }
}
@keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
  }
}
@keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
  }
}
@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fpFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fpFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
span.flatpickr-day.selected {
  font-weight: bold;
}

body {
  background-color: #fff;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.link_underline {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.link_underline:hover, .link_underline:focus {
  border-bottom: 1px solid #fff;
}
.link_underline--dark {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.link_underline--dark:hover, .link_underline--dark:focus {
  border-bottom: 1px solid #222;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #fff url("/static/icons/chevron-down.svg") no-repeat 95% 50%;
  border-radius: 3px;
  border: solid 1px #222;
  height: 40px;
  padding: 10px 20px;
  width: 100%;
}

.page-header {
  color: #fff;
  position: relative;
  min-height: 160px;
  margin-right: auto;
  padding: 0;
  margin-bottom: 40px;
}
.page-header:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .page-header {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .page-header {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .page-header {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .page-header {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
.page-header__container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  /* BlackBerry menu fix */
}
.ui-inactive .page-header__container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
@media only screen and (min-width: 320px) {
  .page-header__container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .page-header__container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .page-header__container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .page-header__container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .page-header__container {
    margin-top: 70px;
  }
}
@media all and (min-width: 1120px) {
  .page-header {
    min-height: 250px;
    margin-bottom: 100px;
  }
}

.image__background_fixed {
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
}
.playing .image__background_fixed {
  display: none;
}

.content-header__title {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
}
@media all and (min-width: 1120px) {
  .content-header__title {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}

.content-header__subtitle {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 29px;
}
@media all and (min-width: 1120px) {
  .content-header__subtitle {
    font-size: 24px;
  }
}
@media all and (max-width: 559px) {
  .content-header__subtitle {
    max-width: 260px;
  }
}
@media all and (min-width: 560px) {
  .content-header__subtitle {
    margin-bottom: 15px;
  }
}

.intro-block {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  color: #fff;
}
@media only screen and (min-width: 320px) {
  .intro-block {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .intro-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .intro-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .intro-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .intro-block {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .intro-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .intro-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .intro-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .intro-block {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 900px) {
  .intro-block {
    margin-bottom: 60px;
  }
}

.text-block {
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 50px;
  padding-top: 35px;
}
@media only screen and (min-width: 320px) {
  .text-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .text-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .text-block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .text-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media only screen and (min-width: 900px) {
  .text-block {
    padding-top: 60px;
  }
}
@media all and (min-width: 1120px) {
  .text-block {
    padding-top: 45px;
  }
}
.text-block h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .text-block h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.text-block h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .text-block h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .text-block h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .text-block h2 {
    margin-bottom: 20px;
  }
}
.text-block h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .text-block h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .text-block h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .text-block h3 {
    margin-bottom: 20px;
  }
}
.text-block h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .text-block h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .text-block h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .text-block h4 {
    margin-bottom: 20px;
  }
}
.text-block p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .text-block p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .text-block p {
    margin-bottom: 20px;
  }
}
.text-block p + h2, .text-block p + h3, .text-block p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .text-block p + h2, .text-block p + h3, .text-block p + h4 {
    padding-top: 20px;
  }
}
.text-block ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.text-block ul li {
  padding: 0.5em 1em;
}
.text-block a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .text-block a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.text-block a:hover {
  border-bottom: 1px solid transparent;
}
.text-block.last-of-text {
  padding-bottom: 50px;
}
@media all and (min-width: 1120px) {
  .text-block.last-of-text {
    padding-bottom: 80px;
  }
}
.content-page-transparent .text-block.last-of-text {
  padding-bottom: 0;
}

.text-block-image {
  padding: 0;
  float: left;
}

.content-page-transparent ul {
  list-style: disc url("/static/icons/icon-bullet-white.svg");
}

.text-icon-block_item {
  margin-bottom: 40px;
  color: #fff;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  font-weight: 400;
  line-height: 1.3;
}
@media only screen and (min-width: 320px) {
  .text-icon-block_item {
    width: calc(91.6666666667% - 38.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .text-icon-block_item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .text-icon-block_item {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .text-icon-block_item {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
.text-icon-block_item .button {
  float: left;
  clear: both;
  width: 155px;
  margin-right: 0;
}
@media all and (min-width: 900px) {
  .text-icon-block_item .button {
    float: right;
  }
}
@media all and (min-width: 1120px) {
  .text-icon-block_item {
    margin-bottom: 0;
  }
}
@media all and (min-width: 320px) and (max-width: 899px) {
  .text-icon-block_item.departure_times {
    clear: left;
  }
}
@media all and (max-width: 559px) {
  .text-icon-block_item.departure_times {
    margin-top: -10px;
  }
}
.text-icon-block_text::first-line {
  font-weight: 500;
}
.text-icon-block_text {
  margin-right: 40px;
}
@media all and (min-width: 560px) {
  .text-icon-block_text {
    margin-right: 30px;
  }
}
.text-icon-block_icon--container {
  height: 50px;
  width: 65px;
  float: left;
  margin-bottom: 5px;
}
@media all and (max-width: 559px) {
  .text-icon-block_icon--container {
    margin-bottom: 50px;
  }
}
.text-icon-block_icon--container svg {
  height: 100%;
  width: 100%;
}
.text-icon-block_icon--container svg path {
  fill: #ffd700;
}
@media all and (min-width: 320px) and (max-width: 899px) {
  .text-icon-block_icon--container.hide {
    display: none;
  }
}
.text-icon-block_item.laptop-icon .text-icon-block_icon--container svg {
  position: relative;
  left: -10px;
  top: -4px;
}
.text-icon-block_item.car-icon .text-icon-block_icon--container svg {
  position: relative;
  left: -8px;
  bottom: -6px;
}
.text-icon-block_item.mobile-icon .text-icon-block_icon--container svg {
  width: 60%;
  position: relative;
  left: -7px;
}
.text-icon-block_item.card-icon .text-icon-block_icon--container svg {
  position: relative;
  left: -10px;
  bottom: -3px;
}

@media all and (max-width: 559px) {
  .mobile-icon .text-icon-block_icon--container {
    padding-left: 9px;
  }
}

/* TODO this feels kinda hackey */
@media all and (min-width: 560px) {
  .text-icon-block_icon--container {
    height: 50px;
    float: none;
  }
}
@media all and (min-width: 900px) {
  .text-icon-block_icon--container.hide {
    display: block;
  }
}
.webcam-image-block {
  padding-top: 20px;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  font-size: 0;
}
@media only screen and (min-width: 320px) {
  .webcam-image-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .webcam-image-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .webcam-image-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .webcam-image-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .webcam-image-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .webcam-image-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .webcam-image-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .webcam-image-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
.content-page-transparent .webcam-image-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.webcam-image-block .webcam-image {
  display: inline-block;
  width: 100%;
  margin-bottom: 14px;
}
@media all and (min-width: 900px) {
  .webcam-image-block .webcam-image {
    width: 50%;
    padding: 0px 14px 0px 14px;
  }
}

.image-block.image-block--narrow {
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 14px;
}
@media only screen and (min-width: 320px) {
  .image-block.image-block--narrow {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .image-block.image-block--narrow {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .image-block.image-block--narrow {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .image-block.image-block--narrow {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-block.image-block--narrow {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .image-block.image-block--narrow {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .image-block.image-block--narrow {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-block.image-block--narrow {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .image-block.image-block--narrow {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .image-block.image-block--narrow {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .image-block.image-block--narrow {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-block.image-block--narrow {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .image-block.image-block--narrow {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media all and (min-width: 1120px) {
  .image-block.image-block--narrow {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-block.image-block--wide {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .image-block.image-block--wide {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .image-block.image-block--wide {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .image-block.image-block--wide {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-block.image-block--wide {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .image-block.image-block--wide {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .image-block.image-block--wide {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-block.image-block--wide {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media all and (max-width: 559px) {
  .image-block.image-block--wide {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
.image-block__image {
  float: left;
  max-width: 100%;
}
.content-page-transparent .image-block.no-caption {
  margin-bottom: 35px;
}
@media all and (min-width: 1120px) {
  .content-page-transparent .image-block.no-caption {
    margin-bottom: 45px;
  }
}

.image-caption {
  padding-top: 14px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  color: #909090;
}
@media all and (min-width: 1120px) {
  .image-caption {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-caption {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .image-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .image-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .image-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-caption {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .image-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .image-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .image-caption {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .image-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .image-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .image-caption {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
}
.content-page-transparent .image-caption {
  margin-bottom: 35px;
}
@media all and (min-width: 1120px) {
  .content-page-transparent .image-caption {
    margin-bottom: 45px;
  }
}
.image-caption.last-of-text {
  padding-bottom: 50px;
  margin-bottom: 0;
}
@media all and (min-width: 1120px) {
  .image-caption.last-of-text {
    padding-bottom: 80px;
  }
}
.content-page-transparent .image-caption.last-of-text {
  padding-bottom: 0;
  margin-bottom: 0;
}

.content-page-transparent .image-caption {
  opacity: 0.5;
}
.content-page-transparent .video-caption {
  opacity: 0.5;
}

.quote-block {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #fff;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.3;
}
@media only screen and (min-width: 320px) {
  .quote-block {
    width: calc(86.6666666667% - 37.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .quote-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .quote-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .quote-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .quote-block {
    margin-left: calc(13.3333333333% - 22.6666666667px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .quote-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .quote-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .quote-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media all and (min-width: 1120px) {
  .quote-block {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media all and (min-width: 1120px) {
  .quote-block {
    font-size: 36px;
    line-height: 1.4;
  }
}
.quote-block__author {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.quote-block__author::before, .quote-block__author::after {
  content: "—";
  margin: 0 5px;
}
.quote-block__quote--marks {
  font-size: 3em;
  position: absolute;
  opacity: 0.5;
}
.quote-block__quote--marks.marks--before {
  margin-top: -30px;
  margin-left: -55px;
}
.quote-block__quote--marks.marks--after {
  margin-top: -20px;
  margin-left: 10px;
}

.video-top-spacer {
  height: 30px;
  background: #fff;
}
@media only screen and (min-width: 320px) {
  .video-top-spacer {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .video-top-spacer {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .video-top-spacer {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .video-top-spacer {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .video-top-spacer {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .video-top-spacer {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .video-top-spacer {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .video-top-spacer {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
.content-page-transparent .video-top-spacer {
  background: transparent;
}

@media only screen and (min-width: 320px) {
  .video-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .video-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .video-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .video-block {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .video-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .video-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .video-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .video-block {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media all and (max-width: 559px) {
  .video-block {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
.video-block__video {
  position: relative;
}
.video-block a {
  display: block;
  padding-bottom: 56.25%;
}
.video-block a .video-block__play {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: #ffd700;
  left: 50%;
  top: 50%;
  border: none;
  -webkit-transform: translate3D(-50%, -50%, 0);
          transform: translate3D(-50%, -50%, 0);
}
@media all and (min-width: 1120px) {
  .video-block a .video-block__play {
    width: 80px;
    height: 80px;
  }
}
.video-block a .video-block__play .video-block__play-icon {
  display: block;
  position: absolute;
  margin-left: 2px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3D(-50%, -50%, 0);
          transform: translate3D(-50%, -50%, 0);
  stroke: #222;
  fill: transparent;
  transition: fill 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.video-block a:hover .video-block__play-icon {
  fill: #222;
}
.video-block.is-playing .video-block__play {
  display: none;
}
.video-block.is-playing a + iframe {
  display: block;
}
.video-block.is-iframe .video-block__video {
  padding-bottom: 56.25%;
  background: #fff;
}
.video-block.is-iframe .video-block__play {
  display: none;
}
.video-block.is-iframe iframe {
  display: block;
}
.video-block iframe {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.content-page-transparent .video-block.no-caption {
  margin-bottom: 35px;
}
@media all and (min-width: 1120px) {
  .content-page-transparent .video-block.no-caption {
    margin-bottom: 45px;
  }
}

.video-caption {
  padding-top: 14px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  color: #909090;
}
@media all and (min-width: 1120px) {
  .video-caption {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .video-caption {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .video-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .video-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .video-caption {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .video-caption {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .video-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .video-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .video-caption {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .video-caption {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .video-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .video-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .video-caption {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .video-caption {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
}
.content-page-transparent .video-caption {
  margin-bottom: 35px;
}
@media all and (min-width: 1120px) {
  .content-page-transparent .video-caption {
    margin-bottom: 45px;
  }
}
.video-caption.last-of-text {
  padding-bottom: 50px;
  margin-bottom: 0;
}
@media all and (min-width: 1120px) {
  .video-caption.last-of-text {
    padding-bottom: 80px;
  }
}
.content-page-transparent .video-caption.last-of-text {
  padding-bottom: 0;
  margin-bottom: 0;
}

.content-page-transparent .image-caption {
  opacity: 0.5;
}
.content-page-transparent .video-caption {
  opacity: 0.5;
}

.button-block {
  padding-bottom: 20px;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 14px;
  padding-bottom: 14px;
}
@media only screen and (min-width: 320px) {
  .button-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .button-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .button-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .button-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .button-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .button-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .button-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .button-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .button-block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .button-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .button-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .button-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .button-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media all and (min-width: 1120px) {
  .button-block {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.button-block + .button-block {
  padding-top: 0;
}
.button-block.last-of-text {
  padding-bottom: 50px;
}
@media all and (min-width: 1120px) {
  .button-block.last-of-text {
    padding-bottom: 80px;
  }
}
.content-page-transparent .button-block.last-of-text {
  padding-bottom: 0;
}

.button {
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-decoration: none;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  border-radius: 3px;
  transition: background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955), color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .button {
    height: 40px;
    line-height: 40px;
  }
}
.button__text {
  display: inline-block;
}
.button__text:first-letter {
  text-transform: uppercase;
}
.button__text--display {
  display: none;
}
@media all and (min-width: 560px) {
  .button__text--display {
    display: inline-block;
  }
}
.button__icon--after {
  margin-left: 0.7em;
  vertical-align: middle;
}
.my-teso-card__button-block .button__icon--after {
  float: right;
}
.button__icon--before {
  margin-right: 0;
  vertical-align: sub;
}
@media all and (min-width: 1120px) {
  .button__icon--before {
    margin-right: 0.7em;
  }
}
.button__icon--white svg {
  fill: #fff;
}
.button--primary {
  background-color: #ffd700;
  color: #222;
}
.button--primary .button__icon--shape {
  transition: fill 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  fill: #222;
}
.button--primary svg {
  fill: #222;
}
.button--primary:hover, .button--primary:focus {
  background-color: #222;
  color: #ffd700;
}
.button--primary:hover svg, .button--primary:focus svg {
  fill: #ffd700;
}
.button--transparent {
  border: 1px solid #fff;
  color: #fff;
  line-height: 48px;
}
.button--transparent .button__icon--shape {
  transition: fill 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  fill: #fff;
}
.button--transparent svg {
  fill: #fff;
}
.button--transparent:hover, .button--transparent:focus {
  background-color: #fff;
  color: #222;
}
.button--transparent:hover svg, .button--transparent:focus svg {
  fill: #222;
}
@media all and (min-width: 1120px) {
  .button--transparent {
    line-height: 38px;
  }
}
.button--stroke {
  transition: stroke 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.button--stroke svg,
.button--stroke path {
  stroke: #222;
}
.button--stroke:hover, .button--stroke:focus {
  background-color: transparent;
  color: #fff;
  border-color: transparent;
}
.button--stroke:hover svg,
.button--stroke:hover path, .button--stroke:focus svg,
.button--stroke:focus path {
  stroke: #fff;
}
.button--stroke div.button-small--icon {
  display: inline-block;
  vertical-align: initial;
}
.button--transparent--reverse {
  border: 1px solid #222;
  color: #222;
  padding: 0 20px;
}
.button--transparent--reverse .button__icon--shape {
  transition: fill 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  fill: #222;
}
.button--transparent--reverse svg {
  fill: #222;
}
.button--transparent--reverse:hover, .button--transparent--reverse:focus {
  background-color: #222;
  color: #fff;
}
.button--transparent--reverse:hover svg, .button--transparent--reverse:focus svg {
  fill: #fff;
}
.button__myteso {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 360px) {
  .button__myteso {
    max-width: 136px;
  }
}
@media (min-width: 380px) {
  .button__myteso {
    max-width: 146px;
  }
}
@media all and (min-width: 900px) {
  .button__myteso {
    max-width: 200px;
  }
}

.button-small {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.4;
  line-height: 28px;
  height: 30px;
  border-radius: 3px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
}
.button-small.button-edit svg {
  position: relative;
  top: 3px;
}
@media all and (max-width: 559px) {
  .button-small.button-edit {
    display: block;
  }
}
.button-small.button-remove svg {
  position: relative;
  top: 1px;
}
@media all and (max-width: 559px) {
  .button-small.button-remove {
    display: block;
  }
}

.button-small--icon {
  margin-right: 6px;
  height: 10px;
  width: 10px;
}

.overview-page {
  background-color: lightskyblue;
}

.overview-item {
  position: relative;
  height: 420px;
  margin-bottom: 20px;
  transition: box-shadow 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
}
@media only screen and (min-width: 320px) {
  .overview-item {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .overview-item {
    width: calc(41.6666666667% - 28.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .overview-item {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .overview-item {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
.overview-item:hover, .overview-item:focus {
  box-shadow: 4px 2px 11px 3px rgba(0, 0, 0, 0.56);
}
.overview-item:hover .overview-item__title, .overview-item:focus .overview-item__title {
  text-decoration: underline;
}
@media only screen and (max-width: 899px) and (min-width: 320px) {
  .overview-item:nth-child(2n+1) {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (max-width: 899px) and (min-width: 560px) {
  .overview-item:nth-child(2n+1) {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (max-width: 899px) and (min-width: 900px) {
  .overview-item:nth-child(2n+1) {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (max-width: 899px) and (min-width: 1120px) {
  .overview-item:nth-child(2n+1) {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 900px) and (min-width: 320px) {
  .overview-item:nth-child(3n+1) {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) and (min-width: 560px) {
  .overview-item:nth-child(3n+1) {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 900px) and (min-width: 900px) {
  .overview-item:nth-child(3n+1) {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 900px) and (min-width: 1120px) {
  .overview-item:nth-child(3n+1) {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
.overview-item__container {
  padding: 15px 20px;
}
.overview-item .read-more-aligner {
  position: absolute;
  padding: 0px 20px 0px 20px;
  bottom: 15px;
}
.overview-item .button {
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.overview-item__title {
  margin-bottom: 20px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .overview-item__title {
    font-size: 24px;
  }
}
.overview-item__label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.overview-item__hit-target {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overview-item__image {
  width: 100%;
}

.home-item {
  position: relative;
  margin-bottom: 30px;
  transition: box-shadow 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  height: 358px;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
@media only screen and (min-width: 320px) {
  .home-item {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
.content-page-transparent .home-item {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media all and (min-width: 900px) {
  .home-item {
    height: 200px;
  }
}
.swiper-slide .home-item {
  width: calc(100% - 40px);
}
.home-item .button {
  margin-top: 10px;
  margin-right: -10px;
}
.home-item__title {
  margin-bottom: 20px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .home-item__title {
    font-size: 24px;
  }
}
.home-item__label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.home-item__image {
  width: 100%;
  height: 100%;
}
.home-item__figure {
  width: 100%;
  height: 180px;
}
@media all and (min-width: 900px) {
  .home-item__figure {
    width: 48%;
    float: left;
    height: 100%;
  }
}
.home-item__container {
  padding: 30px 20px;
}
@media all and (min-width: 900px) {
  .home-item__container {
    width: 52%;
    float: left;
  }
}
.home-item__hit-target {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}

.social-block {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media only screen and (min-width: 320px) {
  .social-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .social-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .social-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .social-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .social-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .social-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .social-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .social-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
.social-block .social-button {
  display: inline-block;
}
.social-block_title {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .social-block_title {
    font-size: 24px;
  }
}
.social-block-container {
  margin-right: 20px;
}
.social-block-container .icon {
  transition: fill 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.social-block-container .icon_shape, .social-block-container .icon_shape_alter {
  fill: #fff;
}
.social-block-container .icon_border {
  stroke: #fff;
  fill: transparent;
}
.social-block-container .icon_background {
  fill: transparent;
}
.social-block-container:hover .icon_shape, .social-block-container:focus .icon_shape {
  fill: transparent;
}
.social-block-container:hover .icon_shape_alter, .social-block-container:focus .icon_shape_alter {
  fill: #fff;
}
.social-block-container:hover .icon_background, .social-block-container:focus .icon_background {
  fill: #fff;
}
.social-block__link {
  vertical-align: center;
  fill: #fff;
}

.page-footer {
  margin: 100px 0 40px;
  color: #fff;
  margin-right: auto;
  padding: 0;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.page-footer:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .page-footer {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .page-footer {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .page-footer {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .page-footer {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}

.footer_container {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 320px) {
  .footer_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .footer_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .footer_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .footer_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.footer_quote {
  margin-left: auto;
  display: none;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .footer_quote {
    font-size: 24px;
  }
}
@media all and (min-width: 1120px) {
  .footer_quote {
    display: block;
  }
}
.footer_item {
  display: inline-block;
  margin-right: 1.5em;
}

.page-navigation {
  color: #fff;
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  margin-right: auto;
  padding: 0;
}
.page-navigation:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .page-navigation {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .page-navigation {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .page-navigation {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .page-navigation {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
.page-navigation .button {
  margin-right: 0;
}

.navigation_container {
  display: flex;
  align-items: center;
  margin-top: 28px;
  padding-bottom: 20px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
@media only screen and (min-width: 320px) {
  .navigation_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .navigation_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .navigation_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .navigation_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.ui-inactive .navigation_container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.navigation_list {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.navigation_logo {
  margin-top: -10px;
}
@media all and (min-width: 320px) and (max-width: 380px) {
  .navigation_logo {
    width: 32%;
  }
  .navigation_logo img {
    width: 100%;
  }
}
.navigation_item {
  display: inline-block;
  margin-right: 60px;
}
@media all and (min-width: 900px) {
  .navigation_item {
    margin-right: 3vw;
  }
}
.navigation_item .button {
  margin-bottom: 0 !important;
}
.navigation_item .button--primary {
  vertical-align: baseline !important;
}
.navigation_item:nth-last-child(-n+3) {
  margin-right: 0;
  margin-left: 10px;
}
.navigation_item--text {
  display: none;
}
@media all and (min-width: 1120px) {
  .navigation_item--text {
    display: inline-block;
  }
}
@media all and (max-width: 559px) {
  .navigation_item.navigation_item_button .button__myteso {
    display: none;
  }
}

.hamburger {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 28px 20px 20px 20px;
  background: #fff;
  color: #222;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
}
@media all and (max-width: 559px) {
  .hamburger {
    transition: opacity 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
}
@media all and (min-width: 560px) {
  .hamburger {
    transition: -webkit-transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    width: 350px;
    opacity: 1;
  }
}
@media all and (min-width: 1120px) {
  .hamburger {
    padding-top: 40px;
  }
}
.hamburger.active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.hamburger .button__icon--before {
  margin-right: 0;
}
.hamburger_navigation_list {
  margin-left: 60px;
}
@media all and (min-width: 1120px) {
  .hamburger_navigation_list {
    margin-left: 40px;
  }
}
.hamburger_navigation_list.active-language-nl .lang-nl {
  text-decoration: underline;
}
.hamburger_navigation_list.active-language-en .lang-en {
  text-decoration: underline;
}
.hamburger_navigation_list.active-language-de .lang-de {
  text-decoration: underline;
}
.hamburger_navigation_item--language {
  display: inline-block;
  margin-top: 40px;
  margin-right: 12px;
}
.hamburger_navigation_item--webcams {
  position: absolute;
  bottom: 30px;
  padding-left: 32px;
}
.hamburger_navigation_item--webcams svg {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -5px;
  left: -5px;
  fill: #222;
}
.hamburger_navigation_item--webcams svg path {
  fill: #222;
}
.hamburger_navigation_item--menu {
  margin-bottom: 20px;
}
.hamburger_navigation_item--menu:last-of-type {
  margin-bottom: 40px;
}
.hamburger_navigation_button:first-child {
  margin-right: 15px;
}
.hamburger_wrapper {
  display: flex;
  margin-bottom: 50px;
  justify-content: flex-end;
}
@media all and (min-width: 1120px) {
  .hamburger_wrapper {
    justify-content: flex-start;
  }
}

body:before {
  transition: opacity 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  content: "";
  display: block;
  background: #000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 990;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translateY(-300%);
          transform: translateY(-300%);
  transition-delay: 0.2s;
}
body.navigation-active:before {
  opacity: 0.6;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
body.navigation-active .page-header {
  pointer-events: none;
}

.link-block {
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 14px;
  padding-bottom: 14px;
}
@media only screen and (min-width: 320px) {
  .link-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .link-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .link-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .link-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .link-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .link-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .link-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .link-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .link-block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .link-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .link-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .link-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .link-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media all and (min-width: 1120px) {
  .link-block {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.link-block + .link-block {
  padding-top: 0;
  margin-top: -14px;
}

.link {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.link__icon {
  margin-right: 0.2em;
}
.link__icon--white svg {
  fill: #fff;
}
.link__icon--close {
  margin-right: 0;
  vertical-align: sub;
}

.home-block .link__icon svg {
  fill: #fff;
}
.home-block .link__text {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.home-block .link__text:hover, .home-block .link__text:focus {
  border-bottom: 1px solid #fff;
}
.home-block .link:not(.news-swiper) .link__icon--shape {
  fill: #fff;
}
.home-block .news-swiper .link {
  margin-left: 0;
}

.content-page .link__text {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.content-page .link__text:hover, .content-page .link__text:focus {
  border-bottom: 1px solid #222;
}
.content-page-transparent .link__icon--shape {
  fill: #fff;
}
.content-page-transparent .link__text {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.content-page-transparent .link__text:hover, .content-page-transparent .link__text:focus {
  border-bottom: 1px solid #fff;
}

.notification-block {
  background-color: #ffd700;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 80px;
  padding: 20px 0px;
  z-index: 999;
  display: block;
  align-items: center;
  transition: -webkit-transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.notification-block a {
  text-decoration: underline;
}
.notification-block_wrapper {
  margin-right: auto;
  padding: 0;
}
.notification-block_wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .notification-block_wrapper {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .notification-block_wrapper {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .notification-block_wrapper {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .notification-block_wrapper {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
.notification-block_wrapper h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
  display: inline-block;
  margin-right: 1em;
  max-width: calc(100% - 45px - 64px);
}
@media all and (min-width: 1120px) {
  .notification-block_wrapper h2 {
    font-size: 24px;
  }
}
.notification-block_container {
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease;
}
@media only screen and (min-width: 320px) {
  .notification-block_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .notification-block_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .notification-block_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .notification-block_container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.notification-block_close {
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  vertical-align: top;
  float: right;
  position: absolute;
  right: 0;
}
.notification-block_close--icon {
  display: block;
  position: relative;
  top: -4px;
}
.notification-block_icon {
  margin-right: 1em;
  display: inline-block;
  vertical-align: top;
}
.notification-block--disable {
  -webkit-transform: translateX(-100%) translateX(70px);
          transform: translateX(-100%) translateX(70px);
  /* IE 11 */
  max-height: 80px;
}
.notification-block--disable .notification-block__show-icon {
  opacity: 1;
  transition: opacity 0.25s ease 0.5s;
  z-index: 10;
}
.notification-block--disable .notification-block_container {
  opacity: 0;
}
.notification-block__show-icon {
  opacity: 0;
  position: absolute;
  right: 18px;
  transition: opacity 0.25s ease;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.home-no-swiper-container {
  display: block;
}
@media all and (min-width: 900px) {
  .home-no-swiper-container {
    display: block;
  }
}
.home-no-swiper-container:not(.news-swiper) .home-item {
  background: transparent;
}
.home-no-swiper-container:not(.news-swiper) .home-item__container {
  padding-top: 0;
  color: #fff;
}
.home-no-swiper-container.news-swiper .home-item:hover, .home-no-swiper-container.news-swiper .home-item:focus {
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.4);
}

.home-block .news-swiper .link__icon svg {
  fill: #222;
}

@media all and (max-width: 559px) {
  .home-swiper-container {
    margin-bottom: 20px;
  }
}
.home-swiper-container .home-item {
  background: transparent;
}
.home-swiper-container .home-item__container {
  padding-top: 20px;
  color: #fff;
}
.home-swiper-container .swiper-container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.home-swiper-container .swiper-container .swiper-notification {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1000;
}
.home-swiper-container .swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.home-swiper-container .swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.home-swiper-container .swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
  z-index: 1;
}
.home-swiper-container .swiper-container-android .swiper-slide,
.home-swiper-container .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.home-swiper-container .swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.home-swiper-container .swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}
.home-swiper-container .swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  width: 100%;
}
.home-swiper-container .swiper-wp8-horizontal {
  touch-action: pan-y;
}
.home-swiper-container .swiper-wp8-vertical {
  touch-action: pan-x;
}
.home-swiper-container .swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: 300ms;
  z-index: 10;
}
.home-swiper-container .swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.home-swiper-container .swiper-pagination-bullet {
  background: #000;
  border-radius: 100%;
  display: inline-block;
  height: 8px;
  opacity: 0.2;
  width: 8px;
}
.home-swiper-container button.swiper-pagination-bullet {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-width: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.home-swiper-container .swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.home-swiper-container .swiper-pagination-bullet-active {
  background: #007aff;
  opacity: 1;
}
.home-swiper-container .swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
.home-swiper-container .swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
  display: block;
  margin: 5px 0;
}
.home-swiper-container .swiper-container-horizontal > .swiper-pagination {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.home-swiper-container .swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
}
@media all and (min-width: 900px) {
  .home-swiper-container {
    display: none;
  }
}
.home-swiper-container .swiper-button--prev,
.home-swiper-container .swiper-button--next {
  display: inline-block;
  height: 20px;
  margin: 0px 2px;
}
.home-swiper-container .swiper-pagination {
  display: inline-block;
  height: 20px;
  margin: 0 13px;
  position: relative;
}
.home-swiper-container .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #fff;
  opacity: 1;
}
.home-swiper-container .swiper-pagination-bullet-active {
  background: #fff;
}
.home-swiper-container .swiper-pagination-bullet:not(:last-child) {
  margin-right: 6px;
}
.home-swiper-container .swiper-navigation {
  display: flex;
  justify-content: center;
}
.home-swiper-container .swiper-button--next img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.date_block {
  background-color: #fff;
  padding: 50px 0;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .date_block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .date_block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .date_block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .date_block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .date_block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .date_block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .date_block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .date_block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .date_block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .date_block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .date_block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .date_block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.date_block_container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 850px) {
  .date_block_container {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.date_block_title {
  padding-bottom: 40px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
@media all and (min-width: 1120px) {
  .date_block_title {
    font-size: 24px;
  }
}
.date_block_legend_title {
  font-weight: bold;
}
.date_block_legend_time span {
  border-radius: 3px;
  width: 22px;
  height: 12px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: -1px;
}
.date_block_legend_time_none {
  border: 1px solid #bcbcbc;
}
.date_block_legend_time_low {
  background: #f8f0c8;
}
.date_block_legend_time_medium {
  background: #f9d898;
}
.date_block_legend_time_high {
  background: #f3ab80;
}
.date_block .date_schedule_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}
@media all and (max-width: 559px) {
  .date_block .date_schedule_container {
    flex-direction: column;
    gap: 0;
  }
}
@media all and (max-width: 559px) {
  .date_block .date_schedule_container_text {
    width: 100%;
  }
}
.date_block_times {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  width: 50%;
}
@media all and (max-width: 559px) {
  .date_block_times {
    display: block;
    width: 100%;
  }
}
.date_block_times p {
  min-width: 150px;
}
.date_block_times--list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.date_block_times--info {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.date_block_times--label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
  margin-bottom: 15px;
}
@media all and (min-width: 1120px) {
  .date_block_times--label {
    font-size: 30px;
  }
}
.date_block_times--notification {
  font-style: italic;
  margin-bottom: 30px;
  margin-right: 20px;
}
.date_block_times--notification:before {
  content: "*";
  color: #ffd700;
  font-style: normal;
}
.date_block_times ul > .departure_time--wait-05u time {
  background: linear-gradient(to right, transparent, #f8f0c8);
}
.date_block_times ul > .departure_time--wait-05u ~ .departure_time--wait-05u time {
  background: #f8f0c8;
}
.date_block_times ul > .departure_time--wait-1ur time, .date_block_times ul > .departure_time--wait-05- time {
  background: linear-gradient(to right, transparent, #f9d898);
}
.date_block_times ul > .departure_time--wait-1ur ~ .departure_time--wait-1ur time, .date_block_times ul > .departure_time--wait-1ur ~ .departure_time--wait-05- time, .date_block_times ul > .departure_time--wait-05- ~ .departure_time--wait-1ur time, .date_block_times ul > .departure_time--wait-05- ~ .departure_time--wait-05- time {
  background: #f9d898;
}
.date_block_times ul > .departure_time--wait-15u time, .date_block_times ul > .departure_time--wait-2ur time, .date_block_times ul > .departure_time--wait-2-3 time {
  background: linear-gradient(to right, transparent, #f3ab80);
}
.date_block_times ul > .departure_time--wait-15u ~ .departure_time--wait-15u time, .date_block_times ul > .departure_time--wait-15u ~ .departure_time--wait-2ur time, .date_block_times ul > .departure_time--wait-15u ~ .departure_time--wait-2-3 time, .date_block_times ul > .departure_time--wait-2ur ~ .departure_time--wait-15u time, .date_block_times ul > .departure_time--wait-2ur ~ .departure_time--wait-2ur time, .date_block_times ul > .departure_time--wait-2ur ~ .departure_time--wait-2-3 time, .date_block_times ul > .departure_time--wait-2-3 ~ .departure_time--wait-15u time, .date_block_times ul > .departure_time--wait-2-3 ~ .departure_time--wait-2ur time, .date_block_times ul > .departure_time--wait-2-3 ~ .departure_time--wait-2-3 time {
  background: #f3ab80;
}
.date_block_times ul li {
  width: 50px;
}
.date_block_times ul li time {
  border-radius: 3px;
  padding: 2px 5px;
}
.date_block_times ul li.departure_time--wait-05 + .departure_time--normal:not(.departure_time--wait-05) time,
.date_block_times ul li.departure_time--wait-05 + .departure_time--extra:not(.departure_time--wait-05) time {
  background: linear-gradient(to right, #f8f0c8, transparent);
}
.date_block_times ul li.departure_time--wait-1u + .departure_time--normal:not(.departure_time--wait-1u) time,
.date_block_times ul li.departure_time--wait-1u + .departure_time--extra:not(.departure_time--wait-1u) time {
  background: linear-gradient(to right, #f9d898, transparent);
}
.date_block_times ul li.departure_time--wait-15 + .departure_time--normal:not(.departure_time--wait-15) time,
.date_block_times ul li.departure_time--wait-15 + .departure_time--extra:not(.departure_time--wait-15) time {
  background: linear-gradient(to right, #f3ab80, transparent);
}
.date_block_times ul li.departure_time--risico span, .date_block_times ul li.departure_time--notification span {
  position: relative;
  top: -0.5em;
  margin-left: -3px;
  font-size: 80%;
}
.date_block_times ul li.departure_time--risico {
  text-decoration: line-through;
}
.date_block_times ul li.departure_time--notification span {
  color: #ffd700;
}
.date_block_message {
  color: #909090;
}
.date_block_form {
  margin-bottom: 30px;
}
.date_block_form--title {
  float: none;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media all and (min-width: 1120px) {
  .date_block_form--title {
    float: left;
  }
}
.date_block_form .flatpickr, .date_block_form--button {
  width: 160px;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  border: 1px solid #bcbcbc;
  transition: border 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
}
.date_block_form .flatpickr:focus, .date_block_form .flatpickr:hover, .date_block_form .flatpickr.selected, .date_block_form--button:focus, .date_block_form--button:hover, .date_block_form--button.selected {
  border: solid 1px #ffd700;
  background-color: #f7f7f7;
}
@media all and (min-width: 900px) {
  .date_block_form .flatpickr, .date_block_form--button {
    margin-bottom: 0;
  }
}
@media all and (max-width: 559px) {
  .date_block_form .flatpickr, .date_block_form--button {
    width: 100%;
  }
}
.date_block .date_block_form--button {
  margin-right: 10px;
  outline: 0;
}
.date_block .date_block_form--button:focus, .date_block .date_block_form--button:hover, .date_block .date_block_form--button.selected {
  border: solid 1px #ffd700;
  background: #f7f7f7;
}
.date_block .flatpickr {
  width: 160px;
  background: url("/static/icons/icon-calendar.svg") no-repeat scroll 30px 10px;
  padding-left: 30px;
}
@media all and (max-width: 559px) {
  .date_block .flatpickr {
    width: 100%;
  }
}
.date_block .flatpickr::-webkit-input-placeholder {
  color: #222;
}
.date_block .flatpickr::-moz-placeholder {
  color: #222;
}
.date_block .flatpickr::-ms-input-placeholder {
  color: #222;
}
.date_block .flatpickr::placeholder {
  color: #222;
}
.date_block .flatpickr[type=date]:after {
  content: attr(placeholder);
}

.message-block {
  color: #f00;
  margin-bottom: 40px;
}
.message-block .message-block_container {
  display: flex;
}
.message-block .message-block_icon {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.message-block .message-block_icon svg path {
  fill: #f00;
}
.message-block .message-block_icon svg circle {
  fill: #f00;
}
.message-block .message-block_message {
  display: flex;
  align-items: center;
}
.message-block .message-block_message a {
  text-decoration: underline;
}

.date_info .info-block {
  overflow: hidden;
  margin-bottom: 16px;
}
.date_info .info-icon {
  display: inline-block;
  width: 45px;
  margin-right: 15px;
  vertical-align: top;
}
.date_info .info-icon svg {
  width: 100%;
  height: 100%;
}
.date_info .info-icon svg path {
  fill: #ffd700;
}
.date_info p {
  display: inline-block;
  vertical-align: top;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  line-height: 18px;
  max-width: calc(100% - 80px);
}
.date_info .live-block {
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
}
.date_info .live-icon {
  display: inline-block;
  flex: 1 0 45px;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  vertical-align: top;
  position: relative;
}
.date_info .live-icon .live-icon-inner {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ffd700;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.date_info .live-icon .live-icon-outer {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #ffd700;
  border-radius: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: ping 2s infinite;
          animation: ping 2s infinite;
}
@-webkit-keyframes ping {
  0% {
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    width: 35px;
    height: 35px;
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    width: 35px;
    height: 35px;
    opacity: 0;
  }
}
.date_info .live-text {
  display: flex;
  width: 100%;
  align-items: center;
}
.date_info--times-info {
  margin-top: auto;
}

/* druktekalender */
.popular-times {
  background-color: #fff;
  padding: 50px 0;
  font-size: 0;
}
@media only screen and (min-width: 320px) {
  .popular-times {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .popular-times {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .popular-times {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .popular-times {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .popular-times {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .popular-times {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .popular-times {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .popular-times {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .popular-times {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .popular-times {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .popular-times {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .popular-times {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.popular-times h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .popular-times h3 {
    font-size: 30px;
  }
}
.popular-times p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  margin-bottom: 50px;
  max-width: 50%;
}
@media all and (max-width: 900px) {
  .popular-times p {
    max-width: 100%;
  }
}
@media all and (min-width: 320px) and (max-width: 899px) {
  .popular-times .busy_block_times_table_wrapper {
    margin-left: -5%;
  }
}
@media all and (max-width: 559px) {
  .popular-times .busy_block_times_table_wrapper {
    margin-left: -7%;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .popular-times .busy_block_times_table_wrapper {
    margin-left: -20%;
  }
}
@media all and (min-width: 900px) and (max-width: 1119px) {
  .popular-times .busy_block_times_table_wrapper {
    margin-left: -20%;
  }
}
.popular-times .busy_block_times {
  display: inline-block;
  width: 100%;
  margin-bottom: 50px;
}
@media all and (min-width: 1120px) {
  .popular-times .busy_block_times {
    width: 50%;
    padding-right: 10%;
  }
}
.popular-times .busy_block_times .weekday {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 24%;
  height: 24px;
  line-height: 24px;
  padding-right: 20px;
}
.popular-times .busy_block_times .weekday.nodim {
  height: auto;
  line-height: inherit;
}
.popular-times .busy_block_times .day_hour {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.4;
  display: inline-block;
  vertical-align: top;
  width: 9.5%;
  height: 16px;
  line-height: 16px;
}
@media all and (min-width: 1120px) {
  .popular-times .busy_block_times .day_hour {
    width: 9.5%;
  }
}
@media all and (max-width: 900px) {
  .popular-times .busy_block_times .day_hour {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    margin-top: 20px;
    margin-bottom: 5px;
  }
}
.popular-times .busy_block_times .day_hour.left_offset_s {
  margin-left: -5px;
}
.popular-times .busy_block_times .day_hour.left_offset {
  margin-left: -5px;
}
.popular-times .busy_block_times .day_hour:last-child {
  position: absolute;
}
.popular-times .busy_block_times .line {
  display: inline-block;
  vertical-align: top;
  width: 9.5%;
  height: 16px;
  border-left: 1px solid #d8d8d8;
}
.popular-times .busy_block_times .line:last-child {
  border-right: 1px solid #d8d8d8;
}
.popular-times .busy_block_times .split .line {
  height: 5px;
  margin-top: -5px;
}
.popular-times .busy_block_times .duration {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: top;
  width: 76%;
  position: relative;
  background: #f7f7f7;
}
@media all and (min-width: 1120px) {
  .popular-times .busy_block_times .duration {
    width: 76%;
  }
}
.popular-times .busy_block_times .duration dd {
  position: absolute;
  height: 100%;
  background: #ffd700;
  top: 0;
  margin-left: 1px;
}
.popular-times .busy_block_times .duration dd:before {
  content: "";
  position: absolute;
  left: -14px;
  width: 15px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 215, 0, 0), gold 100%);
  border-right: 1px solid #fff;
}
.popular-times .busy_block_times .duration dd:after {
  content: "";
  position: absolute;
  right: -15px;
  width: 16px;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 215, 0, 0), gold 100%);
  border-left: 1px solid #fff;
}

.date_block--iframe {
  margin-right: 20px;
}
.date_block--iframe .date_block_times {
  margin-bottom: 0;
}

.form-block {
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-top: 35px;
  position: relative;
}
@media only screen and (min-width: 320px) {
  .form-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .form-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .form-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .form-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .form-block {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .form-block {
    padding-left: calc(12.5% - 22.5px + 20px);
    padding-right: calc(12.5% - 22.5px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .form-block {
    padding-left: calc(16.6666666667% - 23.3333333333px + 20px);
    padding-right: calc(16.6666666667% - 23.3333333333px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .form-block {
    padding-left: calc(20.8333333333% - 24.1666666667px + 20px);
    padding-right: calc(20.8333333333% - 24.1666666667px + 20px);
  }
}
@media all and (max-width: 559px) {
  .form-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.content-page-transparent .form-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media only screen and (min-width: 900px) {
  .form-block {
    padding-top: 60px;
  }
}
.form-block .form__fieldset-group {
  padding: 20px 0;
}
.form-block .form__fieldset-group + .form__fieldset-group {
  margin-top: 4px;
}
.form-block .rich-text + .form__row {
  margin-top: 30px;
}
.form-block .errorlist {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 600;
  color: #f00;
  border-top: 2px solid currentColor;
}
.form-block .totalfee {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 600;
  border-top: 2px solid currentColor;
  text-align: right;
}
@media all and (min-width: 1120px) {
  .form-block {
    padding-bottom: 80px;
    padding-top: 45px;
  }
}
.form-block .form__fieldset {
  clear: both;
}
.form-block .form__fieldset:after {
  content: " ";
  display: table;
  clear: both;
}
.form-block .form__fieldset .form__legend {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
}
.form-block .form__fieldset .form__text {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.form-block .form__fieldset .form__legend {
  display: block;
  float: left;
  width: 100%;
}
.form-block .form__fieldset + .form__fieldset {
  margin-top: 20px;
}
.form-block .form__row {
  clear: both;
}
.form-block .form__row:after {
  content: " ";
  display: table;
  clear: both;
}
.form-block .form__row:after {
  content: " ";
  display: table;
  clear: both;
}
.form-block .form__row.form__row--footer {
  display: block;
  margin-top: 20px;
  flex-direction: row-reverse;
  font-size: 0;
}
.form-block .form__row.form__row--footer:after {
  content: " ";
  display: table;
  clear: both;
}
.form-block .form__row.form__row--footer .button {
  min-width: 50px;
}
.form-block .form__row.form__row--footer .button--submit,
.form-block .form__row.form__row--footer .button--skip {
  float: right;
}
.form-block .form__row.form__row--footer .button--back {
  padding-left: 0;
  padding-right: 0;
}
.form-block .form__row .button {
  margin: 0;
  min-width: 25%;
}
.form-block .form__row .form__links {
  flex: 1 1 100%;
}
.form-block .form__row--right {
  text-align: right;
}
@media all and (max-width: 559px) {
  .form-block .form__row .form-field + .form-field {
    margin-top: 10px;
  }
  .form-block .form__row .button {
    margin-top: 10px;
    min-width: 100%;
  }
}
@media all and (min-width: 900px) {
  .form-block .form__row {
    display: flex;
    flex-direction: row;
  }
  .form-block .form__row .form-field {
    flex-grow: 2;
    min-width: 10%;
  }
  .form-block .form__row .button {
    margin-bottom: 0;
  }
}
.form-block .form__row .no-margin {
  margin: 0 !important;
  height: 0;
}
.form-block .rich-text h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .form-block .rich-text h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.form-block .rich-text h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .form-block .rich-text h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .form-block .rich-text h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .form-block .rich-text h2 {
    margin-bottom: 20px;
  }
}
.form-block .rich-text h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .form-block .rich-text h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .form-block .rich-text h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .form-block .rich-text h3 {
    margin-bottom: 20px;
  }
}
.form-block .rich-text h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .form-block .rich-text h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .form-block .rich-text h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .form-block .rich-text h4 {
    margin-bottom: 20px;
  }
}
.form-block .rich-text p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .form-block .rich-text p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .form-block .rich-text p {
    margin-bottom: 20px;
  }
}
.form-block .rich-text p + h2, .form-block .rich-text p + h3, .form-block .rich-text p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .form-block .rich-text p + h2, .form-block .rich-text p + h3, .form-block .rich-text p + h4 {
    padding-top: 20px;
  }
}
.form-block .rich-text ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.form-block .rich-text ul li {
  padding: 0.5em 1em;
}
.form-block .rich-text a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .form-block .rich-text a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.form-block .rich-text a:hover {
  border-bottom: 1px solid transparent;
}
.form-block .rich-text h2 {
  margin-bottom: 50px;
}

.prd-btns {
  float: right;
}
.prd-btns a {
  margin-bottom: 10px;
}

.product {
  max-width: 50%;
}

.hidden_row {
  display: none !important;
}

h4.payment_heading {
  padding: 25px 0 15px;
  margin-bottom: 0;
}

.form__row.payment_methods {
  clear: both;
}
.form__row.payment_methods:after {
  content: " ";
  display: table;
  clear: both;
}
.form__row.payment_methods:after {
  content: " ";
  display: table;
  clear: both;
}
.form__row.payment_methods.form__row--footer {
  display: block;
  margin-top: 20px;
  flex-direction: row-reverse;
  font-size: 0;
}
.form__row.payment_methods.form__row--footer:after {
  content: " ";
  display: table;
  clear: both;
}
.form__row.payment_methods.form__row--footer .button {
  min-width: 50px;
}
.form__row.payment_methods.form__row--footer .button--submit,
.form__row.payment_methods.form__row--footer .button--skip {
  float: right;
}
.form__row.payment_methods.form__row--footer .button--back {
  padding-left: 0;
  padding-right: 0;
}
.form__row.payment_methods .button {
  margin: 0;
  min-width: 25%;
}
.form__row.payment_methods .form__links {
  flex: 1 1 100%;
}
.form__row.payment_methods--right {
  text-align: right;
}
@media all and (max-width: 559px) {
  .form__row.payment_methods .form-field + .form-field {
    margin-top: 10px;
  }
  .form__row.payment_methods .button {
    margin-top: 10px;
    min-width: 100%;
  }
}
@media all and (min-width: 900px) {
  .form__row.payment_methods {
    display: flex;
    flex-direction: row;
  }
  .form__row.payment_methods .form-field {
    flex-grow: 2;
    min-width: 10%;
  }
  .form__row.payment_methods .button {
    margin-bottom: 0;
  }
}
.form__row.payment_methods label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  padding-left: 55px !important;
}
.form__row.payment_methods input[type=radio] + label {
  background-repeat: no-repeat;
  background-position: 15px 50%;
  background-size: 32px 32px;
  width: 100%;
}
.form__row.payment_methods input[type=radio] + label[data-method=ideal] {
  background-image: url("/static/images/logos/iDeal-logo.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=mastercard] {
  background-image: url("/static/images/logos/Mastercard-logo.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=visa] {
  background-image: url("/static/images/logos/Visa-logo.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=paypal] {
  background-image: url("/static/images/logos/Paypal-logo.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=bancontactmistercash] {
  background-image: url("/static/images/logos/bancontact.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=sofortberweisung] {
  background-image: url("/static/images/logos/sofort.png");
}
.form__row.payment_methods input[type=radio] + label[data-method=americanexpress] {
  background-image: url("/static/images/logos/american-express.png");
}

.exta-ticket-link {
  display: block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  font-weight: bold;
  margin-bottom: 30px;
}

.ticket-row {
  clear: both;
  margin-bottom: 10px;
  min-height: 30px;
}
.ticket-row:after {
  content: " ";
  display: table;
  clear: both;
}

.edit-contact {
  float: right;
}

.product-list {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.extra-ticket-link {
  clear: both;
  margin-bottom: 30px;
  display: block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
}

h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media all and (min-width: 1120px) {
  h4 {
    font-size: 24px;
  }
}

.checkout-page {
  background-color: #222;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.checkout-page .page-header {
  min-height: 30px;
  margin-bottom: 0;
}
.checkout-page p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  line-height: 18px;
}

.total_fee {
  text-align: right;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 30px;
}
@media all and (min-width: 1120px) {
  .total_fee {
    font-size: 30px;
  }
}

#id_license_plate {
  text-transform: uppercase;
}

.checkout-page .departure_day__container:not(.active) {
  display: none;
}
.checkout-page .departure_day__container.active {
  display: block;
}
.checkout-page .extra-field-activator {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
  display: none;
  margin-top: 30px;
}
.checkout-page .toggle_second {
  margin-top: 20px;
}
.checkout-page .form-content:not(.all_fields_visible) .extra-field-activator {
  display: block;
}
.checkout-page .form-content:not(.all_fields_visible) .extra-field-activator.hidden {
  display: none;
}
.checkout-page .form-content:not(.all_fields_visible) .toggle_second.form__row {
  display: none;
}
.checkout-page .form-content:not(.all_fields_visible) .toggle_second.form__row.active {
  display: block;
}

.tickets-no_plates_messages {
  margin: 0px 0px 15px 0px;
}

.ticketflow-policy-accept {
  margin: 10px 0px;
}
.ticketflow-policy-accept a {
  text-decoration: underline;
}

.form-create-teso-account {
  margin: 10px 0px 25px 0px;
}
.form-create-teso-account a {
  text-decoration: underline;
}

label[for=id_create_account] {
  padding: 0px 15px 0px 5px;
}
label[for=id_create_account] .teso-vinkje {
  top: 3px;
}
label[for=id_create_account] .c-tooltip {
  position: absolute;
  right: -10px;
  top: 1px;
}

span.uppercase {
  text-transform: uppercase;
}

.checkout-page--thankyou .payment-icon {
  display: inline-block;
  margin-right: 5px;
  background: #ffd700;
  height: 1em;
  width: 32px;
  height: 32px;
  vertical-align: top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.checkout-page--thankyou .payment-icon__paid {
  background-image: url("/static/icons/order_succes.svg");
}
.checkout-page--thankyou .payment-icon__pending {
  background-image: url("/static/icons/order_pending.svg");
}
.checkout-page--thankyou .payment-icon + p {
  vertical-align: baseline;
}
.checkout-page--thankyou .vinkje-txt {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 20px;
  vertical-align: top;
  margin-left: 0;
}
.checkout-page--thankyou .card_resume {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding-top: 20px;
  max-width: 300px;
  margin-bottom: 20px;
}
.checkout-page--thankyou .card_resume .card-head {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.checkout-page--thankyou .card_resume .card_ticket {
  clear: both;
  margin-bottom: 10px;
  height: 21px;
  overflow: hidden;
}
.checkout-page--thankyou .card_resume .card_ticket .credits {
  float: right;
  width: 16px;
  margin-left: 12px;
  text-align: right;
}
.checkout-page--thankyou .content-stream__block.order--confirmation {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    padding-left: calc(8.3333333333% - 21.6666666667px + 20px);
    padding-right: calc(8.3333333333% - 21.6666666667px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    padding-left: calc(16.6666666667% - 23.3333333333px + 20px);
    padding-right: calc(16.6666666667% - 23.3333333333px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.order--confirmation {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
.checkout-page--thankyou .content-stream__block.order--specs {
  background: #fff;
  padding-bottom: 40px;
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    padding-left: calc(8.3333333333% - 21.6666666667px + 20px);
    padding-right: calc(8.3333333333% - 21.6666666667px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    padding-left: calc(16.6666666667% - 23.3333333333px + 20px);
    padding-right: calc(16.6666666667% - 23.3333333333px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.order--specs {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
.checkout-page--thankyou .content-stream__block.order--specs .inner {
  background: #F2F2F2;
  margin-left: -10%;
  margin-right: -10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.checkout-page--thankyou .content-stream__block.more-info {
  background: #fff;
  padding-bottom: 40px;
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    padding-left: calc(8.3333333333% - 21.6666666667px + 20px);
    padding-right: calc(8.3333333333% - 21.6666666667px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    padding-left: calc(16.6666666667% - 23.3333333333px + 20px);
    padding-right: calc(16.6666666667% - 23.3333333333px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info {
    padding-left: calc(25% - 25px + 20px);
    padding-right: calc(25% - 25px + 20px);
  }
}
.checkout-page--thankyou .content-stream__block.more-info h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info h2 {
    margin-bottom: 20px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info h3 {
    margin-bottom: 20px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info h4 {
    margin-bottom: 20px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .checkout-page--thankyou .content-stream__block.more-info p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .checkout-page--thankyou .content-stream__block.more-info p {
    margin-bottom: 20px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info p + h2, .checkout-page--thankyou .content-stream__block.more-info p + h3, .checkout-page--thankyou .content-stream__block.more-info p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou .content-stream__block.more-info p + h2, .checkout-page--thankyou .content-stream__block.more-info p + h3, .checkout-page--thankyou .content-stream__block.more-info p + h4 {
    padding-top: 20px;
  }
}
.checkout-page--thankyou .content-stream__block.more-info ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.checkout-page--thankyou .content-stream__block.more-info ul li {
  padding: 0.5em 1em;
}
.checkout-page--thankyou .content-stream__block.more-info a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .checkout-page--thankyou .content-stream__block.more-info a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.checkout-page--thankyou .content-stream__block.more-info a:hover {
  border-bottom: 1px solid transparent;
}
.checkout-page--thankyou .content-stream__block.more-info a.button {
  margin-left: 0;
}
.checkout-page--thankyou .content-stream__block.more-info a.button .button__icon--shape {
  transition: fill 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  fill: #222;
}
.checkout-page--thankyou .content-stream__block.more-info a.button svg {
  fill: #222;
}
.checkout-page--thankyou .content-stream__block.more-info a.button:hover, .checkout-page--thankyou .content-stream__block.more-info a.button:focus {
  background-color: #222;
  color: #fff;
}
.checkout-page--thankyou .content-stream__block.more-info a.button:hover svg, .checkout-page--thankyou .content-stream__block.more-info a.button:focus svg {
  fill: #fff;
}
.checkout-page--thankyou h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media all and (min-width: 1120px) {
  .checkout-page--thankyou h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
.checkout-page--thankyou .teso-checkbox {
  margin-bottom: 30px;
}

.menu-row {
  background: white;
  display: flex;
  list-style: none;
  height: 60px;
}
@media only screen and (min-width: 320px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .menu-row {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (min-width: 560px) {
  .menu-row {
    width: 98%;
    margin: 0 -10px 0 10px;
  }
}
@media only screen and (min-width: 900px) {
  .menu-row {
    margin: 0;
  }
}
@media only screen and (min-width: 900px) and (min-width: 320px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 560px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 900px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 1120px) {
  .menu-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.menu-item {
  width: 20%;
  background: white;
  border: 1px solid #D3D3D3;
  border-top: none;
  border-left: none;
  padding: 16px 0 19px 18px;
  box-sizing: content-box;
}
.menu-item:hover {
  border-color: #ffd700;
  background: #ffd700;
}
.menu-item:hover:last-of-type {
  border-right: 1px solid #ffd700;
}
.menu-item:last-of-type {
  border-right: 1px solid white;
}

.menu-item--active {
  border-color: #ffd700;
  background: #ffd700;
}

.menu-link {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.white-row {
  background: white;
  height: 60px;
}
@media only screen and (min-width: 320px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .white-row {
    width: 100%;
    margin-left: 0;
  }
}

body.tinted {
  background-color: #222;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.my-teso-page .page-header {
  min-height: 30px;
  margin-bottom: 0;
  margin-top: 50px;
}
.my-teso-page .page-header .content-header__title {
  position: relative;
  margin-bottom: 60px;
}
.my-teso-page .page-header .page-header__container figure {
  background-color: #222;
  background-size: cover;
}

.my-teso-page-login_wrapper {
  background: #fff;
  padding: 60px 0px;
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-login_wrapper {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-login_wrapper {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.my-teso-page-login_wrapper h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login-form {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-login_wrapper .login-form {
    width: calc(41.6666666667% - 28.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login-form {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login-form {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login-form {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-login_wrapper .login-form {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login-form {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login-form {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login__no-account {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-login_wrapper .login__no-account {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login__no-account {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login__no-account {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login__no-account {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-login_wrapper .login__no-account {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login__no-account {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login__no-account {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps {
  margin-bottom: 20px;
}
.my-teso-page-login_wrapper .login_teso-account-usps h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h2 {
    margin-bottom: 20px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h3 {
    margin-bottom: 20px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login_teso-account-usps h4 {
    margin-bottom: 20px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .my-teso-page-login_wrapper .login_teso-account-usps p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-login_wrapper .login_teso-account-usps p {
    margin-bottom: 20px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps p + h2, .my-teso-page-login_wrapper .login_teso-account-usps p + h3, .my-teso-page-login_wrapper .login_teso-account-usps p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .my-teso-page-login_wrapper .login_teso-account-usps p + h2, .my-teso-page-login_wrapper .login_teso-account-usps p + h3, .my-teso-page-login_wrapper .login_teso-account-usps p + h4 {
    padding-top: 20px;
  }
}
.my-teso-page-login_wrapper .login_teso-account-usps ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.my-teso-page-login_wrapper .login_teso-account-usps ul li {
  padding: 0.5em 1em;
}
.my-teso-page-login_wrapper .login_teso-account-usps a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .my-teso-page-login_wrapper .login_teso-account-usps a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.my-teso-page-login_wrapper .login_teso-account-usps a:hover {
  border-bottom: 1px solid transparent;
}
.my-teso-page-login_wrapper .login_teso-account-usps h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}
.my-teso-page-login_wrapper .login_teso-account-usps p {
  margin-bottom: 0;
}

.my-teso__white-bottom-row {
  background: #fff;
  height: 60px;
}
@media only screen and (min-width: 320px) {
  .my-teso__white-bottom-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso__white-bottom-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso__white-bottom-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso__white-bottom-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .my-teso__white-bottom-row {
    width: 100%;
    margin: 0;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .my-teso__white-bottom-row {
    width: 100%;
    margin: 0;
  }
}
.my-teso__white-row-padder {
  background: #fff;
  width: 100%;
  height: 61px;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  left: 0;
  display: none;
}
@media only screen and (min-width: 560px) {
  .my-teso__white-row-padder {
    display: block;
  }
}
@media all and (min-width: 900px) {
  .my-teso__white-row-padder {
    width: calc(100% + 42px);
    left: -21px;
  }
}
.my-teso__white-fill-column {
  background-color: #fff;
  height: 500px;
  position: relative;
  display: none;
}
@media only screen and (min-width: 320px) {
  .my-teso__white-fill-column {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso__white-fill-column {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso__white-fill-column {
    width: calc(56.25% - 31.25px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso__white-fill-column {
    width: calc(62.5% - 32.5px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso__white-fill-column {
    display: inline-block;
  }
  .my-teso__white-fill-column:before {
    content: "";
    width: 20px;
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    bottom: 0;
    left: -20px;
  }
}
.my-teso__white-column {
  background-color: #fff;
  height: 500px;
  position: relative;
  display: none;
}
@media only screen and (min-width: 320px) {
  .my-teso__white-column {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso__white-column {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso__white-column {
    width: calc(6.25% - 21.25px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso__white-column {
    width: calc(12.5% - 22.5px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso__white-column {
    display: inline-block;
  }
  .my-teso__white-column.first:after {
    content: "";
    width: 20px;
    position: absolute;
    height: 100%;
    background-color: transparent;
    top: 0;
    bottom: 0;
    right: -20px;
  }
  .my-teso__white-column.last:before {
    content: "";
    width: 20px;
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    bottom: 0;
    left: -20px;
  }
}

.my-teso-page-content {
  margin-right: auto;
  padding: 0;
}
.my-teso-page-content:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .my-teso-page-content {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-content {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-content {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-content {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-content section.content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-content section.content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-content section.content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-content section.content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page-content section.content {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page-content section.content {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page-content section.content {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page-content section.content {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media all and (max-width: 559px) {
  .my-teso-page-content section.content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.myteso-cards--container {
  margin-right: auto;
  padding: 0;
}
.myteso-cards--container:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .myteso-cards--container {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-cards--container {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-cards--container {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .myteso-cards--container {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}

.myteso-card--container {
  background-color: transparent;
  height: 100%;
  position: relative;
}
.myteso-card--container::after {
  content: "";
  width: 10px;
  position: absolute;
  height: 101%;
  background-color: white;
  top: -1px;
  right: -11px;
}
.myteso-card--container::before {
  content: "";
  width: 10px;
  position: absolute;
  height: 101%;
  background-color: white;
  top: -1px;
  left: -11px;
}
@media all and (max-width: 559px) {
  .myteso-card--container::before {
    top: -21px;
    left: -21px;
    width: 20px;
    height: 110%;
    background-color: #fff;
  }
  .myteso-card--container::after {
    top: -21px;
    left: -2px;
    height: 20px;
    background-color: #fff;
    width: 102%;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-card--container::after {
    content: "";
    width: 14px;
    position: absolute;
    height: 115%;
    background-color: white;
    top: -61px;
    right: -14px;
  }
  .myteso-card--container::before {
    content: "";
    width: 11px;
    position: absolute;
    height: 115%;
    background-color: white;
    top: -61px;
    left: -11px;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-card--container {
    background-color: transparent;
    height: 100%;
    position: relative;
  }
  .myteso-card--container:after {
    content: "";
    width: 10px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    right: -11px;
  }
  .myteso-card--container:before {
    content: "";
    width: 10px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    left: -11px;
  }
}

.myteso-cards--list {
  list-style: none;
  padding: 0;
  font-size: 0;
  height: 440px;
}
.myteso-cards--list + .white-row {
  height: 100px;
  background: white;
}
@media only screen and (min-width: 320px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .myteso-cards--list + .white-row {
    margin-left: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-cards--list + .white-row {
    width: 98%;
    margin: -50px 10px 0 10px;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-cards--list + .white-row {
    margin-top: 0;
  }
}
@media only screen and (min-width: 900px) and (min-width: 320px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 560px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 900px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 1120px) {
  .myteso-cards--list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.whiteblock {
  background-color: white;
  height: 100%;
  position: relative;
  display: none;
}
@media only screen and (min-width: 320px) {
  .whiteblock {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .whiteblock {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .whiteblock {
    width: calc(6.25% - 21.25px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .whiteblock {
    width: calc(12.5% - 22.5px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .whiteblock {
    display: inline-block;
  }
  .whiteblock:first-of-type:after {
    content: "";
    width: 10px;
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    bottom: 0;
    right: -10px;
  }
  .whiteblock:last-of-type:before {
    content: "";
    width: 10px;
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    bottom: 0;
    left: -10px;
  }
}

.myteso-card {
  text-align: left;
  margin: 0;
  display: inline-block;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  border: 1px solid white;
}
@media only screen and (min-width: 320px) {
  .myteso-card {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-card {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-card {
    width: calc(29.1666666667% - 25.8333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .myteso-card {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .myteso-card {
    margin-top: 20px;
  }
  .myteso-card:after {
    content: "";
    position: absolute;
    top: -21px;
    right: -21px;
    background: white;
    height: 110%;
    width: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .myteso-card {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 900px) {
  .myteso-card {
    margin-bottom: 0;
  }
}

.myteso-card:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 0 6px white;
  width: 100%;
}

.myteso-card--white {
  background-color: white;
}

.myteso-card--content {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding: 18px 20px 20px 25px;
  border: 1px solid #D3D3D3;
  border-radius: 6px;
  box-sizing: border-box;
  height: 100%;
}

.teso-card-header {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 25px;
}
@media all and (min-width: 1120px) {
  .teso-card-header {
    font-size: 24px;
  }
}

.myteso-card--buttonblock {
  position: absolute;
  bottom: 25px;
  margin-left: 20px;
  margin-right: 20px;
  left: 0;
}

.myteso-buttonblock--primary {
  width: 100%;
  margin-bottom: 24px;
}

.myteso-buttonblock-icon {
  position: absolute;
  right: 20px;
}

.link {
  font-weight: bold;
}

.card-menu-item {
  display: inline-block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 14.5px;
  margin-bottom: 12.5px;
}
.card-menu-item:last-of-type {
  border: none;
}
@media all and (max-width: 559px) {
  .card-menu-item {
    display: block;
  }
}

.myteso-card--text {
  color: #909090;
}

.myteso--name {
  display: inline-block;
}

.myteso-card--transparent {
  background-image: url("/static/images/logo-teso.svg");
  background-repeat: no-repeat;
  background-size: 50px 20px;
  background-position: bottom 30px right 20px;
  color: white;
}

.myteso-card-text--transparent {
  color: white;
  font-weight: bold;
}

.myteso-card-content--transparent {
  border: none;
}

.myteso-logout-button {
  margin-right: 0px;
  margin-top: 6px;
  float: right;
  z-index: 2;
}

.steps-bar {
  background: #fff;
  color: #222;
  overflow: hidden;
}
@media only screen and (min-width: 320px) {
  .steps-bar {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .steps-bar {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .steps-bar {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .steps-bar {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.steps-bar .steps-bar__item__title,
.steps-bar .steps-bar__item__index {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.steps-bar .steps-bar__items {
  display: flex;
  flex-direction: row;
}
.steps-bar .steps-bar__item {
  flex: 1;
  white-space: nowrap;
}
.steps-bar .steps-bar__item.steps-bar__item--active {
  background: #ffd700;
  color: #222;
}
.steps-bar .steps-bar__item.steps-bar__item--disabled {
  background: #fff;
  color: #bcbcbc;
}
.steps-bar .steps-bar__item.steps-bar__item--active, .steps-bar .steps-bar__item.steps-bar__item--disabled {
  pointer-events: none;
}
.steps-bar .steps-bar__item__anchor {
  display: block;
  padding: 13px;
}
.steps-bar .steps-bar__item__index {
  border: 1px solid currentColor;
  border-radius: 3px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 3px;
  text-align: center;
  transition: background-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  vertical-align: middle;
  width: 20px;
}
.steps-bar .steps-bar__item__index + .steps-bar__item__title {
  margin-left: 3px;
}
.steps-bar .steps-bar__item__title {
  display: inline-block;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.steps-bar .steps-bar__item__title:first-letter {
  text-transform: uppercase;
}
@media all and (min-width: 320px) and (max-width: 899px) {
  .steps-bar .steps-bar__item.steps-bar__item--active {
    flex: 4;
  }
  .steps-bar .steps-bar__item:not(.steps-bar__item--active) .steps-bar__item__title {
    display: none;
  }
}
.steps-bar .steps-bar__item {
  border-bottom: 1px solid #bcbcbc;
  border-right: 1px solid #bcbcbc;
}
.steps-bar .steps-bar__item.steps-bar__item--active {
  border-color: #ffd700;
}
.steps-bar .steps-bar__item.steps-bar__item--disabled {
  border-color: #bcbcbc;
}
.steps-bar .steps-bar__item:first-child {
  border-left-width: 0;
}
.steps-bar .steps-bar__item:last-child {
  border-right-width: 0;
}
.steps-bar .steps-bar__item:hover .steps-bar__item__index {
  background: #222;
  border-color: #222;
  color: #fff;
}
.steps-bar .steps-bar__item:hover.steps-bar__item--active .steps-bar__item__index {
  background: #222;
  border-color: #222;
  color: #ffd700;
}
.steps-bar .steps-bar__item:hover.steps-bar__item--disabled .steps-bar__item__index {
  background: #fff;
  border-color: #bcbcbc;
  color: #fff;
}

.form-errors {
  margin: 0px 0px 20px 0px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.form-errors p {
  font-weight: 700;
}
.form-errors .field-errors {
  color: #f00;
}
.form-errors strong {
  font-weight: 700;
}
.form-errors.hidden {
  display: none;
}

.birthdate-dropdown {
  margin-bottom: 15px;
}
.birthdate-dropdown .form-field__select-container {
  display: flex;
}
.birthdate-dropdown .form-field__select-container .birthdate_day,
.birthdate-dropdown .form-field__select-container .holder_birthdate_day {
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 10px;
  position: relative;
}
.birthdate-dropdown .form-field__select-container .birthdate_day::after,
.birthdate-dropdown .form-field__select-container .holder_birthdate_day::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  color: currentColor;
  content: "";
  height: 40px;
  line-height: 40px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
}
.birthdate-dropdown .form-field__select-container .birthdate_month,
.birthdate-dropdown .form-field__select-container .holder_birthdate_month {
  flex-grow: 3;
  flex-basis: 0;
  margin-right: 10px;
  position: relative;
}
.birthdate-dropdown .form-field__select-container .birthdate_month::after,
.birthdate-dropdown .form-field__select-container .holder_birthdate_month::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  color: currentColor;
  content: "";
  height: 40px;
  line-height: 40px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
}
.birthdate-dropdown .form-field__select-container .birthdate_year,
.birthdate-dropdown .form-field__select-container .holder_birthdate_year {
  flex-grow: 2;
  flex-basis: 0;
  position: relative;
}

.form__row.has-errors input[type=text],
.form__row.has-errors input[type=email],
.form__row.has-errors input[type=number],
.form__row .has-errors input[type=text],
.form__row .has-errors input[type=email],
.form__row .has-errors input[type=number] {
  border-bottom: 1px solid #f00 !important;
}
.form__row.has-errors input[type=radio] + label,
.form__row .has-errors input[type=radio] + label {
  border-bottom: 1px solid #f00 !important;
}
.form__row.has-errors input[type=radio]:checked + label, .form__row.has-errors input[type=radio].is-checked + label,
.form__row .has-errors input[type=radio]:checked + label,
.form__row .has-errors input[type=radio].is-checked + label {
  border: 2px solid #ffd700 !important;
}
.form__row.has-errors select,
.form__row .has-errors select {
  border-bottom: 1px solid #f00 !important;
}
.form__row.account_prefilled {
  display: block;
  padding-bottom: 15px;
}
.form__row.account_prefilled span {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin: 0px 0px 5px 0;
  min-height: 24px;
  cursor: default;
}
.form__row.account_prefilled span:first-letter {
  text-transform: uppercase;
}
.form__row p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  min-height: 40px;
  line-height: 22px;
}
.form__row--small p {
  min-height: 0;
}
.form__row .form__col {
  width: 100%;
}
.form__row .form__col .form-field--single-line {
  margin-bottom: 0;
}
.form__row .form__col .form-field--checkboxes input[type=checkbox] + label {
  color: #222;
}
.form__row .form-field__field-wrapper .form-field--dropdown {
  margin-left: 0;
}

.form-field {
  position: relative;
}
.form-field .form-field__text {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.form-field .form-field__field-wrapper {
  display: block;
  max-width: 100%;
  position: relative;
}
.form-field .form-field__text {
  margin: 5px 0;
}
.form-field .form-field__label {
  font-weight: bold;
}
.form-field input,
.form-field textarea,
.form-field select {
  flex: 1 1 100%;
}
.form-field input:not([type=radio]):not([type=checkbox]) {
  padding-left: 5px !important;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]),
.form-field textarea,
.form-field select {
  width: 100%;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file])::-webkit-input-placeholder {
  color: inherit;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file])::-moz-placeholder {
  color: inherit;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]):-ms-input-placeholder {
  color: inherit;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file])::input-placeholder {
  color: inherit;
}
.form-field label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-field label.is-hidden {
  display: none;
}
.form-field .form-field__error-wrapper {
  margin: 5px 0;
}
.form-field .form-field__label {
  font-weight: bold;
}
@media all and (min-width: 900px) {
  .form-field .form-field {
    margin-left: 20px;
  }
}
.form-field.is-hidden {
  display: none;
}
.form-field input:not([type=radio]):not([type=checkbox]).has-activated:invalid,
.form-field textarea.has-activated:invalid,
.form-field select.has-activated:invalid {
  border-color: #f00 !important;
}
.form-field input[type=radio].has-activated:invalid + label::before,
.form-field input[type=checkbox].has-activated:invalid + label::before {
  border-color: #f00 !important;
}
.form-field label {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin: 0px 0px 5px 0;
  min-height: 24px;
}
.form-field label:first-letter {
  text-transform: uppercase;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]),
.form-field textarea,
.form-field select {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  background-color: #f7f7f7;
  border: none;
  border-bottom: 1px solid #E9E9E9;
  color: currentColor;
  display: inline-block;
  height: 40px;
  line-height: 22px;
  margin: 0;
  min-height: 40px;
  min-width: 50px;
  overflow: hidden;
  padding: 4px 0;
  position: relative;
  transition: color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  vertical-align: bottom;
  white-space: nowrap;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]):hover, .form-field input:not([type=radio]):not([type=checkbox]):not([type=file]):focus,
.form-field textarea:hover,
.form-field textarea:focus,
.form-field select:hover,
.form-field select:focus {
  background-color: rgba(34, 34, 34, 0.1);
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]) + .button,
.form-field textarea + .button,
.form-field select + .button {
  border-color: transparent;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]) + .button:hover, .form-field input:not([type=radio]):not([type=checkbox]):not([type=file]) + .button:focus,
.form-field textarea + .button:hover,
.form-field textarea + .button:focus,
.form-field select + .button:hover,
.form-field select + .button:focus {
  background-color: transparent;
  color: inherit;
}
.form-field input:not([type=radio]):not([type=checkbox]):not([type=file]):disabled, .form-field input:not([type=radio]):not([type=checkbox]):not([type=file]).is-disabled,
.form-field textarea:disabled,
.form-field textarea.is-disabled,
.form-field select:disabled,
.form-field select.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.form-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form-field.form-field--single-line {
  margin-bottom: 15px;
}
.form-field.form-field--number .field-legend {
  float: left;
}
.form-field.form-field--number .form-field__field-wrapper {
  width: 180px;
  float: right;
}
.form-field.form-field--number .form-field__field-wrapper input[type=number]::-webkit-inner-spin-button,
.form-field.form-field--number .form-field__field-wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-field.form-field--number .form-field__field-wrapper input[type=number] {
  width: 48px;
  margin: 0px 10px;
  font-size: 15px;
  border-radius: 3px;
  line-height: 40px;
  font-weight: 500;
  border: 1px solid #bcbcbc;
  text-align: center;
  background-color: transparent;
}
.form-field.form-field--number .form-field__field-wrapper a.counter_button {
  display: inline-block;
  border-radius: 3px;
  height: 40px;
  width: 48px;
  border: 1px solid #bcbcbc;
  line-height: 40px;
  text-align: center;
}
.form-field.form-field--number .form-field__field-wrapper a.counter_button:hover {
  background: #ffd700;
}
.form-field.form-field--multi-line textarea {
  min-height: 10em;
}
.form-field.form-field--dropdown {
  display: block;
}
.form-field.form-field--dropdown select {
  border-radius: 0;
  cursor: pointer;
  padding-right: 38px;
  width: 100%;
}
.form-field.form-field--dropdown select option {
  color: #222;
}
.form-field.form-field--dropdown .form-field__select-container {
  position: relative;
  width: 100%;
}
.form-field.form-field--dropdown .form-field__select-container::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9Ii01MS41IDc2LjUgMTAyNCAxMDI0Ij48cGF0aCBkPSJNMjg5LjgzIDUwMy4xN2gzMjFsLTE2MC41IDE2MC41eiIvPjwvc3ZnPg==");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  color: currentColor;
  content: "";
  height: 40px;
  line-height: 40px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
}
.form-field.form-field--radio-buttons .item__title {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
}
.form-field.form-field--radio-buttons .item__text {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.form-field.form-field--radio-buttons .item__icon {
  clear: left;
  float: left;
  height: 30px;
  width: 30px;
}
.checkout-page--ticket-choice .form-field.form-field--radio-buttons .item__icon {
  width: 36px;
  height: 36px;
}
.checkout-page--ticket-choice .form-field.form-field--radio-buttons .item__icon svg {
  width: 100%;
  height: 100%;
}
.form-field.form-field--radio-buttons .item__title,
.form-field.form-field--radio-buttons .item__text {
  display: block;
  padding-left: 45px;
}
.form-field.form-field--radio-buttons .item__text {
  font-weight: 500;
  color: #909090;
}
.form-field.form-field--radio-buttons input[type=radio] {
  float: left;
  height: 0;
  margin-left: -150vw;
  width: 0;
}
.form-field.form-field--radio-buttons input[type=radio] + label {
  display: block;
  font-weight: normal;
  padding: 16px;
  width: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 3px;
  margin: 20px 0;
}
.form-field.form-field--radio-buttons input[type=radio] + label:hover {
  background-color: #f7f7f7;
}
.form-field.form-field--radio-buttons input[type=radio]:checked + label, .form-field.form-field--radio-buttons input[type=radio].is-checked + label {
  border: 2px solid #ffd700;
  padding: 15px;
}
.form-field.form-field--checkboxes input[type=checkbox] + label {
  text-transform: none;
}
.form-field.form-field--checkboxes input[type=checkbox] {
  height: 0;
  margin-left: -150vw;
  width: 0;
}
.form-field.form-field--checkboxes input[type=checkbox] + label {
  display: block;
  font-weight: normal;
  padding-left: 0px;
  width: 100%;
  cursor: pointer;
  line-height: 26px;
  color: #8c8c8c;
}
.form-field.form-field--checkboxes input[type=checkbox] + label::before {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 1px solid #222;
  border-radius: 3px;
  color: transparent;
  content: "";
  display: inline-block;
  float: left;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-right: 12px;
  margin-bottom: 2em;
  text-align: center;
  transition: border-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  position: relative;
  top: 3px;
}
.form-field.form-field--checkboxes input[type=checkbox] + label a {
  text-decoration: underline;
}
.form-field.form-field--checkboxes input[type=checkbox] + label a:hover, .form-field.form-field--checkboxes input[type=checkbox] + label a:focus {
  text-decoration: none;
}
.form-field.form-field--checkboxes input[type=checkbox] + label + input + label {
  margin-top: 10px;
}
.form-field.form-field--checkboxes input[type=checkbox]:checked + label::before, .form-field.form-field--checkboxes input[type=checkbox].is-checked + label::before {
  background-image: url("/static/icons/teso-icon-checkmark.svg");
  color: currentColor;
  line-height: 13px;
}
.form-field.form-field--checkboxes:after {
  content: " ";
  display: table;
  clear: both;
}
.form-field.form-field--checkboxes.form-field--inline input[type=checkbox] + label {
  display: inline-block;
  margin-right: 20px;
  width: auto;
}
.form__row_dropdown {
  margin-bottom: 15px;
}
.form__row_dropdown label {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin: 0px 0px 5px 0;
  min-height: 24px;
}
.form__row_dropdown label:first-letter {
  text-transform: uppercase;
}
.form__row_dropdown .form-field__field-wrapper,
.form__row_dropdown label {
  flex-grow: 1;
}

.departure_day__container label,
.tickets__container label {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 700;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin: 0px 0px 5px 0;
  min-height: 24px;
  margin-bottom: -10px;
}
.departure_day__container label:first-letter,
.tickets__container label:first-letter {
  text-transform: uppercase;
}

.form__row.tickets__container {
  display: block;
}

.form__row--extra-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.form__row--extra-info-image {
  margin-right: 10px;
}

.form__row--extra-info-text {
  font-weight: unset !important;
  margin-bottom: 10px;
}

.my-teso-page.teso-loose-tickets .buttons {
  text-align: right;
}
@media only screen and (min-width: 320px) {
  .my-teso-page.teso-loose-tickets .buttons {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page.teso-loose-tickets .buttons {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page.teso-loose-tickets .buttons {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page.teso-loose-tickets .buttons {
    width: calc(91.6666666667% - 38.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .my-teso-page.teso-loose-tickets .buttons {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-page.teso-loose-tickets .buttons {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-page.teso-loose-tickets .buttons {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-page.teso-loose-tickets .buttons {
    margin-left: calc(4.1666666667% - 20.8333333333px + 40px);
  }
}
.my-teso-page.teso-loose-tickets .white-p {
  padding-top: 0;
}

.loose-card {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  width: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  padding: 10px;
  margin: 10px 0;
}
@media all and (min-width: 900px) {
  .loose-card {
    width: 31%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.loose-card .my-teso-card__qrcode {
  margin-left: -10px;
  margin-top: -20px;
  margin-right: -30px;
  float: left;
  width: 50%;
}
.loose-card .my-teso-card__qrcode.zoomed .qr_inner svg {
  background: #fff;
}
.loose-card .my-teso-card__qrcode .qr_inner {
  width: 100%;
  padding-bottom: 100%;
}
.loose-card .my-teso-card__qrcode .qr_inner svg {
  background: transparent;
}
.loose-card .my-teso-card__qrcode .qr_inner img {
  width: 70%;
}
.loose-card .my-teso-card__qrcode .qr_inner .zoom-icon {
  top: -10px;
  left: -16px;
}
.loose-card .my-teso-card__qrcode .qr_inner .zoom-icon svg path {
  fill: #222;
}
.loose-card .loose-card__description {
  display: block;
}
.loose-card .loose-card__description:after {
  content: " ";
  display: table;
  clear: both;
}
.loose-card .loose-card__number,
.loose-card .loose-card__date {
  color: #bcbcbc;
}

.table {
  display: flex;
  flex-wrap: wrap;
}
.table__cell {
  flex-grow: 1;
  width: 20%;
  overflow: hidden;
}
.table__cell--date::first-letter {
  text-transform: capitalize;
}

.c-tooltip {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  vertical-align: top;
  white-space: nowrap;
  width: 40px;
  display: inline-block;
  line-height: 40px;
  position: relative;
  vertical-align: text-top;
  top: -4px;
}
.c-tooltip .e-text {
  height: auto;
}
.c-tooltip .e-span {
  margin-left: 0.2em;
  white-space: nowrap;
}
.c-tooltip .e-text,
.c-tooltip .e-close {
  display: none;
}
.c-tooltip:focus,
.c-tooltip .e-text:focus,
.c-tooltip .e-close:focus,
.c-tooltip .e-span:focus {
  outline: none;
}
.c-tooltip .e-text {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.c-tooltip .e-text {
  bottom: 100%;
  display: none;
  left: 50%;
  margin: 20px;
  min-width: 200px;
  padding: 30px 40px;
  position: absolute;
  text-align: left;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  z-index: 9999;
  background-color: #f7f7f7;
  color: #222;
  border: 1px solid #bcbcbc;
  border-radius: 3px;
}
.c-tooltip.has-hover .e-text, .c-tooltip.has-focus .e-text, .c-tooltip.has-click .e-text {
  display: block;
}
.c-tooltip.is-below .e-text {
  bottom: auto;
  margin-top: 20px;
  top: 100%;
}
.c-tooltip.is-align-left .e-text {
  left: -30px;
  -webkit-transform: none;
          transform: none;
}
.c-tooltip.is-align-right .e-text {
  left: auto;
  right: -30px;
  -webkit-transform: none;
          transform: none;
}
@media all and (min-width: 560px) {
  .c-tooltip .e-text {
    min-width: 280px;
  }
}
.c-tooltip::after {
  content: "";
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
}
.c-tooltip.has-click::after {
  display: block;
}
.c-tooltip .e-text::before {
  content: "";
  display: block;
  height: calc(100% + 40px);
  left: -20px;
  position: absolute;
  top: -20px;
  width: calc(100% + 40px);
  z-index: -1;
}
.c-tooltip .e-text::after {
  border: 5px solid transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(100%);
          transform: translateX(-50%) translateY(100%);
  width: 0;
}
.c-tooltip.is-below .e-text::after {
  bottom: auto;
  top: 0;
  -webkit-transform: translateX(-50%) translateY(-100%);
          transform: translateX(-50%) translateY(-100%);
}
.c-tooltip.is-align-left .e-text::after {
  left: 20px;
}
.c-tooltip.is-align-right .e-text::after {
  left: auto;
  right: 20px;
}
.c-tooltip.is-align-left .e-text::after, .c-tooltip.is-align-right .e-text::after {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.c-tooltip.is-align-left.is-below .e-text::after, .c-tooltip.is-align-right.is-below .e-text::after {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.c-tooltip .e-text {
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateX(-50%) translateY(20px);
          transform: translateX(-50%) translateY(20px);
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: transform 500ms, opacity 500ms;
  transition: transform 500ms, opacity 500ms, -webkit-transform 500ms;
  white-space: normal;
}
body.is-ie10 .c-tooltip .e-text {
  display: none;
}
.c-tooltip.is-align-left .e-text, .c-tooltip.is-align-right .e-text {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}
.c-tooltip.has-hover .e-text, .c-tooltip.has-focus .e-text, .c-tooltip.has-click .e-text {
  display: block;
  opacity: 1;
  pointer-events: inherit;
  -webkit-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: transform 500ms, opacity 500ms;
  transition: transform 500ms, opacity 500ms, -webkit-transform 500ms;
}
body.is-ie10 .c-tooltip.has-hover .e-text, body.is-ie10 .c-tooltip.has-focus .e-text, body.is-ie10 .c-tooltip.has-click .e-text {
  display: block;
}
.c-tooltip.has-hover.is-align-left .e-text, .c-tooltip.has-hover.is-align-right .e-text, .c-tooltip.has-focus.is-align-left .e-text, .c-tooltip.has-focus.is-align-right .e-text, .c-tooltip.has-click.is-align-left .e-text, .c-tooltip.has-click.is-align-right .e-text {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.c-tooltip.has-click .e-close {
  cursor: pointer;
  display: block;
  font-size: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  width: 60px;
}
.c-tooltip.has-click .e-close img {
  position: absolute;
  top: 12px;
  right: 12px;
}
.c-tooltip.v-no-icon::before {
  display: none;
}
.c-tooltip.v-no-icon .e-span {
  margin-left: -0.2em;
  text-decoration: underline;
}
.c-tooltip.v-no-icon:hover .e-span, .c-tooltip.v-no-icon:focus .e-span {
  text-decoration: none;
}
.c-tooltip .text-icon-block_icon {
  width: 26px;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}
.c-tooltip dd {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ffd700;
  border-radius: 50%;
  line-height: 15px;
  text-align: center;
  vertical-align: top;
  margin-left: 10px;
}

.trips .mobile-wrapper {
  display: none;
}
@media all and (max-width: 559px) {
  .trips .mobile-wrapper {
    display: block;
  }
}
.trips .widescreen-wrapper {
  display: block;
}
@media all and (max-width: 559px) {
  .trips .widescreen-wrapper {
    display: none;
  }
}
@media only screen and (min-width: 320px) {
  .trips .white {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .trips .white {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .trips .white {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .trips .white {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (min-width: 320px) and (max-width: 899px) {
  .trips .white {
    width: 100%;
    margin: 0;
  }
}
.trips .table-wrapper {
  padding: 10px;
}
.trips__table {
  width: 100%;
}
.trips .table-row-content {
  height: auto;
  min-height: 32px;
  padding: 15px 10px 0px 0px;
}
@media all and (max-width: 559px) {
  .trips .table-row-content {
    min-height: 20px;
    padding: 5px 5px 0px 0px;
  }
}
.trips .table-row-content td {
  text-align: center;
}
.trips .table__cell--mobile-title {
  width: 100%;
  font-weight: bold;
}
.trips .table__cell--mobile-title ~ .table__cell--mobile-title {
  margin-top: 25px;
}
.trips .table__cell--mobile {
  width: 50%;
}
.trips .table__cell--date {
  width: 30%;
}
.trips .table__cell--name {
  width: 25%;
}
.trips .table__cell--product {
  width: 25%;
}
.trips .table__cell--price {
  width: 20%;
}
.trips .table-header {
  text-align: left;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .trips .table-header {
    font-size: 30px;
  }
}
.trips .checkin-type {
  color: grey;
}
@media all and (max-width: 559px) {
  .trips .checkin-type {
    color: black;
  }
}
.trips .trips-info__wrapper {
  padding: 0 0 0 4px;
}
.trips #trips-info__icon {
  vertical-align: middle;
}
.trips #trips-info__tooltip {
  visibility: hidden;
  width: 210px;
  background-color: black;
  font-size: 15px;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  margin-top: 30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.trips .trips-info__wrapper:hover #trips-info__tooltip {
  visibility: visible;
}

.my-teso-cards:after {
  content: " ";
  display: table;
  clear: both;
}

.my-teso-card {
  border: 1px solid #fff;
  display: inline-block;
  font-size: 16px;
  height: 440px;
  margin: 0;
  margin-top: 60px;
  position: relative;
  text-align: left;
  vertical-align: top;
}
@media only screen and (min-width: 320px) {
  .my-teso-card {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-card {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-card {
    width: calc(29.1666666667% - 25.8333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-card {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .my-teso-card {
    margin-top: 20px;
  }
  .my-teso-card::after {
    content: "";
    position: absolute;
    top: -21px;
    right: -21px;
    background: #fff;
    height: 110%;
    width: 20px;
  }
}
.my-teso-card.filler-landscape, .my-teso-card.filler-portrait {
  display: none;
}
@media only screen and (min-width: 560px) {
  .my-teso-card {
    margin-bottom: 0px;
  }
  .my-teso-card.filler-portrait {
    display: block;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-card {
    margin-bottom: 0;
  }
  .my-teso-card.filler-landscape {
    display: block;
  }
  .my-teso-card.filler-portrait {
    display: none;
  }
}
.my-teso-card__content {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding: 18px 20px 20px 20px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  box-sizing: border-box;
  height: 100%;
}
.my-teso-card__content .card_ticket {
  clear: both;
  margin-bottom: 10px;
  height: 21px;
  overflow: hidden;
}
.my-teso-card__content .card_ticket .credits {
  float: right;
  width: 3em;
  margin-left: 12px;
  text-align: right;
}
.my-teso-card__qrcode {
  clear: both;
  text-align: left;
  padding: 10px 0px;
  position: relative;
  height: 120px;
  z-index: 8;
}
.my-teso-card__qrcode .qr_inner {
  width: 50%;
  padding-bottom: 50%;
  position: relative;
  z-index: 5;
}
.my-teso-card__qrcode .qr_inner .zoom-icon {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  transition: opacity 500ms;
  transition-delay: 500ms;
  opacity: 1;
  -webkit-transform: translate(100%, 100%);
          transform: translate(100%, 100%);
}
@media all and (min-width: 560px) {
  .my-teso-card__qrcode .qr_inner .zoom-icon {
    display: none;
  }
}
.my-teso-card__qrcode .qr_inner .zoom-icon svg {
  background: transparent;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 10px;
  top: -32px;
}
.my-teso-card__qrcode svg {
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 500ms, -webkit-transform 500ms;
  transition: transform 500ms, left 500ms;
  transition: transform 500ms, left 500ms, -webkit-transform 500ms;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.my-teso-card__qrcode.zoomed {
  z-index: 10;
}
.my-teso-card__qrcode.zoomed svg {
  -webkit-transform: scale(2) translateX(0%);
          transform: scale(2) translateX(0%);
  left: 0%;
  max-height: 500px;
  max-width: 500px;
}
.my-teso-card__qrcode.zoomed .zoom-icon {
  opacity: 0;
  transition: opacity 10ms;
  transition-delay: 0ms;
}
.my-teso-card__qrcode.zoomed:before {
  display: block;
  content: "";
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #222;
  position: fixed;
  opacity: 0.4;
  z-index: 3;
}
.my-teso-card .card-head {
  display: block;
  margin-bottom: 5px;
}
.my-teso-card::before {
  background: transparent;
  border-radius: 6px;
  box-shadow: 0 0 0 6px #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.my-teso-card--white {
  background-color: #fff;
}
.my-teso-card--white h4 {
  margin-bottom: 30px;
}
.my-teso-card--transparent {
  background-image: url("/static/images/logo-teso.svg");
  background-repeat: no-repeat;
  background-size: 50px 20px;
  background-position: bottom 25px right 20px;
  color: #fff;
}
.my-teso-card__container {
  background-color: transparent;
  height: 100%;
  position: relative;
}
.my-teso-card__container::after {
  content: "";
  width: 10px;
  position: absolute;
  height: 101%;
  background-color: #fff;
  top: -1px;
  right: -11px;
}
.my-teso-card__container::before {
  content: "";
  width: 20px;
  position: absolute;
  height: 101%;
  background-color: #fff;
  top: -1px;
  left: -21px;
}
@media all and (max-width: 559px) {
  .my-teso-card__container::before {
    top: -21px;
    left: -21px;
    width: 20px;
    height: 110%;
    background-color: #fff;
  }
  .my-teso-card__container::after {
    top: -21px;
    left: -2px;
    height: 20px;
    background-color: #fff;
    width: 102%;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-card__container::after {
    content: "";
    width: 21px;
    position: absolute;
    height: 115%;
    background-color: #fff;
    top: -61px;
    right: -21px;
  }
  .my-teso-card__container::before {
    content: "";
    width: 21px;
    position: absolute;
    height: 115%;
    background-color: #fff;
    top: -61px;
    left: -21px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-card__container {
    background-color: transparent;
    height: 100%;
    position: relative;
  }
  .my-teso-card__container::after {
    content: "";
    width: 10px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    right: -11px;
  }
  .my-teso-card__container::before {
    content: "";
    width: 20px;
    position: absolute;
    height: 101%;
    background-color: white;
    top: -1px;
    left: -21px;
  }
}
.my-teso-card__details {
  float: left;
  width: 100%;
}
.my-teso-card__name {
  float: left;
}
.my-teso-card__birthday {
  float: right;
}
.my-teso-card__text {
  color: #909090;
}
.my-teso-card__text--transparent {
  color: #fff;
  font-weight: bold;
}
.my-teso-card h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .my-teso-card h3 {
    font-size: 24px;
  }
}
.my-teso-card .borderline {
  font-size: 0;
  border-bottom: 1px solid #909090;
  margin-bottom: 15px;
}
.my-teso-card__travel {
  color: #909090;
  padding-bottom: 5px;
}
.my-teso-card__button-block {
  bottom: 20px;
  left: 0;
  margin-left: 20px;
  margin-right: 20px;
  position: absolute;
  z-index: 2;
}
.my-teso-card__button-block .button {
  width: 100%;
  margin-bottom: 16px;
}
.my-teso-card__button-block .button__icon {
  position: absolute;
  right: 20px;
}
.my-teso-card__menu-item {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid #bcbcbc;
  padding-bottom: 14.5px;
  margin-bottom: 12.5px;
}
.my-teso-card__menu-item:last-of-type {
  border: none;
}
@media all and (max-width: 559px) {
  .my-teso-card__menu-item {
    display: block;
  }
}

.my-teso-menu {
  display: none;
  background-color: #fff;
  list-style: none;
  height: 60px;
}
@media only screen and (min-width: 320px) {
  .my-teso-menu {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .my-teso-menu {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .my-teso-menu {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-teso-menu {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (min-width: 900px) {
  .my-teso-menu {
    display: flex;
    flex-direction: row;
  }
}
.my-teso-menu__item {
  flex: 1;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #D3D3D3;
  border-top: none;
  border-left: none;
  box-sizing: content-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-teso-menu__item:hover {
  border-color: #ffd700;
  background: #ffd700;
}
.my-teso-menu__item:hover:last-of-type {
  border-right: 1px solid #ffd700;
}
.my-teso-menu__item:last-of-type {
  border-right: 1px solid #fff;
}
.my-teso-menu__item--active {
  background: #ffd700;
  border-color: #ffd700;
}
.my-teso-menu__item a {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  display: block;
  height: 100%;
  padding: 16px 0 19px 18px;
  width: 100%;
}
.my-teso-menu--mobile {
  display: block;
  background-color: #fff;
  flex-direction: row;
  list-style: none;
  padding: 15px 20px;
}
@media all and (min-width: 900px) {
  .my-teso-menu--mobile {
    display: none;
  }
}

.teso-cards-row-container {
  margin-right: auto;
  padding: 0;
}
.teso-cards-row-container:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .teso-cards-row-container {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .teso-cards-row-container {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .teso-cards-row-container {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .teso-cards-row-container {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}

.teso-row-opwaarderen {
  display: inline-block;
  vertical-align: top;
}

.teso-card-row-header {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
}
@media all and (min-width: 1120px) {
  .teso-card-row-header {
    font-size: 30px;
  }
}
.teso-card-row-header__card-number {
  margin-left: 20px;
}

.table-opwaarderen-btn {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  font-size: 14px;
}

.myteso-row-opwaardeer-button {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.4;
  height: 30px;
  text-align: center;
  padding: 0 9px;
}

.pas-menu {
  clear: both;
  padding-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-top: #D3D3D3 solid 1px;
}
@media all and (min-width: 560px) {
  .pas-menu {
    margin-left: 50px;
    margin-right: 50px;
  }
}

.pas-menu-header {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .pas-menu-header {
    font-size: 24px;
  }
}

.table-row-content {
  height: 40px;
  vertical-align: bottom;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.pas-menu-item {
  margin-top: 9px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.table-row-product {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.table-row-product:after {
  content: " ";
  display: table;
  clear: both;
}
@media all and (min-width: 560px) {
  .table-row-product {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.table-row-product .ticket_name {
  float: left;
  width: 50%;
}
@media all and (max-width: 559px) {
  .table-row-product .ticket_name {
    width: 70%;
  }
}
.table-row-product .table-row-retours {
  float: left;
  width: 10%;
}
@media all and (max-width: 559px) {
  .table-row-product .table-row-retours {
    width: 30%;
    text-align: right;
  }
}
.table-row-product .table-row-opwaarderen {
  float: left;
  width: 40%;
  text-align: right;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
@media all and (max-width: 559px) {
  .table-row-product .table-row-opwaarderen {
    display: block;
    float: none;
    clear: left;
    width: 100%;
    text-align: left;
  }
}

.table-row-headers {
  border-bottom: #D3D3D3 solid 1px;
}

.wide-card-product-header {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  margin-bottom: 15px;
  border-bottom: #D3D3D3 solid 1px;
  font-size: 0;
}
@media all and (min-width: 560px) {
  .wide-card-product-header {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media all and (min-width: 900px) {
  .wide-card-product-header {
    margin-left: 50px;
    margin-right: 50px;
  }
}
.wide-card-product-header .col-head {
  width: 30%;
  text-align: left;
  display: inline-block;
  padding-bottom: 10px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .wide-card-product-header .col-head {
    font-size: 24px;
  }
}
.wide-card-product-header .col-head.col-name {
  width: 50%;
}
.wide-card-product-header .col-head.col-retours {
  width: 20%;
}
@media all and (max-width: 559px) {
  .wide-card-product-header .col-head.col-name {
    width: 70%;
  }
  .wide-card-product-header .col-head.col-retours {
    width: 30%;
    text-align: right;
  }
}

.card-product-content {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding-bottom: 20px;
}

.card-row-container {
  background-color: transparent;
  height: auto;
  position: relative;
}
.card-row-container:after {
  content: "";
  position: absolute;
  height: 20%;
  background-color: white;
  top: 100%;
  right: 0;
  width: 100%;
}
.teso-card-row-row--open .card-row-container:after {
  height: 20px;
}

.whiteblock-row {
  background: white;
  position: relative;
  min-height: 132px;
}
@media only screen and (min-width: 320px) {
  .whiteblock-row {
    width: calc(0% - 20px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .whiteblock-row {
    width: calc(8.3333333333% - 21.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .whiteblock-row {
    width: calc(12.5% - 22.5px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .whiteblock-row {
    width: calc(12.5% - 22.5px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .whiteblock-row {
    width: 20px;
    height: 100%;
    margin: 0;
    min-height: 152px;
  }
  .whiteblock-row::after, .whiteblock-row::before {
    display: none !important;
  }
}
.whiteblock-row::after, .whiteblock-row::before {
  background-color: white;
  content: "";
  display: none;
  height: 100%;
  width: 20px;
  position: absolute;
  top: 0;
}
.whiteblock-row::after {
  display: block;
  right: -20px;
}
.whiteblock-row::before {
  display: block;
  left: -20px;
}
@media only screen and (min-width: 900px) {
  .whiteblock-row {
    height: 132px;
  }
}

.card-row-uitklapper {
  width: 100%;
  border: 1px #D3D3D3 solid;
  background: #f7f7f7;
  border-radius: 0;
  position: relative;
  z-index: 10;
  height: 0px;
  overflow: hidden;
}

.teso-cards-row-list {
  list-style: none;
  padding: 0;
  font-size: 0;
  height: auto;
}
.teso-cards-row-list + .white-row {
  height: 100px;
  background: white;
}
@media only screen and (min-width: 320px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-cards-row-list + .white-row {
    margin-top: 0;
  }
}
@media only screen and (min-width: 900px) and (min-width: 320px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 560px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 900px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) and (min-width: 1120px) {
  .teso-cards-row-list + .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .teso-cards-row-list + .white-row {
    width: 100%;
    margin: 0;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .teso-cards-row-list + .white-row {
    width: 100%;
    margin: 0;
  }
}

.right-block {
  position: absolute;
  right: 40px;
  top: 25px;
  display: block;
  width: auto;
}
@media all and (max-width: 559px) {
  .right-block {
    position: relative;
    top: auto;
    right: auto;
  }
  .right-block p {
    text-align: left;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.uitklapper-icon {
  width: 20px;
  height: 20px;
  float: right;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}
@media all and (min-width: 560px) {
  .uitklapper-icon {
    right: 40px;
  }
}

.vinkje-txt-sub {
  margin-left: 25px;
  display: inline-block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: #8C8C8C;
}

.personalinfo {
  padding-top: 40px;
  white-space: nowrap;
  float: bottom;
  text-align: right;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding-left: 20px !important;
  color: white;
}

.sub-content-button-btn {
  height: 30px;
  width: 90px;
}

.icon-sub {
  margin: 0;
}

.btn-sub {
  font-size: 12px;
}

.qr-row {
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 15px;
  width: 80px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
}
.qr-row .qr_inner {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  z-index: 5;
}
.qr-row.wide_qr {
  width: 0px;
  margin-right: 5px;
  margin-left: 15px;
}
.qr-row svg {
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 500ms, -webkit-transform 500ms;
  transition: transform 500ms, left 500ms;
  transition: transform 500ms, left 500ms, -webkit-transform 500ms;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.qr-row.zoomed {
  z-index: 10;
}
.qr-row.zoomed svg {
  -webkit-transform: scale(3) translateX(0%);
          transform: scale(3) translateX(0%);
  left: 0%;
  max-height: 500px;
  max-width: 500px;
}
.qr-row.zoomed:before {
  display: block;
  content: "";
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #222;
  position: fixed;
  opacity: 0.4;
  z-index: 3;
}

.card_removal-confirm .white-container {
  background: #fff;
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .card_removal-confirm .white-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .card_removal-confirm .white-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container {
    padding-left: calc(0% - 20px + 20px);
    padding-right: calc(0% - 20px + 20px);
  }
}
@media only screen and (min-width: 560px) {
  .card_removal-confirm .white-container {
    padding-left: calc(8.3333333333% - 21.6666666667px + 20px);
    padding-right: calc(8.3333333333% - 21.6666666667px + 20px);
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container {
    padding-left: calc(12.5% - 22.5px + 20px);
    padding-right: calc(12.5% - 22.5px + 20px);
  }
}
@media only screen and (min-width: 1120px) {
  .card_removal-confirm .white-container {
    padding-left: calc(12.5% - 22.5px + 20px);
    padding-right: calc(12.5% - 22.5px + 20px);
  }
}
.card_removal-confirm .white-container .rich-text {
  max-width: 600px;
  padding: 20px 0px 20px 0px;
  margin-bottom: -36px;
}
.card_removal-confirm .white-container .rich-text h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .card_removal-confirm .white-container .rich-text h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.card_removal-confirm .white-container .rich-text h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .card_removal-confirm .white-container .rich-text h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container .rich-text h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container .rich-text h2 {
    margin-bottom: 20px;
  }
}
.card_removal-confirm .white-container .rich-text h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .card_removal-confirm .white-container .rich-text h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container .rich-text h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container .rich-text h3 {
    margin-bottom: 20px;
  }
}
.card_removal-confirm .white-container .rich-text h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .card_removal-confirm .white-container .rich-text h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container .rich-text h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container .rich-text h4 {
    margin-bottom: 20px;
  }
}
.card_removal-confirm .white-container .rich-text p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .card_removal-confirm .white-container .rich-text p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .card_removal-confirm .white-container .rich-text p {
    margin-bottom: 20px;
  }
}
.card_removal-confirm .white-container .rich-text p + h2, .card_removal-confirm .white-container .rich-text p + h3, .card_removal-confirm .white-container .rich-text p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .card_removal-confirm .white-container .rich-text p + h2, .card_removal-confirm .white-container .rich-text p + h3, .card_removal-confirm .white-container .rich-text p + h4 {
    padding-top: 20px;
  }
}
.card_removal-confirm .white-container .rich-text ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.card_removal-confirm .white-container .rich-text ul li {
  padding: 0.5em 1em;
}
.card_removal-confirm .white-container .rich-text a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .card_removal-confirm .white-container .rich-text a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.card_removal-confirm .white-container .rich-text a:hover {
  border-bottom: 1px solid transparent;
}
.card_removal-confirm .white-container .confirm-buttons {
  max-width: 600px;
  padding: 20px 0px 20px 0px;
}
.card_removal-confirm .white-container .confirm-buttons .link--right {
  float: right;
}

.wide-card-vspacer {
  background: #fff;
  height: 10px;
}
@media only screen and (min-width: 320px) {
  .wide-card-vspacer {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .wide-card-vspacer {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .wide-card-vspacer {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .wide-card-vspacer {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .wide-card-vspacer {
    width: 100%;
    margin: 0;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .wide-card-vspacer {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (min-width: 320px) {
  .teso-card-row-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .teso-card-row-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-card-row-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .teso-card-row-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .teso-card-row-row {
    width: 100%;
    margin: 0;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .teso-card-row-row {
    width: 100%;
    margin: 0;
  }
}
.teso-card-row-row.teso-card-row-row--open .card-row-container {
  position: relative;
}
@media only screen and (min-width: 900px) {
  .teso-card-row-row.teso-card-row-row--open .card-row-container {
    min-height: 440px;
  }
}
.teso-card-row-row.teso-card-row-row--open .card-row-container .card-row-uitklapper {
  height: auto;
  padding-bottom: 20px;
}
.teso-card-row-row.teso-card-row-row--open .uitklapper-icon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
@media only screen and (min-width: 560px) {
  .teso-card-row-row.teso-card-row-row--open .whiteblock-row {
    min-height: 510px;
    height: 100%;
  }
}
.teso-card-row-row.teso-card-row-row--open .card-row-uitklapper {
  display: block;
  min-height: 380px;
}

.my-teso__top_button_bar {
  min-height: 130px;
  height: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.buttons {
  text-align: right;
  display: inline-block;
}
@media only screen and (min-width: 320px) {
  .buttons {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .buttons {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .buttons {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .buttons {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .buttons {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .buttons {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .buttons {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .buttons {
    margin-left: calc(12.5% - 22.5px + 40px);
  }
}
.buttons .button__text--display {
  display: inline-block;
}

.btn-cards {
  position: relative;
  margin-left: 20px;
  margin-bottom: 20px;
}

.white-row {
  background: white;
  height: 120px;
}
@media only screen and (min-width: 320px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .white-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.card-row-content {
  min-height: 110px;
  position: relative;
  z-index: 12;
}

.teso-card-row {
  text-align: left;
  margin: 0;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  margin-bottom: 10px;
}
@media only screen and (min-width: 320px) {
  .teso-card-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .teso-card-row {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-card-row {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .teso-card-row {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .teso-card-row {
    margin-left: 0;
    margin-right: 0;
  }
}

.teso-card-row:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  height: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 0 0 8px white;
  width: 100%;
  z-index: 0;
}

.connect-licenses {
  clear: both;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.connect-licenses a {
  margin-left: 0 !important;
  line-height: 0;
  display: block;
  color: #f7f7f7;
}
.connect-licenses a input[type=checkbox] + label:before {
  margin-bottom: 0 !important;
}
.connect-licenses a input[type=checkbox]:checked + label:before, .connect-licenses a input[type=checkbox].is-checked + label:before {
  background-color: #ffd700;
}

.white {
  height: auto;
  background: #fff;
  padding: 20px;
}
.teso-single-cards-list {
  list-style: none;
  padding: 0;
  font-size: 0;
  background: white;
  height: 400px;
  display: inline-block;
}
@media only screen and (min-width: 320px) {
  .teso-single-cards-list {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .teso-single-cards-list {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-single-cards-list {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .teso-single-cards-list {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.teso-single-card {
  text-align: left;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  border-radius: 6px;
  border: 1px solid #D3D3D3;
  background-color: white;
  display: inline-block;
}
@media only screen and (min-width: 320px) {
  .teso-single-card {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .teso-single-card {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .teso-single-card {
    width: calc(29.1666666667% - 25.8333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .teso-single-card {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}

.single-card-content {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.single-card-header {
  display: inline-block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .single-card-header {
    font-size: 24px;
  }
}

.qr-los {
  display: inline-block;
  width: 20px;
  margin: 0;
  height: 30px;
  background: red;
}

.teso-loose-tickets .white {
  height: auto;
}
.white-content-block {
  margin-top: 50px;
  border-radius: 6px;
  border: 1px #D3D3D3 solid;
  height: 400px;
  overflow: hidden;
}
.white-content-block__freeheight {
  min-height: 400px;
  height: auto;
  margin-bottom: 50px;
}
@media only screen and (min-width: 320px) {
  .white-content-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .white-content-block {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .white-content-block {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .white-content-block {
    width: calc(41.6666666667% - 28.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .white-content-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .white-content-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .white-content-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .white-content-block {
    margin-left: calc(5.5555% - 21.1111px + 40px);
  }
}
@media all and (max-width: 559px) {
  .white-content-block {
    width: 100%;
    margin-left: 0;
    height: auto;
    padding-bottom: 20px;
  }
}

.white-content-block-row {
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 6px;
  border: 1px #D3D3D3 solid;
  height: auto;
}
@media only screen and (min-width: 320px) {
  .white-content-block-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .white-content-block-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .white-content-block-row {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .white-content-block-row {
    width: calc(88.8888333333% - 37.7777666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .white-content-block-row {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .white-content-block-row {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .white-content-block-row {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .white-content-block-row {
    margin-left: calc(5.5555% - 21.1111px + 40px);
  }
}
@media all and (max-width: 559px) {
  .white-content-block-row {
    width: 100%;
    margin-left: 0;
  }
}

.white-content-block-header {
  margin: 15px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .white-content-block-header {
    font-size: 30px;
  }
}

.kenteken-row {
  margin: 5px 15px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  display: block;
}

.kenteken {
  color: #909090;
  display: inline-block;
  text-transform: uppercase;
}

.settings-row {
  margin: 5px 15px;
}

.teso-checkbox {
  display: inline-block;
}

.teso-checkbox--disbaled {
  opacity: 0.3;
  pointer-events: none;
}

.vinkje {
  position: relative;
  z-index: -999;
}

.vinkje-txt {
  margin-left: 25px;
  display: inline-block;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.kenteken-remove {
  float: right;
  color: black;
  display: inline-block;
}

.item-row {
  margin: 5px 15px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  display: block;
}

.item-name {
  color: #909090;
  display: inline-block;
  width: 40%;
}

.item-info {
  color: black;
  display: inline-block;
  width: 55%;
}

.btn-p {
  margin: 15px;
}

.white-p {
  background: white;
  height: auto;
}
@media only screen and (min-width: 320px) {
  .white-p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .white-p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .white-p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .white-p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .white-p {
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .white-p {
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.teso-loose-tickets .white-p {
  padding: 30px 20px;
}
@media all and (min-width: 560px) {
  .teso-loose-tickets .white-p {
    padding: 38px 39px;
  }
}

.teso-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
}

.teso-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.teso-vinkje {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #D3D3D3;
}

.teso-checkbox:hover input ~ .teso-vinkje {
  background-color: #D3D3D3;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.teso-checkbox input:checked ~ .teso-vinkje {
  background-color: #ffd700;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.teso-vinkje:after {
  content: "";
  position: absolute;
  display: none;
}

.teso-checkbox input:checked ~ .teso-vinkje:after {
  display: block;
}

.teso-checkbox .teso-vinkje:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 11px;
  border: solid black;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.popular-times-button {
  padding: 0;
  margin-right: 10px;
}
@media all and (max-width: 900px) {
  .popular-times-button {
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

.date_schedule_explained {
  width: 100%;
}

.pagination {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding: 25px;
  text-align: center;
}
.pagination .prev,
.pagination .page,
.pagination .next {
  padding: 5px;
}
.pagination a.page:hover,
.pagination .prev:hover,
.pagination .next:hover {
  font-weight: 600;
  text-decoration: underline;
}
.pagination .current {
  font-weight: 600;
}

ul.message-area {
  margin-right: auto;
  padding: 0;
  margin-bottom: 20px;
}
ul.message-area:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  ul.message-area {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  ul.message-area {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  ul.message-area {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  ul.message-area {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
ul.message-area li {
  background: rgba(255, 215, 0, 0.5);
  padding: 10px 5px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: #222;
}
ul.message-area li.error {
  color: #f00;
  background: #fff;
  font-weight: bold;
}
@media only screen and (min-width: 320px) {
  ul.message-area.container {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  ul.message-area.container {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  ul.message-area.container {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  ul.message-area.container {
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  ul.message-area.container {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 560px) {
  ul.message-area.container {
    padding-left: calc(0% - 20px);
    padding-right: calc(0% - 20px);
  }
}
@media only screen and (min-width: 900px) {
  ul.message-area.container {
    padding-left: calc(0% - 20px);
    padding-right: calc(0% - 20px);
  }
}
@media only screen and (min-width: 1120px) {
  ul.message-area.container {
    padding-left: calc(0% - 20px);
    padding-right: calc(0% - 20px);
  }
}
@media only screen and (min-width: 320px) {
  ul.message-area.container {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  ul.message-area.container {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  ul.message-area.container {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  ul.message-area.container {
    margin-left: calc(8.3333333333% - 21.6666666667px + 40px);
  }
}

.white-p ul.message-area {
  margin-top: 20px;
  margin-bottom: 0;
}

.informing-message {
  margin-top: -10px;
  background: rgba(255, 215, 0, 0.5);
  padding: 10px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: #222;
}
.informing-message strong {
  font-weight: bold;
}
.informing-message a {
  background: transparent !important;
}

.informing-message__extra-info-image-small {
  margin: 5px 10px;
}
.informing-message__extra-info-image-small img {
  width: 65px;
}

.informing-message--light {
  display: flex;
  background-color: #FFF9E0;
}
.informing-message--light h1 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 46px;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .informing-message--light h1 {
    font-size: 70px;
    letter-spacing: -0.5px;
  }
}
.informing-message--light h2 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .informing-message--light h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
@media only screen and (min-width: 320px) {
  .informing-message--light h2 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .informing-message--light h2 {
    margin-bottom: 20px;
  }
}
.informing-message--light h3 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .informing-message--light h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 320px) {
  .informing-message--light h3 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .informing-message--light h3 {
    margin-bottom: 20px;
  }
}
.informing-message--light h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .informing-message--light h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) {
  .informing-message--light h4 {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .informing-message--light h4 {
    margin-bottom: 20px;
  }
}
.informing-message--light p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media only screen and (min-width: 320px) {
  .informing-message--light p {
    margin-bottom: 14px;
  }
}
@media only screen and (min-width: 900px) {
  .informing-message--light p {
    margin-bottom: 20px;
  }
}
.informing-message--light p + h2, .informing-message--light p + h3, .informing-message--light p + h4 {
  padding-top: 14px;
}
@media all and (min-width: 1120px) {
  .informing-message--light p + h2, .informing-message--light p + h3, .informing-message--light p + h4 {
    padding-top: 20px;
  }
}
.informing-message--light ul {
  margin: 1em;
  font-weight: 500;
  list-style: disc;
  list-style: disc url("/static/icons/icon-bullet-black.svg");
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
.informing-message--light ul li {
  padding: 0.5em 1em;
}
.informing-message--light a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  color: #222;
  background: #fff;
  border-bottom-color: #222;
}
.content-page-transparent .informing-message--light a {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.informing-message--light a:hover {
  border-bottom: 1px solid transparent;
}
.informing-message--light p {
  margin-bottom: 0px;
}

.price-pair-block {
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 0px;
}
@media only screen and (min-width: 320px) {
  .price-pair-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .price-pair-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .price-pair-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .price-pair-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .price-pair-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .price-pair-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .price-pair-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .price-pair-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .price-pair-block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .price-pair-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .price-pair-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .price-pair-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .price-pair-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
.price-pair-block span {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 10px;
}
.price-pair-block span.align-left {
  display: inline-block;
  vertical-align: top;
  width: 60%;
  padding-right: 10px;
}
@media all and (min-width: 900px) {
  .price-pair-block span.align-left {
    width: 40%;
  }
}
.price-pair-block span.align-right {
  display: inline-block;
  vertical-align: top;
}

.bulk_ticket__item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bulk_ticket__edit-button-wrapper {
  flex-shrink: 0;
}

.bulk_ticket__ticket-visual {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  padding: 15px;
  margin: 25px 0;
  height: 100px;
}

.bulk_ticket__ticket-icon {
  height: 100%;
}

.bulk_ticket__ticket-svg {
  height: 100%;
  flex-shrink: 0;
  margin-right: 15px;
}

.image-text-block--image {
  position: relative;
  float: left;
}

.image-text-block--text {
  position: relative;
  float: left;
}
@media only screen and (max-width: 530px) {
  .image-text-block--text {
    width: 100%;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 530px) {
  .image-text-block--text {
    width: 100%;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 700px) {
  .image-text-block--text {
    width: 100%;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 850px) {
  .image-text-block--text {
    width: 100%;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 1180px) {
  .image-text-block--text {
    width: 65%;
    padding: 0px 0px 15px 30px;
  }
}
@media only screen and (min-width: 1440px) {
  .image-text-block--text {
    width: 65%;
    padding: 0px 0px 15px 30px;
  }
}

.image-text-block {
  padding-bottom: 50px;
  color: #222;
  background: #fff;
  border-bottom-color: #222;
  padding-top: 14px;
}
@media only screen and (min-width: 320px) {
  .image-text-block {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .image-text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .image-text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .image-text-block {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .image-text-block {
    margin-left: calc(0% - 20px + 40px);
  }
}
@media only screen and (min-width: 560px) {
  .image-text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 900px) {
  .image-text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-text-block {
    margin-left: calc(16.6666666667% - 23.3333333333px + 40px);
  }
}
@media only screen and (min-width: 320px) {
  .image-text-block {
    padding-left: calc(11.6666666667% - 22.3333333333px);
    padding-right: calc(11.6666666667% - 22.3333333333px);
  }
}
@media only screen and (min-width: 560px) {
  .image-text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 900px) {
  .image-text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
@media only screen and (min-width: 1120px) {
  .image-text-block {
    padding-left: calc(8.3333333333% - 21.6666666667px);
    padding-right: calc(8.3333333333% - 21.6666666667px);
  }
}
.content-page-transparent .image-text-block {
  color: #fff;
  background: transparent;
  border-bottom-color: #fff;
  padding-top: 0;
}
@media all and (min-width: 1120px) {
  .image-text-block {
    padding-top: 20px;
  }
}

.cro-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  width: 100%;
}

.usp-text {
  padding: 40px 25px 0 10px;
}

.usp-item-wrapper {
  display: flex;
  flex-direction: row;
}

.usp-checkmark {
  margin-top: 2px;
  margin-right: 2px;
}

.usp-bullet-title {
  font-weight: bold !important;
}

.usp-basket-container {
  grid-column-start: 0;
  padding-left: 20px;
  z-index: 20;
  height: 100%;
}

.ticket-container {
  grid-column-start: 2;
  grid-column-end: 7;
}

.usp-variant {
  background-color: #f9f9f9;
  margin-right: -40px;
  height: 100%;
  padding: 15px;
}

.usp-item {
  padding-top: 15px;
}

.usp-title {
  font-weight: bold;
}

.basket-contents {
  padding: 10px;
}

.basket-list {
  list-style: none;
}

.basket-item {
  padding-top: 4px;
  padding-left: 18px;
}

.check-mark {
  margin-right: 5px;
}

@media only screen and (min-width: 1080px) {
  .usp-text {
    padding-top: 20px;
  }

  .usp-variant__usp-mobile-only {
    display: none;
  }
}
@media only screen and (max-width: 1079px) {
  .cro-container {
    display: block;
  }

  .usp-container {
    display: block;
  }

  .usp-variant {
    width: calc(100% - 20px);
  }

  .usp-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: calc(16.66667% - 23.33333px + 20px);
  }

  .usp-variant__usp-desktop-only {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .usp-text {
    padding-left: calc(12.5% - 22.5px + 20px);
  }
}
@media only screen and (max-width: 559px) {
  .usp-text {
    padding-left: 10px;
  }
}
.is-visible {
  display: block;
}

.is-hidden {
  display: none;
}

.filter-wrapper {
  width: 100%;
  background-color: gold;
}

.filterbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.filterbar-item {
  flex-basis: 24%;
}
@media all and (max-width: 559px) {
  .filterbar-item {
    flex-basis: 100%;
  }
  .filterbar-item:nth-child(n+2) {
    padding-top: 35px;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .filterbar-item {
    flex-basis: 50%;
  }
  .filterbar-item:nth-child(n+3) {
    padding-top: 35px;
  }
}

.filterbar-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filterbar-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}
@media all and (max-width: 559px) {
  .filterbar-bottom {
    padding-top: 35px;
  }
}
@media all and (min-width: 560px) and (max-width: 899px) {
  .filterbar-bottom {
    padding-top: 35px;
  }
}

.filter-checkbox input[type=checkbox] + label {
  text-transform: none;
}
.filter-checkbox input[type=checkbox] {
  height: 0;
  margin-left: -150vw;
  width: 0;
}
.filter-checkbox input[type=checkbox] + label {
  display: block;
  font-weight: normal;
  padding-left: 0px;
  width: 100%;
  cursor: pointer;
  line-height: 26px;
  color: #8c8c8c;
}
.filter-checkbox input[type=checkbox] + label::before {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 1px solid #222;
  border-radius: 3px;
  color: transparent;
  content: "";
  display: inline-block;
  float: left;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-right: 12px;
  margin-bottom: 2em;
  text-align: center;
  transition: border-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  position: relative;
  top: 3px;
}
.filter-checkbox input[type=checkbox] + label a {
  text-decoration: underline;
}
.filter-checkbox input[type=checkbox] + label a:hover, .filter-checkbox input[type=checkbox] + label a:focus {
  text-decoration: none;
}
.filter-checkbox input[type=checkbox] + label + input + label {
  margin-top: 10px;
}
.filter-checkbox input[type=checkbox]:checked + label::before, .filter-checkbox input[type=checkbox].is-checked + label::before {
  background-image: url("/static/icons/teso-icon-checkmark.svg");
  color: currentColor;
  line-height: 13px;
}
.filter-checkbox input[type=checkbox] {
  display: none;
}
.filter-checkbox input[type=checkbox] + label::before {
  padding: 0;
  margin: 0;
}

.filter-label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  padding-bottom: 5px;
}

.filter-main-label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  font-weight: 500;
  margin-left: 10px;
}
@media all and (min-width: 1120px) {
  .filter-main-label {
    font-size: 30px;
  }
}

.filter-button {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
}

.date-select-button {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: lighter;
}
.date-select-button::-webkit-input-placeholder {
  opacity: 1;
}
.date-select-button::-moz-placeholder {
  opacity: 1;
}
.date-select-button::-ms-input-placeholder {
  opacity: 1;
}
.date-select-button::placeholder {
  opacity: 1;
}

.filterbar--button-confirm {
  border: 1px solid black;
  border-radius: 5px;
}

.filterbar--button-download {
  border: 1px solid black;
  border-radius: 5px;
  float: right;
  margin: 10px;
}

.filter__checkbox-label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  font-weight: normal;
  padding-left: 5px;
  color: black;
}

.filter__checkbox-image {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}

.filter-search {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: lighter;
}

.filter--button-datepicker {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: black;
}

.toggle__field-checkbox {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.toggle-passes {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.filterbar-button-toggle {
  background-color: gold;
  border-radius: 5px;
  margin: 10px;
}

.date-picker-label {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

.trips__table--no_results {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: black;
  padding: 20px 0px;
}

.home-page {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: grey !important;
  /* This needs some refactoring */
}
.home-page .video-top-spacer {
  display: none;
}
@media all and (max-width: 559px) {
  .home-page.show-one-card .page-header {
    height: auto;
  }
  .home-page.show-one-card .page-header__container {
    margin-top: 30px;
  }
}
.home-page .page-header {
  display: flex;
  align-items: center;
  height: calc(90vh - 260px);
  margin-bottom: 0px;
}
@media all and (min-width: 560px) {
  .home-page .page-header {
    height: calc(100vh - 378px);
  }
}
@media all and (min-width: 1120px) {
  .home-page .page-header {
    height: calc(100vh - 378px);
  }
}
.home-page .page-header__logo {
  width: 14em;
  margin-bottom: 20px;
}
@media all and (min-width: 560px) {
  .home-page .page-header__logo {
    margin-bottom: 15px;
  }
}
@media all and (min-width: 1120px) {
  .home-page .page-header .page-header__logo {
    width: 16em;
  }
}
.home-page.no-scroll {
  overflow: hidden;
}
.home-page .button {
  margin-right: 1em;
}
.home-page .button--transparent {
  margin-bottom: 10px;
}
.home-page .button--primary {
  margin-top: 0;
  vertical-align: top;
}
@media all and (max-width: 559px) {
  .home-page .page-navigation .button--primary {
    margin-top: 0;
  }
}
@media all and (max-width: 559px) {
  .home-page .home-cta-container {
    display: flex;
    flex-wrap: wrap;
  }
  .home-page .home-cta-container .button--primary {
    order: 1;
    margin-bottom: 10px;
  }
  .home-page .home-cta-container .button--transparent {
    order: 2;
  }
}
.home-page .navigation_logo {
  display: none;
}
.home-page .page-content {
  transition: -webkit-transform 1s ease 1s;
  transition: transform 1s ease 1s;
  transition: transform 1s ease 1s, -webkit-transform 1s ease 1s;
}
.home-page .page-content--video-finished {
  -webkit-transform: translateY(-270px);
          transform: translateY(-270px);
}

.cleared {
  clear: both;
}

.home-card-desktop-only {
  display: none;
}
@media all and (min-width: 560px) {
  .home-card-desktop-only {
    display: block;
  }
}

.teso-card__homepage {
  padding-top: 0px;
  max-width: 300px;
  display: none;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
@media all and (max-width: 559px) {
  .teso-card__homepage {
    displaY: block;
  }
}
.teso-card__homepage .qr_inner {
  width: 50%;
  height: 120px;
  position: relative;
  z-index: 5;
}
.teso-card__homepage .qr_inner svg {
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 500ms, -webkit-transform 500ms;
  transition: transform 500ms, left 500ms;
  transition: transform 500ms, left 500ms, -webkit-transform 500ms;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.teso-card__homepage.zoomed {
  z-index: 10;
}
.teso-card__homepage.zoomed svg {
  -webkit-transform: scale(2) translateX(0%);
          transform: scale(2) translateX(0%);
  left: 0%;
  max-height: 500px;
  max-width: 500px;
}
.teso-card__homepage.zoomed:before {
  display: block;
  content: "";
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #222;
  position: fixed;
  opacity: 0.4;
  z-index: 3;
}
.teso-card__homepage .card-head {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.teso-card__homepage .card_ticket {
  clear: both;
  margin-bottom: 10px;
  height: 21px;
  overflow: hidden;
}
.teso-card__homepage .card_ticket .credits {
  float: right;
  width: 16px;
  margin-left: 12px;
  text-align: right;
}
.home-block {
  color: #fff;
  margin-right: auto;
  padding: 0;
  padding-top: 80px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.home-block:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .home-block {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .home-block {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .home-block {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .home-block {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
@media all and (min-width: 560px) {
  .home-block {
    padding-top: 110px;
    padding-bottom: 20px;
  }
}
@media all and (min-width: 560px) and (max-height: 560px) {
  .home-block {
    padding-top: 50px;
  }
}
.home-block:first-child {
  padding-top: 0;
  padding-bottom: 20px;
}
@media all and (max-height: 560px) {
  .home-block:first-child {
    padding-top: 50px;
  }
}
.home-block_intro {
  margin-bottom: 40px;
}
@media only screen and (min-width: 320px) {
  .home-block_intro {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-block_intro {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-block_intro {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-block_intro {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
.home-block_intro .home-block_heading-h2 {
  margin-bottom: 15px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .home-block_intro .home-block_heading-h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
.home-block_intro .home-block_heading-h4 {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  line-height: 1.2;
}
@media all and (min-width: 1120px) {
  .home-block_intro .home-block_heading-h4 {
    font-size: 24px;
  }
}
.home-block_intro .home-block_paragraph {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.home-block .home-swiper-container {
  clear: left;
}
.home-block_button {
  margin-bottom: 60px;
}
@media only screen and (min-width: 320px) {
  .home-block_button {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-block_button {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-block_button {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-block_button {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.home-block_video {
  margin-right: auto;
  padding: 0;
}
.home-block_video:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .home-block_video {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .home-block_video {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .home-block_video {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .home-block_video {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
@media only screen and (min-width: 320px) {
  .home-block_video-item {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-block_video-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-block_video-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-block_video-item {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (max-width: 559px) {
  .home-block_video-item .video-block {
    margin-top: 60px;
  }
}
.home-block_video .home-block_heading-h2 {
  margin-bottom: 20px;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 26px;
  letter-spacing: -0.3px;
  font-weight: 500;
}
@media all and (min-width: 1120px) {
  .home-block_video .home-block_heading-h2 {
    font-size: 40px;
    letter-spacing: -0.5px;
  }
}
.home-block_video .home-block_paragraph {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}
.home-block .link {
  font-weight: bold;
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 320px) {
  .home-block .link {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-block .link {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-block .link {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-block .link {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
.home-block .link__text {
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.home-block .link__text:hover, .home-block .link__text:focus {
  border-bottom: 1px solid #fff;
}
.home-block .video-block {
  width: 100%;
  margin-left: 0;
}
.ui-inactive .home-block {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.departure_time_container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
@media only screen and (min-width: 320px) {
  .departure_time_container .date_info,
.departure_time_container .departure_times {
    width: calc(91.6666666667% - 38.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .departure_time_container .date_info,
.departure_time_container .departure_times {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .departure_time_container .date_info,
.departure_time_container .departure_times {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .departure_time_container .date_info,
.departure_time_container .departure_times {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
.departure_time_container .scroll-element {
  display: none;
  transition: opacity 0.5s ease;
}
@media only screen and (min-width: 320px) {
  .departure_time_container .scroll-element {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .departure_time_container .scroll-element {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .departure_time_container .scroll-element {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .departure_time_container .scroll-element {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (min-width: 900px) {
  .departure_time_container .scroll-element {
    display: block;
    text-align: center;
    opacity: 0.8;
  }
  .departure_time_container .scroll-element:hover {
    opacity: 1;
  }
}
.departure_time_container .scroll-element--clicked {
  opacity: 0;
  pointer-events: none;
}
.departure_time_container .date_info > p {
  font-family: "Foundry Monoline", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (min-width: 560px) {
  .departure_time_container .departure_times {
    text-align: right;
  }
}
.departure_time_container .departure_times a {
  margin-top: 34px;
}
.ui-inactive .departure_time_container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fullscreen_background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  width: 100%;
  height: 100%;
  display: none;
}
.playing .fullscreen_background {
  display: block;
}
.is_mobile .fullscreen_background {
  display: none !important;
}
.fullscreen_background #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100vh;
  max-width: 177.777778vh;
  /* 16/9 * 100 = 177.7777778 */
}

.home-video-button-constrainer {
  margin-right: auto;
  padding: 0;
  transition: opacity 1s ease;
}
.home-video-button-constrainer:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .home-video-button-constrainer {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .home-video-button-constrainer {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .home-video-button-constrainer {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .home-video-button-constrainer {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}
.home-video-button-constrainer--hide {
  opacity: 0;
}
.home-video-button-constrainer .home-video.button-container {
  text-align: right;
}
@media only screen and (min-width: 320px) {
  .home-video-button-constrainer .home-video.button-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 560px) {
  .home-video-button-constrainer .home-video.button-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .home-video-button-constrainer .home-video.button-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1120px) {
  .home-video-button-constrainer .home-video.button-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}
@media all and (min-width: 560px) {
  .home-video-button-constrainer .home-video.button-container {
    margin-top: 180px;
  }
}
.home-video-button-constrainer .home-video.button-container .video_button,
.home-video-button-constrainer .home-video.button-container .audio_button {
  margin-bottom: 0px;
  min-width: 180px;
  padding-left: 13px;
  margin-right: 0;
  text-align: left;
  display: none;
  background: #fff;
}
.is_mobile .home-video-button-constrainer .home-video.button-container .video_button,
.is_mobile .home-video-button-constrainer .home-video.button-container .audio_button {
  display: none;
}
@media all and (min-width: 560px) {
  .home-video-button-constrainer .home-video.button-container .video_button,
.home-video-button-constrainer .home-video.button-container .audio_button {
    display: inline-block;
  }
}
.home-video-button-constrainer .home-video.button-container .video_button svg,
.home-video-button-constrainer .home-video.button-container .audio_button svg {
  height: 36px;
  width: 36px;
  fill: #222;
  position: relative;
  top: 5px;
}
@media all and (min-width: 1120px) {
  .home-video-button-constrainer .home-video.button-container .video_button svg,
.home-video-button-constrainer .home-video.button-container .audio_button svg {
    top: 1px;
  }
}
.home-video-button-constrainer .home-video.button-container .video_button svg path,
.home-video-button-constrainer .home-video.button-container .audio_button svg path {
  fill: #222;
}
.home-video-button-constrainer .home-video.button-container .video_button .button__text--display,
.home-video-button-constrainer .home-video.button-container .audio_button .button__text--display {
  vertical-align: top;
  color: #222;
}
.home-video-button-constrainer .home-video.button-container .video_button .button__icon--before,
.home-video-button-constrainer .home-video.button-container .audio_button .button__icon--before {
  margin-right: 0;
}
.home-video-button-constrainer .home-video.button-container .video_button {
  margin-right: 20px;
}

.video_button svg:nth-of-type(1) {
  display: inline-block;
}
.playing .video_button svg:nth-of-type(1) {
  display: none;
}
.video_button .text-play {
  display: inline-block;
}
.video_button .text-pause {
  display: none;
}
.playing .video_button .text-play {
  display: none;
}
.playing .video_button .text-pause {
  display: inline-block;
}
.video_button svg:nth-of-type(2) {
  display: none;
}
.playing .video_button svg:nth-of-type(2) {
  display: inline-block;
}

.audio_button {
  margin-right: 0;
}
.audio_button svg:nth-of-type(1) {
  display: inline-block;
}
.muted .audio_button svg:nth-of-type(1) {
  display: none;
}
.audio_button svg:nth-of-type(2) {
  display: none;
}
.muted .audio_button svg:nth-of-type(2) {
  display: inline-block;
}
.audio_button .text-mute {
  display: inline-block;
}
.audio_button .text-unmute {
  display: none;
}
.muted .audio_button .text-mute {
  display: none;
}
.muted .audio_button .text-unmute {
  display: inline-block;
}
.touch .audio_button {
  display: none !important;
}

.content-page .page-header {
  min-height: 190px;
}

.content-stream {
  margin-right: auto;
  padding: 0;
}
.content-stream:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (min-width: 320px) {
  .content-stream {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 560px) {
  .content-stream {
    float: none;
    max-width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .content-stream {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1120px) {
  .content-stream {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;
    width: 100%;
  }
}