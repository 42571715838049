.link-block {
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  @include column_padding(.7,1,1,1);
  @include content-theme(false);

  padding-top: $mobile-half-margin;
  padding-bottom: $mobile-half-margin;

  @include desktop {
    padding-top: $desktop-half-margin;
    padding-bottom: $desktop-half-margin;
  }

  & + .link-block {
    padding-top: 0;
    margin-top: -$mobile-half-margin;
  }

}

.link {
  font-weight: bold;
  @include typography-list;

  &__icon {
    margin-right: .2em;

    &--white svg {
      fill: $color-white;
    }

    &--close {
      margin-right: 0;
      vertical-align: sub;
    }
  }
}

.home-block {
  .link {

    &__icon {
      svg {
        fill: $color-white;
      }
    }
    &__text {
      @include underline($color-white);
    }

    &:not(.news-swiper) {
      .link__icon {
        &--shape {
          fill: $color-white;
        }
      }
    }
  }
  .news-swiper {
    .link {
      margin-left: 0;
    }
  }
}

.content-page {
  .link__text {
    @include underline($color-black);
  }

  &-transparent {
    .link__icon--shape {
      fill: $color-white;
    }

    .link__text {
      @include underline($color-white);
    }
  }
}
