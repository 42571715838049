.social-block {
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);

  padding-top: 60px;
  padding-bottom: 60px; // TODO match this with vertical rythm spect

  .social-button {
    display: inline-block;
  }

  &_title {
    color: $color-white;
    margin-bottom: 20px;
    @include typography-h4();
  }

  &-container {
    margin-right: 20px;

    // TODO make a CLEAN mixin out of this

    .icon {
      transition: fill $cubic-250;

      &_shape,
      &_shape_alter {
        fill: $color-white;
      }

      &_border {
        stroke: #fff;
        fill: transparent;
      }

      &_background {
        fill: transparent;
      }
    }

    &:hover,
    &:focus {
      .icon {
        &_shape {
          fill: transparent;
        }

        &_shape_alter {
          fill: $color-white;
        }

        &_background {
          fill: $color-white;
        }
      }
    }
  }

  &__link {
    vertical-align: center;
    fill: $color-white;
  }
}
