.bulk_ticket__item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bulk_ticket__edit-button-wrapper {
  flex-shrink: 0;
}

.bulk_ticket__ticket-visual {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  padding: 15px;
  margin: 25px 0;
  height: 100px;
}

.bulk_ticket__ticket-icon {
  height: 100%;
}

.bulk_ticket__ticket-svg {
  height: 100%;
  flex-shrink: 0;
  margin-right: 15px;
}

