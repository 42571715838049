.text-icon-block {
  &_item {
    margin-bottom: 40px;
    color: $color-white;
    @include layout(5.5,6,3,3);
    @include typography-paragraph;

    font-weight: 400;
    line-height: 1.3;

    .button {
      float: left;
      clear: both;
      width: 155px;
      margin-right: 0;

      @include landscape() {
        float: right;
      }
    }

    @include desktop {
      margin-bottom: 0;
    }

    &.departure_times {
      @include mobile-portrait-only {
        clear: left;
      }
      @include mobile-only {
        margin-top: -10px;
      }
    }

  }

  &_text::first-line {
    font-weight: 500;
  }

  &_text {
    margin-right: 40px;
    @include portrait {
      margin-right: 30px;
    }
  }

  &_icon {
    &--container {
      height: 50px;
      width: 65px;
      float: left;
      margin-bottom: 5px;
      @include mobile-only {
        margin-bottom: 50px;
      }

      svg {
        height: 100%;
        width: 100%;
        path {
          fill: $color-yellow;
        }
      }

      @include mobile-portrait-only {
        &.hide {
          display: none;
        }
      }

    }
  }

  &_item.laptop-icon {
    .text-icon-block_icon--container {
      svg {
        position: relative;
        left: -10px;
        top: -4px;
      }
    }
  }

  &_item.car-icon {
    .text-icon-block_icon--container {
      svg {
        position: relative;
        left: -8px;
        bottom: -6px;
      }
    }
  }

  &_item.mobile-icon {
    .text-icon-block_icon--container {
      svg {
        width: 60%;
        position: relative;
        left: -7px;
      }
    }
  }

  &_item.card-icon {
    .text-icon-block_icon--container {
      svg {
        position: relative;
        left: -10px;
        bottom: -3px;
      }
    }
  }

}

.mobile-icon {
  @include mobile-only {
    .text-icon-block_icon--container {
      padding-left: 9px;
    }
  }
}

/* TODO this feels kinda hackey */
@include portrait {
  .text-icon-block_icon--container {
    height: 50px;
    float: none;
  }
}

@include landscape {
  .text-icon-block_icon--container {
    &.hide {
      display: block;
    }
  }
}

