.quote-block {

  position: relative;
  @include layout(5.2,9,9,9);
  @include offset(.8,1.5,1.5,1.5);

  margin-top: $mobile-margin;
  margin-bottom: $mobile-margin;

  @include desktop {
    margin-top: $desktop-margin;
    margin-bottom: $desktop-margin;
  }

  //.content-page-transparent & {
  //  margin-top: 0;
  //}

  color: $color-white;
  @include typography-quote;

  &__author {
    @include typography-undertitle;

    &::before,
    &::after {
      content: '—';
      margin: 0 5px;
    }
  }

  &__quote {
    &--marks {
      font-size: 3em;
      position: absolute;
      opacity: .5;

      &.marks--before {
        margin-top: -30px;
        margin-left: -55px;
      }

      &.marks--after {
        margin-top: -20px;
        margin-left: 10px;
      }
    }
  }
}
