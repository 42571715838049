.image-text-block--image {
  position: relative;
  float: left;
}

.image-text-block--text {
  position: relative;
  float: left;

  @media only screen and (max-width: 530px) {
    width: 100%;
    padding-top: 30px;
  }

  @media only screen and (min-width: 530px) {
    width: 100%;
    padding-top: 30px;
  }

  @media only screen and (min-width: 700px) {
    width: 100%;
    padding-top: 30px;
  }

  @media only screen and (min-width: 850px) {
    width: 100%;
    padding-top: 30px;
  }

  @media only screen and (min-width: 1180px) {
    width: 65%;
    padding: 0px 0px 15px 30px;
  }

  @media only screen and (min-width: 1440px) {
    width: 65%;
    padding: 0px 0px 15px 30px;
  }
}

.image-text-block {

  padding-bottom: 50px;
  @include layout(6, 8, 8, 8);
  @include offset(0, 2, 2, 2);
  @include column_padding(.7, 1, 1, 1);
  @include content-theme(false);
  padding-top: $mobile-half-margin;

  @include desktop {
    padding-top: $desktop-half-margin;
  }
}
