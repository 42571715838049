
.my-teso-page.teso-loose-tickets {

  .buttons {
    text-align: right;
    @include layout(6, 10, 12, 11);
    @include offset(0, 1, 0, 0.5);
  }

  .white-p {
    padding-top: 0;
  }
}

.loose-card {
  @include typography-paragraph;
  @include landscape {
    width: 31%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0;
    margin-bottom: 20px;
  }
  width: 100%;
  border: 1px solid $color-light-grey;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  padding: 10px;
  margin: 10px 0;

  .my-teso-card__qrcode {
    margin-left: -10px;
    margin-top: -20px;
    margin-right: -30px;
    float: left;
    width: 50%;
    &.zoomed {
      .qr_inner {
        svg {
          background: $color-white;
        }
      }
    }
    .qr_inner {
      width: 100%;
      padding-bottom: 100%;
      svg {
        background: transparent;
      }
      img {
        width: 70%;  // png solution
      }
      .zoom-icon {
        top: -10px;
        left: -16px;
        svg {
          path {
            fill: $color-black;
          }
        }
      }
    }
  }
  .loose-card__description {
    //margin-left: 50%;
    display: block;
    @include clearfix;
  }
  .loose-card__number ,
  .loose-card__date {
    color: $color-light-grey;
  }

}
