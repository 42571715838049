// typography mixins go here
// define typography including breakpoints

@mixin typography-document() {
  font-family: $font-stack;
}

@mixin typography-paragraph() {
  @include typography-document;

  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

@mixin typography-h1() {
  @include typography-document;

  font-size: 46px;
  letter-spacing: -.4px;
  font-weight: 500;

  @include desktop {
    font-size: 70px;
    letter-spacing: -.5px;
  }
}

@mixin typography-h2() {
  @include typography-document;

  font-size: 26px;
  letter-spacing: -.3px;
  font-weight: 500;

  @include desktop {
    font-size: 40px;
    letter-spacing: -.5px;
  }
}

@mixin typography-h3() {
  @include typography-document;

  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -.4px;
  font-weight: 500;

  @include desktop {
    font-size: 30px;
  }
}

@mixin typography-h3-overview() {
  @include typography-document;

  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -.4px;
  font-weight: 500;

  @include desktop {
    font-size: 24px;
  }
}

@mixin typography-h4() {
  @include typography-document;

  font-size: 20px;
  letter-spacing: -.4px;
  font-weight: 500;
  line-height: 1.20;

  @include desktop {
    font-size: 24px;
  }
}

@mixin typography-quote() {
  @include typography-document;

  font-size: 26px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.3;

  @include desktop {
    font-size: 36px;
    line-height: 1.4;
  }
}

@mixin typography-undertitle() {
  @include typography-document;

  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
}

@mixin typography-intro() {
  @include typography-document;

  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
}

@mixin typography-list() {
  @include typography-document;

  font-size: 15px;
  line-height: 1.5;
}

@mixin typography-button() {
  @include typography-document;

  font-size: 14px;
  font-weight: 500;
}
@mixin typography-button--small(){
  @include typography-document;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.4;
}
@mixin typography-link(){
      @include typography-document;
      font-size: 15px;
      line-height: 1.6;
      font-weight: 700;
  }
