.prd-btns {
  float: right;
  a {
    margin-bottom: 10px;
  }
}

.product {
  max-width: 50%;
}
.hidden_row {
  display: none !important;
}

h4.payment_heading {
  padding: 25px 0 15px;
  margin-bottom: 0;
}

.form__row.payment_methods {
  @include form__row;

  label{

    @include typography-intro;
    padding-left: 55px!important;
  }
    input[type='radio'] + label {

      background-repeat: no-repeat;
      background-position: 15px 50%;
      background-size: 32px 32px;
      width: 100%;

      &[data-method="ideal"] {
        background-image: url('/static/images/logos/iDeal-logo.png');
      }
      &[data-method="mastercard"] {
        background-image: url('/static/images/logos/Mastercard-logo.png');
      }
      &[data-method="visa"] {
        background-image: url('/static/images/logos/Visa-logo.png');
      }
      &[data-method="paypal"] {
        background-image: url('/static/images/logos/Paypal-logo.png');
      }
      &[data-method="bancontactmistercash"] {
        background-image: url('/static/images/logos/bancontact.png');
      }
      &[data-method="sofortberweisung"] {
        background-image: url('/static/images/logos/sofort.png');
      }
      &[data-method="americanexpress"] {
        background-image: url('/static/images/logos/american-express.png');
      }

    }
}

.exta-ticket-link {
  display: block;
  @include typography-paragraph;
  font-weight: bold;
  margin-bottom: 30px;
}

.ticket-row {
  @include clearfix;
  clear: both;
  margin-bottom: 10px;
  min-height: 30px;
}

.edit-contact {
  float: right;
}

.product-list {
  @include typography-paragraph;
}

.extra-ticket-link {
  clear: both;
  margin-bottom: 30px;
  display: block;
  @include typography-link;
}

h4 {
  @include typography-h4;
  margin-bottom: 10px;
}

.checkout-page {
  background-color: $color-black;
  //background-image: url("/static/images/webcampaal9blur.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .page-header {
    min-height: 30px;
    margin-bottom: 0;
  }

  p {
    @include typography-paragraph;
    line-height: 18px;
  }
}

.total_fee {
  text-align: right;
  @include typography-h3;
  margin-bottom: 40px;
  margin-top: 30px;
}

#id_license_plate {
  text-transform: uppercase;
}

.checkout-page {
  //car second screen
  .departure_day__container {
    &:not(.active) {
      display: none; // disable temporary
    }

    &.active {
      display: block;
    }
  }

  .extra-field-activator {
    @include typography-link;
    display: none;
    margin-top: 30px;
  }

  .toggle_second {
    margin-top: 20px;
  }

  .form-content:not(.all_fields_visible ) {

    .extra-field-activator {
      display: block;
      &.hidden {
        display: none;
      }
    }

    .toggle_second { // second row toggleable with JS

      &.form__row {
        display: none;

        &.active {
          display: block;
        }

      }

    }

  }
}

.tickets-no_plates_messages {
  margin: 0px 0px 15px 0px;
}
.ticketflow-policy-accept {
  margin: 10px 0px;
  a {
    text-decoration: underline;
  }
}
.form-create-teso-account {
  margin: 10px 0px 25px 0px;
  a {
    text-decoration: underline;
  }
}

label[for="id_create_account"] { // label
  padding: 0px 15px 0px 5px;
  .teso-vinkje {
    top: 3px;
  }
  .c-tooltip {
    position: absolute;
    right: -10px;
    top: 1px;
  }
}

span.uppercase {
  text-transform: uppercase;
}

.checkout-page--thankyou {

  .checkout-page__content-stream {
  }

  .payment-icon {
    display: inline-block;
    margin-right: 5px;
    background: $color-yellow;
    height: 1em;
    width: 32px;
    height: 32px;
    vertical-align: top;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
    &__paid {
      background-image: url('/static/icons/order_succes.svg');
    }
    &__pending {
      background-image: url('/static/icons/order_pending.svg');
    }

    & + p {
      vertical-align: baseline;
    }
  }

  .vinkje-txt {
    @include typography-paragraph;
    display: inline-block;
    margin-bottom: 20px;
    vertical-align: top;
    margin-left: 0;
  }

  .card_resume {
    @include typography-paragraph;

    padding-top: 20px;
    max-width: 300px;
    margin-bottom: 20px;

    .card-head {
      display: block;
      margin-bottom: 5px;
      font-weight: 700;
    }
    .card_ticket {
      clear: both;
      margin-bottom: 10px;
      height: 21px;
      overflow: hidden;
      .credits {
        float: right;
        width: 16px;
        margin-left: 12px;
        text-align: right;
      }
      .ticket_name {

      }

    }
  }


  .content-stream__block {

    &.order--confirmation {
      background: $color-white;
      @include layout(6, 12, 12, 12);
      @include column-padding-with-gutter(0.5, 2, 3, 3);
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.order--specs {
      background: $color-white;
      @include layout(6, 12, 12, 12);
      @include column-padding-with-gutter(0.5, 2, 3, 3);

      padding-bottom: 40px;

      .inner {
        background: #F2F2F2;
        // nasty to break out this block
        margin-left: -10%;
        margin-right: -10%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    &.more-info {
      background: $color-white;
      @include layout(6, 12, 12, 12);
      @include column-padding-with-gutter(0.5, 2, 3, 3);
      padding-bottom: 40px;
      @include style-richtext;

      a.button {
        @include arrow($color-black, $color-white);
        margin-left: 0;
      }

    }
  }

  h2 {
    @include typography-h2;
    margin-bottom: 30px;
  }

  .teso-checkbox {
    margin-bottom: 30px;
  }

}



