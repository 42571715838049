.trips {

  .mobile-wrapper {
    @include mobile-only {
      display: block;
    }

    display: none;
  }

  .widescreen-wrapper {
    @include mobile-only {
      display: none;
    }

    display: block;
  }

  .white {
    @include layout(6, 12, 12, 12);
    @include mobile-portrait-only {
      width: 100%;
      margin: 0;
    }
  }

  .table-wrapper {
    padding: 10px;
  }

  &__table {
    width: 100%;
  }

  .table-row-content {

    @include mobile-only {
      min-height: 20px;
      padding: 5px 5px 0px 0px;
    }

    height: auto;
    min-height: 32px;
    padding: 15px 10px 0px 0px;
  }

  .table-row-content td {
    text-align: center;
  }

  .table__cell--mobile-title {
    width: 100%;
    font-weight: bold;

    ~ .table__cell--mobile-title {
      margin-top: 25px;
    }
  }

  .table__cell--mobile {
    width: 50%;
  }

  .table__cell--date {
    width: 30%;
  }

  .table__cell--name {
    width: 25%;
  }

  .table__cell--product {
    width: 25%;
  }

  .table__cell--price {
    width: 20%;
  }

  .table-header {
    text-align: left;
    @include typography-h3;
  }

  .checkin-type {
    @include mobile-only {
      color: black;
    }

    color: grey;
  }

  .trips-info__wrapper {
    padding: 0 0 0 4px;
  }

  #trips-info__icon {
    vertical-align: middle;
  }

  #trips-info__tooltip {
    visibility: hidden;
    width: 210px;
    background-color: black;
    font-size: 15px;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    margin-top: 30px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .trips-info__wrapper:hover #trips-info__tooltip {
    visibility: visible;
  }
}
