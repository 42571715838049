
.video-top-spacer {
  height: 30px;
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);

  background: $color-white;
  .content-page-transparent & {
    background: transparent;
  }
}

.video-block {
  @include layout(6,9,9,9);
  @include offset(0,1.5,1.5,1.5);

  @include mobile-only {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }

  &__video {
    position: relative;
  }

  a { // 900 width, 500 height ratio
    display: block;
    padding-bottom: (9/16) *100%;

    .video-block__play {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background-color: $color-yellow;
      left: 50%;
      top: 50%;
      border: none;
      transform: translate3D(-50%, -50%, 0); // TODO, possible without 3d?

      @include desktop {
        width: 80px;
        height: 80px;
      }

      .video-block__play-icon {
        display: block;
        position: absolute;
        margin-left: 2px;
        top: 50%;
        left: 50%;
        transform: translate3D(-50%, -50%, 0);
        stroke: $color-black;
        fill: transparent;
        transition: fill $cubic-200;
      }
    }

    &:hover {
      .video-block__play-icon {
        fill: $color-black;
      }
    }
  }

  &.is-playing {  // todo, the api probably adds is-playing so check this
    .video-block__play {
      display: none;
    }
    a + iframe {
      display: block;
    }
  }

  &.is-iframe {

    .video-block__video {
      padding-bottom: (9/16) *100%;
      background: $color-white;
    }

    .video-block__play {
      display: none;
    }
    iframe {
      display: block;
    }

  }

  iframe {
    display: none; // temp, do the rest with actual videos
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &.no-caption {
    .content-page-transparent & {
      margin-bottom: 35px;
      @include desktop {
        margin-bottom: 45px;
      }
    }
  }

}

.video-caption {
  padding-top: $mobile-half-margin;
  @include desktop {
    padding-top: $desktop-half-margin;
  }
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  @include column_padding(.7,1,1,1);
  @include typography-undertitle;
  @include content-theme(false, false);
  color: $color-grey; // Overwrite undertitle grey color

  .content-page-transparent & {
    margin-bottom: 35px;
    @include desktop {
      margin-bottom: 45px;
    }
  }

  &.last-of-text {
    padding-bottom: 50px;
    margin-bottom: 0;
    @include desktop {
      padding-bottom: 80px;
    }
    .content-page-transparent & {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

}

@include content-opacity;
