
ul.message-area {

  @include container;
  margin-bottom: 20px;
  li {
    background: rgba($color-yellow, 0.5);
    padding: 10px 5px;

    @include typography-paragraph;
    color: $color-black;

    &.error {
      color: $color-red;
      background: $color-white;
      font-weight: bold;
    }

  }

  &.container {
    @include layout(5,10,10,10);
    @include column_padding(0.5,0,0,0);
    @include offset(0.5,1,1,1);
  }

}
.white-p ul.message-area {
  margin-top: 20px;
  margin-bottom: 0;
}

.informing-message {
  margin-top: -10px;
  background: rgba($color-yellow, 0.5);
  padding: 10px;
  strong {
    font-weight: bold;
  }
  @include typography-paragraph;
  color: $color-black;
  
  a {
    background: transparent !important;
  }

}

.informing-message__extra-info-image-small {
  margin: 5px 10px;
  img {
    width: 65px
  }
}

.informing-message--light {
  @include style-richtext;
  display: flex;
  background-color: #FFF9E0;
  
  p {
    margin-bottom: 0px;
  }
}