.page-navigation {
  color: $color-white;
  font-weight: bold;
  @include typography-list();
  @include container();

  .button {
    margin-right: 0;
  }
}

.navigation {
  &_container {
    display: flex;
    align-items: center;
    margin-top: 28px;
    padding-bottom: 20px;
    @include layout(6,12,12,12);

    @include ui-state;
  }

  &_list {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &_logo {
    margin-top: -10px;
    @media all and (min-width: 320px) and (max-width: 380px) {
      width: 32%;
      img  {
        width: 100%;
      }
    }
  }

  &_item {
    display: inline-block;
    margin-right: 60px;

    @include landscape {
      margin-right: 3vw;
    }
    
    .button {
      margin-bottom: 0 !important;
    }

    .button--primary {
      vertical-align: baseline !important;
    }

    &:nth-last-child(-n+3) {
      margin-right: 0;
      margin-left: 10px;
    }

    &--text {
      display: none;
      @include desktop() {
        display: inline-block;
      }
    }

    &.navigation_item_button .button__myteso {
      @include mobile-only() {
        display: none;
      }
    }
  }
}

//hamburger off canvas thing
.hamburger {

  //&.inactive {
  //  display: none;
  //}

  position: fixed;
  z-index: 999;
  right: 0; // prevent the crap transition from firing onload
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 28px 20px 20px 20px;
  background: $color-white;
  color: $color-black;
  transform: translateX(100%);
  opacity: 0;

  @include mobile-only {
    transition: opacity $cubic-250;
  }

  @include portrait {
    transition: transform $cubic-250;
    width: 350px;
    opacity: 1;
  }

  @include desktop {
    padding-top: 40px;
  }

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  .button__icon--before {
    margin-right: 0;
  }

  &_navigation_list {
    margin-left: 60px;

    @include desktop {
      margin-left: 40px;
    }

    &.active-language-nl {
      .lang-nl {
        text-decoration: underline;
      }
    }
    &.active-language-en {
      .lang-en {
        text-decoration: underline;
      }
    }
    &.active-language-de {
      .lang-de {
        text-decoration: underline;
      }
    }

  }

  &_navigation_item {
    &--language {
      display: inline-block;
      margin-top: 40px;
      margin-right: 12px;
    }

    &--webcams {
      position: absolute;
      bottom: 30px;
      padding-left: 32px;
      svg {
        width: 32px;
        height: 32px;
        position: absolute;
        top: -5px;
        left: -5px;
        fill: $color-black;
        path {
          fill: $color-black;
        }
      }

    }

    &--menu {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }
  }

  &_navigation_button {
    &:first-child {
      margin-right: 15px;
    }
  }

  &_wrapper {
    display: flex;
    margin-bottom: 50px;
    justify-content: flex-end;

    @include desktop () {
      justify-content: flex-start;
    }
  }

}

body {
  &:before {
    transition: opacity $cubic-250;
    content: "";
    display: block;
    background: #000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 990;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(-300%);
    transition-delay: 0.2s;
  }
  &.navigation-active {
    &:before {
      opacity: 0.6;
      transform: translateY(0);
    }
    .page-header {
      pointer-events: none;
    }
  }
}
