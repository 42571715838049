.is-visible {
  display: block;
}

.is-hidden {
  display: none;
}

.filter-wrapper {
  width: 100%;
  background-color: gold;
}

.filterbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.filterbar-item {
  @include mobile-only {
    flex-basis: 100%;

    &:nth-child(n+2) {
      padding-top: 35px;
    }
  }

  @include portrait-only {
    flex-basis: 50%;

    &:nth-child(n+3) {
      padding-top: 35px;
    }
  }

  flex-basis: 24%;
}

.filterbar-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filterbar-bottom {

  @include mobile-only {
    padding-top: 35px;
  }

  @include portrait-only {
    padding-top: 35px;
  }

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}

.filter-checkbox {

  @include form-field--checkbox;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label::before {
    padding: 0;
    margin: 0;
  }
}

.filter-label {
  @include typography-paragraph;
  padding-bottom: 5px;
}

.filter-main-label {
  @include typography-h3;
  margin-left: 10px;
}

.filter-button {
  @include typography-button;

  background-color: transparent;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
}

.date-select-button {
  @include typography-paragraph;

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: lighter;

  &::placeholder {
    opacity: 1;
  }

}

.filterbar--button-confirm {
  border: 1px solid black;
  border-radius: 5px;
}

.filterbar--button-download {
  border: 1px solid black;
  border-radius: 5px;
  float: right;
  margin: 10px;
}

.filter__checkbox-label {
  @include typography-paragraph;

  font-weight: normal;
  padding-left: 5px;
  color: black;
}

.filter__checkbox-image {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}

.filter-search {
  @include typography-paragraph;

  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: lighter;

}

.filter--button-datepicker {
  @include typography-paragraph;

  color: black;
}

.toggle__field-checkbox {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.toggle-passes {
  @include typography-paragraph;
}

.filterbar-button-toggle {
  background-color: gold;
  border-radius: 5px;
  margin: 10px;
}

.date-picker-label {
  @include typography-paragraph;
}

.trips__table--no_results {
  @include typography-paragraph;
  color: black;
  padding: 20px 0px;
}
