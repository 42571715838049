.home-item {
  position: relative;
  //display: flex;
  //flex-direction: column;
  margin-bottom: 30px;
  transition: box-shadow $cubic-250;
  height: 358px;
  @include layout(6,6,6,6);
  @include content-theme(false);

  @include landscape {
    //flex-direction: row;
    height: 200px;
  }

  .swiper-slide & {
    width: calc(100% - 40px);
  }

  .button {
    margin-top: 10px;
    margin-right: -10px;
  }

  //&:hover,
  //&:focus {
  //  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, .4);
  //}

  &__title {
    margin-bottom: 20px;
    @include typography-h4;
  }

  &__label {
    @include typography-paragraph;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__figure {
    width: 100%;
    height: 180px;

    @include landscape {
      width: 48%;
      float: left;
      height: 100%;
    }
  }

  &__container {
    padding: 30px 20px;

    @include landscape {
      width: 52%;
      float: left;
    }
  }

  &__hit-target {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
