
.text-block {
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  @include column_padding(.7,1,1,1);
  @include content-theme;

  padding-top: 35px;
  @include desktop {
    padding-top: 45px;
  }

  // NOTE: Not sure if I can scope this, tags were styled at component root level -EKL
  //.rich-text {
    @include style-richtext;
  //}

  &.last-of-text {
    padding-bottom: 50px;
    @include desktop {
      padding-bottom: 80px;
    }
    .content-page-transparent & {
      padding-bottom: 0;
    }
  }
}

.text-block-image {
  padding: 0;
  float: left;
}

.content-page-transparent {
  ul {
    list-style: disc url('/static/icons/icon-bullet-white.svg');
  }
}
