.white-content-block {
  margin-top: 50px;
  border-radius: 6px;
  border: 1px #D3D3D3 solid;
  height: 400px;

  &__freeheight {
    min-height: 400px;
    height: auto;
    margin-bottom: 50px;
  }

  overflow: hidden;
  //display: block;
  @include layout(6, 6, 6, 5);
  @include offset(0, 0, 0, 0.66666);

  @include mobile-only {
    width: 100%;
    margin-left: 0;
    height: auto;
    padding-bottom: 20px;
  }

}

.white-content-block-row {
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 6px;
  border: 1px #D3D3D3 solid;
  height: auto;

  @include layout(6, 12, 12, 10.66666);
  @include offset(0, 0, 0, 0.66666);

  @include mobile-only {
    width: 100%;
    margin-left: 0;
  }

}

.white-content-block-header {
  margin: 15px;
  @include typography-h3;
}

.kenteken-row {
  margin: 5px 15px;
  @include typography-paragraph;
  display: block;
}

.kenteken {
  color: #909090;
  display: inline-block;
  text-transform: uppercase;
}

.settings-row {
  margin: 5px 15px;
}

.teso-checkbox {
  display: inline-block;
}

.teso-checkbox--disbaled {
  opacity: 0.3;
  pointer-events: none;
}

.vinkje {
  position: relative;
  z-index: -999;
}

.vinkje-txt {
  margin-left: 25px;
  display: inline-block;
  @include typography-paragraph;
}

.kenteken-remove {
  float: right;
  color: black;
  display: inline-block;
}

.item-row {
  margin: 5px 15px;
  @include typography-paragraph;
  display: block;
}

.item-name {
  color: #909090;
  display: inline-block;
  width: 40%;
}

.item-info {
  color: black;
  display: inline-block;
  width: 55%;
}

.btn-p {
  margin: 15px;
}

.white-p {
  background: white;
  height: auto;
  @include layout(6, 12, 12, 12);

  @include mobile-only {
    $gutter: _retrieve-neat-setting($mobile-grid, gutter);
    width: 100%;
    margin-left: 0;
    padding-left: $gutter;
    padding-right: $gutter;
  }
  @include portrait-only {
    $gutter: _retrieve-neat-setting($mobile-grid, gutter);
    width: 100%;
    margin-left: 0;
    padding-left: $gutter;
    padding-right: $gutter;
  }

  .teso-loose-tickets & {
    padding: 30px 20px;
    @include portrait {
      padding: 38px 39px;
    }
  }

}

.teso-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
}

.teso-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.teso-vinkje {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color:#D3D3D3 ;
}

.teso-checkbox:hover input ~ .teso-vinkje {
  background-color: #D3D3D3;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.teso-checkbox input:checked ~ .teso-vinkje {
  background-color: #ffd700;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.teso-vinkje:after {
  content: "";
  position: absolute;
  display: none;
}

.teso-checkbox input:checked ~ .teso-vinkje:after {
  display: block;
}

.teso-checkbox .teso-vinkje:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 11px;
  border: solid black;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

