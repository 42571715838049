.button-block {
  padding-bottom: 20px;
  @include layout(6,8,8,8);
  @include offset(0,2,2,2);
  @include column_padding(.7,1,1,1);
  @include content-theme(false);


  padding-top: $mobile-half-margin;
  padding-bottom: $mobile-half-margin;

  @include desktop {
    padding-top: $desktop-half-margin;
    padding-bottom: $desktop-half-margin;
  }

  & + .button-block {
    padding-top: 0;
  }

  &.last-of-text {
    padding-bottom: 50px;
    @include desktop {
      padding-bottom: 80px;
    }
    .content-page-transparent & {
      padding-bottom: 0;
    }
  }
}

.button {
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-decoration: none;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  border-radius: 3px;
  transition: background-color $cubic-250, color $cubic-250;
  @include typography-button;

  @include desktop()  {
    height: 40px;
    line-height: 40px;
  }

  &__text {

    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }

    &--display {
      display: none;
      @include portrait() {
        display: inline-block;
      }
    }
  }

  &__icon {
    &--after {
      margin-left: .7em;
      vertical-align: middle;
      .my-teso-card__button-block & {
       float: right;
      }
    }

    &--before {
      margin-right: 0;
      vertical-align: sub;
      @include desktop() {
        margin-right: .7em;
      }
    }

    &--white {
      svg {
        fill: $color-white;
      }
    }
  }

  &--primary {
    background-color: $color-yellow;
    color: $color-black;
    @include arrow($color-black, $color-yellow);
  }

  &--transparent {
    border: 1px solid $color-white;
    color: $color-white;
    @include arrow($color-white, $color-black);

    line-height: 48px; // mitigate the borders
    @include desktop()  {
      line-height: 38px;
    }

  }

  &--stroke {
    @include stroke($color-black, $color-white);
    div.button-small--icon {
      display: inline-block;
      vertical-align: initial;
    }
  }

  &--transparent--reverse {
    border: 1px solid $color-black;
    color: $color-black;
    padding: 0 20px;
    @include arrow($color-black, $color-white);
  }

  &__myteso {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: 360px) {
      max-width: 136px;
    }
    @media (min-width: 380px) {
      max-width: 146px;
    }
    @include landscape {
      max-width: 200px;
    }
  }
}
