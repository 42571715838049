.white{
  @include landscape {
    @include layout(6, 12, 12, 12);
  }

  height: auto;
  background: $color-white;
  padding: 20px;
}

.teso-single-cards-list{
  list-style: none;
  padding: 0;
  font-size: 0;
  background: white;
  height: 400px;
  display: inline-block;
  @include layout(6, 12, 12, 12);
}
.teso-single-card{
  text-align: left;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  position: relative;
  height: 100%;
  vertical-align: top;
  border-radius: 6px;
  border: 1px solid #D3D3D3;
  background-color: white;
  display: inline-block;
  @include layout(6, 6, 3.5, 4);
}
.single-card-content{
  display: inline-block;
  width:100%;
  height: 100%;
}
.single-card-header{
  display: inline-block;
 @include typography-h4;
}
.qr-los{
  display: inline-block;
  width: 20px;
  margin: 0;
  height: 30px;
  background: red;
}

.teso-loose-tickets .white {
  @include portrait {
    @include layout(6, 12, 12, 12);
  }

  height: auto;
}
