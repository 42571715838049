.date_block {
  background-color: $color-white;
  padding: 50px 0;
  @include layout(6,12,12,12);
  @include offset(0,0,0,0);
  @include column_padding(.7,1,1,1);
  @include typography-paragraph();

  //&_container {
  //  padding-top: 50px;
  //  padding-bottom: 50px;
  //  border: 1px solid $color-light-grey;
  //  @include column_padding(.7,1,1,1.5);
  //}

  &_container {
    display:flex;
    justify-content: space-between;
    @media only screen and (max-width: 850px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &_title {
    padding-bottom: 40px;
    @include typography-h4();
    margin-bottom: 0;
  }
  &_legend_title {
    font-weight:bold;
  }

  &_legend_time {
    span {
      border-radius: 3px;
      width: 22px;
      height: 12px;
      margin-right: 10px;
      display: inline-block;
      margin-bottom: -1px;
    }

    &_none {
      border: 1px solid $color-light-grey;
    }

    &_low {
      background: $color-wait-low;
    }

    &_medium {
      background: $color-wait-medium;
    }

    &_high {
      background: $color-wait-high;
    }
  }



  .date_schedule_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;

    @include mobile-only {
      flex-direction: column;
      gap: 0;
    }
  }

  .date_schedule_container_text {

    @include mobile-only {
      width: 100%;
    }
  }

  &_times {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    width: 50%;

    @include mobile-only {
      display: block;
      width: 100%;
    }

    p {
      min-width: 150px;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &--info {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &--label {
      @include typography-h3;
      margin-bottom: 15px;
    }

    &--notification {
      font-style: italic;
      margin-bottom: 30px;
      margin-right: 20px;

      &:before {
        content: '*';
        color: $color-yellow;
        font-style: normal;
      }
    }

    ul {
      &>.departure_time--wait-05u{
        time {
          background: linear-gradient(to right, transparent, $color-wait-low);
        }
        &~.departure_time--wait-05u time {
          background: $color-wait-low;
        }
      }

      &>.departure_time--wait-1ur,
      &>.departure_time--wait-05-{
        time {
          background: linear-gradient(to right, transparent, $color-wait-medium);
        }
        &~.departure_time--wait-1ur time,
        &~.departure_time--wait-05- time{
          background: $color-wait-medium;
        }
      }

      &>.departure_time--wait-15u,
      &>.departure_time--wait-2ur,
      &>.departure_time--wait-2-3{
        time {
          background: linear-gradient(to right, transparent, $color-wait-high);
        }
        &~.departure_time--wait-15u time,
        &~.departure_time--wait-2ur time,
        &~.departure_time--wait-2-3 time{
          background: $color-wait-high;
        }
      }

      li {
        width: 50px;

        time {
          border-radius: 3px;
          padding: 2px 5px;
        }

        &.departure_time--wait-05{
          + .departure_time--normal:not(.departure_time--wait-05),
          + .departure_time--extra:not(.departure_time--wait-05) {
            time {
              background: linear-gradient(to right, $color-wait-low, transparent);
            }
          }
        }

        &.departure_time--wait-1u {
          + .departure_time--normal:not(.departure_time--wait-1u),
          + .departure_time--extra:not(.departure_time--wait-1u) {
            time {
              background: linear-gradient(to right, $color-wait-medium, transparent);
            }
          }
        }

        &.departure_time--wait-15 {
          + .departure_time--normal:not(.departure_time--wait-15),
          + .departure_time--extra:not(.departure_time--wait-15) {
            time {
              background: linear-gradient(to right, $color-wait-high, transparent);
            }
          }
        }

        &.departure_time {
          &--extra {
            //nothing extra for style here
          }

          &--risico, &--notification {
            span {
              position: relative;
              top: -0.5em;
              margin-left: -3px;
              font-size: 80%;
            }
          }

          &--risico {
            text-decoration: line-through;
          }

          &--notification {
            span {
              color: $color-yellow;
            }
          }
        }

      }
    }
  }

  &_message {
    color: $color-grey;
  }

  &_form {
    margin-bottom: 30px;

    &--title {
      float: none;
      margin-right: 20px;
      margin-bottom: 20px;

      @include desktop() {
        float: left;
      }
    }

    .flatpickr,
    &--button {
      width: 160px;
      border-radius: 3px;
      background: $color-white;
      padding: 10px;
      border: 1px solid $color-light-grey;
      transition: border $cubic-250;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      @include typography-button();
      outline: 0;
      &:focus,
      &:hover,
      &.selected {
        border: solid 1px $color-yellow;
        background-color: $flatpickr-grey;
      }

      @include landscape() {
        margin-bottom: 0;
      }
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .date_block_form--button {
    margin-right: 10px;
    outline: 0;
    &:focus,
    &:hover,
    &.selected {
      border: solid 1px $color-yellow;
      background: $flatpickr-grey;
    }
  }

  .flatpickr {
    width: 160px;
    background: url('/static/icons/icon-calendar.svg') no-repeat scroll 30px 10px;
    padding-left: 30px;

    @include mobile-only {
      width: 100%;
    }

    &::placeholder {
      color: $color-black;
    }

    &[type='date']:after {  //flatpickt workaround for date type not showing placeholder.. stupid browsers https://github.com/flatpickr/flatpickr/issues/1103
      content: attr(placeholder);
    }

  }
}

.message-block {
  color: $color-red;
  margin-bottom: 40px;

  .message-block_container {
    display:flex;
  }

  .message-block_icon {
    width:45px;
    height:45px;
    margin-right: 15px;
    display:flex;
    align-items: center;
    svg {
      path {
        fill: $color-red;
      }

      circle {
        fill: $color-red;
      }
    }
  }
  .message-block_message {
    display: flex;
    align-items: center;
    a {
      text-decoration: underline;
    }
  }
}



.date_info {
  .info-block {
    overflow: hidden;
    margin-bottom: 16px;
  }
  .info-icon {
    display: inline-block;
    width: 45px;
    margin-right: 15px;
    vertical-align: top;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: $color-yellow;
      }
    }
  }

  p {
    display: inline-block;
    vertical-align: top;
    @include typography-paragraph;
    line-height: 18px;
    max-width: calc(100% - 80px);
  }


  .live-block {
    overflow: hidden;
    margin-bottom: 16px;
    display: flex;
  }
  .live-icon {
    display: inline-block;
    flex: 1 0 45px;
    width: 45px;
    height: 45px;
    margin-right: 15px;
    vertical-align: top;
    position: relative;

    .live-icon-inner {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $color-yellow;
      border-radius: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .live-icon-outer {
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 1px solid $color-yellow;
      border-radius: 35px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation: ping 2s infinite;
    }
    @keyframes ping {
      0% {width: 8px; height: 8px; opacity: 0;}
      33% {opacity: 1;}
      66% {opacity: 1;}
      100% {width: 35px; height: 35px; opacity: 0;}
    }
  }
  .live-text {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &--times-info {
    margin-top: auto;
  }
}

/* druktekalender */
.popular-times {

  background-color: $color-white;
  padding: 50px 0;
  @include layout(6, 12, 12, 12);
  @include offset(0, 0, 0, 0);
  @include column_padding(.7, 1, 1, 1);
  font-size: 0;

  h3 {
    @include typography-h3;
  }

  p {
    @include typography-paragraph();
    margin-bottom: 50px;
    max-width: 50%;

    @include landscape-down {
      max-width: 100%;
    }
  }

  .busy_block_times_table_wrapper {

    @include mobile-portrait-only {
      margin-left: -5%;
    }

    @include mobile-only {
      margin-left: -7%;
    }

    @include portrait-only {
      margin-left: -20%;
    }

    @include landscape-only {
      margin-left: -20%;
    }
  }

  .busy_block_times {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;

    @include desktop {
      width: 50%;
      padding-right: 10%;
    }

    .weekday {
      display: inline-block;
      text-align: right;
      vertical-align: top;
      @include typography-button;
      width: 24%;
      height: 24px;
      line-height: 24px;
      padding-right: 20px;

      &.nodim {
        height: auto;
        line-height: inherit;
      }
    }

    .day_hour {
      @include typography-button--small;

      @include desktop {
        width: 76/8 * 1%;
      }

      @include landscape-down {
        transform: rotate(-45deg);
        transform-origin: left;
        margin-top: 20px;
        margin-bottom: 5px;
      }

      display: inline-block;
      vertical-align: top;
      width: 76/8 * 1%;
      height: 16px;
      line-height: 16px;

      &.left_offset_s {
        margin-left: -5px;
      }

      &.left_offset {
        margin-left: -5px;
      }

      &:last-child {
        position: absolute;
      }
    }

    .line {
      display: inline-block;
      vertical-align: top;
      width: 76/8 * 1%;
      height: 16px;
      border-left: 1px solid #d8d8d8;

      &:last-child {
        border-right: 1px solid #d8d8d8;
      }
    }

    .split .line {
      height: 5px;
      margin-top: -5px;
    }

    .duration {
      height: 24px;
      line-height: 24px;
      display: inline-block;
      vertical-align: top;
      width: 76%;

      @include desktop {
        width: 76%;
      }

      position: relative;
      background: $flatpickr-grey;

      dd {
        position: absolute;
        height: 100%;
        background: $color-yellow;
        top: 0;
        margin-left: 1px;
        &:before {
          content: "";
          position: absolute;
          left: -14px;
          width: 15px;
          height: 100%;
          background: linear-gradient(to right, rgba($color-yellow,0), rgba($color-yellow,1) 100%);
          border-right: 1px solid $color-white;
        }
        &:after {
          content: "";
          position: absolute;
          right: -15px;
          width: 16px;
          height: 100%;
          background: linear-gradient(to left, rgba($color-yellow,0), rgba($color-yellow,1) 100%);
          border-left: 1px solid $color-white;
        }
      }
    }

  }

}

// Styling overrides for the iframe version of the date block
.date_block--iframe {
    margin-right: 20px;

  .date_block_times {
    margin-bottom: 0;
  }
}
