a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.link {
  &_underline {
    @include underline($color-white);
  }

  &_underline--dark {
    @include underline($color-black);
  }
}
