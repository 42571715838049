
@mixin style-richtext {
  h1 {
    @include typography-h1;
  }

  h2 {
    @include typography-h2;
    @include vertical_margin($mobile-half-margin, $desktop-half-margin);
  }

  h3 {
    @include typography-h3;
    @include vertical_margin($mobile-half-margin, $desktop-half-margin);
  }

  h4 {
    @include typography-h4;
    @include vertical_margin($mobile-half-margin, $desktop-half-margin);
  }

  p {
    @include typography-paragraph;
    @include vertical_margin($mobile-half-margin, $desktop-half-margin);

    & + h2,
    & + h3,
    & + h4 {
      padding-top: $mobile-half-margin;

      @include desktop {
        padding-top: $desktop-half-margin;
      }
    }
  }

  ul {
    margin: 1em;
    font-weight: 500;
    list-style: disc;
    list-style: disc url('/static/icons/icon-bullet-black.svg');

    @include typography-list;

    li {
      padding: .5em 1em;
    }
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid;
    transition: border-bottom $cubic-200;

    @include content-theme(false);

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}
