
.my-teso-menu {
  @include layout(6, 12, 12, 12);

  display: none;

  @include landscape {
    display: flex;
    flex-direction: row;
  }

  background-color: $color-white;
  list-style: none;
  height: 60px;

  &__item {
    flex: 1;
    white-space: nowrap;
    background: $color-white;
    border: 1px solid #D3D3D3;
    border-top: none;
    border-left: none;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      border-color: $color-yellow;
      background: $color-yellow;
      &:last-of-type {
        border-right: 1px solid $color-yellow;
      }
    }

    &:last-of-type {
      border-right: 1px solid $color-white;
    }

    &--active {
      background: $color-yellow;
      border-color: $color-yellow;
    }

    a {
      @include typography-undertitle;

      display: block;
      height: 100%;
      padding: 16px 0 19px 18px;
      width: 100%;
    }
  }

  &--mobile {

    display: block;

    @include landscape {
      display: none;
    }

    background-color: $color-white;
    flex-direction: row;
    list-style: none;
    padding: 15px 20px;
  }
}
