
.my-teso-cards {
  //min-height: 440px;
  @include clearfix;
}

.my-teso-card {
  @include layout(6, 6, 3.5, 3);

  border: 1px solid $color-white;
  display: inline-block;
  font-size: 16px;
  height: 440px;
  margin: 0;
  margin-top: 60px; // same as the fixed padder
  position: relative;
  text-align: left;
  vertical-align: top;

  @include mobile-only {
    margin-top: 20px; // to change
    &::after {
      content: '';
      position: absolute;
      top: -21px;
      right: -21px;
      background: $color-white;
      height: 110%;
      width: 20px;
    }
  }

  &.filler-landscape,
  &.filler-portrait {
    display: none;
  }

  @include grid-media($portrait-grid) {
    margin-bottom: 0px;
    &.filler-portrait {
      display: block;
    }
  }
  @include grid-media($landscape-grid) {
    margin-bottom: 0;

    &.filler-landscape {
      display: block;
    }
    &.filler-portrait {
      display: none;
    }

  }

  &__content {
    @include typography-paragraph;

    padding: 18px 20px 20px 20px;
    border: 1px solid $color-light-grey;
    border-radius: 6px;
    box-sizing: border-box;
    height: 100%;

    .card_ticket {
      clear: both;
      margin-bottom: 10px;
      height: 21px;
      overflow: hidden;
      .credits {
        float: right;
        width: 3em;
        margin-left: 12px;
        text-align: right;
      }
      .ticket_name {

      }

    }

  }

  &__qrcode {
    clear: both;
    text-align: left;
    padding: 10px 0px;
    position: relative;
    height: 120px;
    z-index: 8;

    .qr_inner {
      width: 50%;
      padding-bottom: 50%;
      position: relative;
      z-index: 5;

      .zoom-icon {
        position: absolute;
        @include portrait {
          display: none; // not on no phone devices
        }
        top: 0px;
        width: 100%;
        height: 100%;
        max-height: 100px;
        max-width: 100px;
        transition: opacity $easing-change;
        transition-delay: $easing-change;
        opacity: 1;
        svg {
          background: transparent;
          width: 32px;
          height: 32px;
          position: absolute;
          left: 10px;
          top: -32px;
        }
        transform: translate(100%, 100%);
      }

    }

    svg {
      background: $color-white;
      width: 100%;
      height: 100%;
      max-height: 100px;
      max-width: 100px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 0;
      transition: transform $easing-change, left $easing-change;
      transform-origin: 0% 0%;
    }

    &.zoomed {
      z-index: 10;
      svg {
        transform: scale(2) translateX(0%);
        left: 0%;
        max-height: 500px;
        max-width: 500px;
      }
      .zoom-icon {
        opacity: 0;
        transition: opacity 10ms;
        transition-delay: 0ms;
      }
      &:before {
        display: block;
        content: "";
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $color-black;
        position: fixed;
        opacity: 0.4;
        z-index: 3;
      }
    }

  }

  .card-head {
    display: block;
    margin-bottom: 5px;
  }

  &::before {
    background: transparent;
    border-radius: 6px;
    box-shadow: 0 0 0 6px $color-white;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--white {
    background-color: $color-white;

    h4 {
      margin-bottom: 30px;
    }

  }

  &--transparent {
    background-image: url("/static/images/logo-teso.svg");
    background-repeat: no-repeat;
    background-size: 50px 20px;
    background-position: bottom 25px right 20px;
    color: $color-white;
  }

  &__container {

    background-color: transparent;
    height: 100%;
    position: relative;

    &::after {
      content: '';
      width: 10px;
      position: absolute;
      height: 101%;
      background-color: $color-white;
      top: -1px;
      right: -11px;
    }

    &::before {
      content: '';
      width: 20px;
      position: absolute;
      height: 101%;
      background-color: $color-white;
      top: -1px;
      left: -21px;
    }

    @include mobile-only {
      &::before {
        top: -21px;
        left: -21px;
        width: 20px;
        height: 110%;
        background-color: $color-white;
      }

      &::after {
        top: -21px;
        left: -2px;
        height: 20px;
        background-color: $color-white;
        width: 102%;
      }
    }
    @include grid-media($portrait-grid) {

      &::after {
        content: '';
        width: 21px;
        position: absolute;
        height: 115%;
        background-color: $color-white;
        top: -61px;
        right: -21px;
      }

      &::before {
        content: '';
        width: 21px;
        position: absolute;
        height: 115%;
        background-color: $color-white;
        top: -61px;
        left: -21px;
      }
    }

    @include grid-media($landscape-grid) {
      background-color: transparent;
      height: 100%;
      position: relative;
        &::after {
          content: '';
          width: 10px;
          position: absolute;
          height: 101%;
          background-color: white;
          top: -1px;
          right: -11px;
        }

        &::before {
          content: '';
          width: 20px;
          position: absolute;
          height: 101%;
          background-color: white;
          top: -1px;
          left: -21px;
        }
    }
  }

  &__details {
    float: left;
    width: 100%;
  }
  &__name {
    float: left;
  }
  &__birthday {
    float: right;
  }

  &__text {
    color: $color-grey;

    &--transparent {
      color: $color-white;
      font-weight: bold;
    }
  }

  h3 {
    @include typography-h4();
  }
  .borderline {
    font-size: 0;
    border-bottom: 1px solid $color-grey;
    margin-bottom: 15px;
  }

  &__travel {
    color: $color-grey;
    padding-bottom: 5px;
  }

  &__button-block {
    bottom: 20px;
    left: 0;
    margin-left: 20px;
    margin-right: 20px;
    position: absolute;
    z-index: 2;

    .button {
      width: 100%;
      margin-bottom: 16px;

      &__icon {
        position: absolute;
        right: 20px;
      }
    }
  }

  &__menu-item {
    @include typography-undertitle;

    display: inline-block;
    border-bottom: 1px solid $color-light-grey;
    padding-bottom: 14.5px;
    margin-bottom: 12.5px;
    &:last-of-type {
      border: none;
    }
    @include mobile-only {
      display: block;
    }
  }

}
