.page-footer {
  margin: 100px 0 40px;
  color: $color-white;
  @include container;
  @include typography-paragraph;
}

.footer {
  &_container {
    display: flex;
    align-items: center;
    @include layout(6,12,12,12);
  }

  &_quote {
    margin-left: auto;
    display: none;
    @include typography-h4();
    @include desktop() {
      display: block;
    }
  }

  &_item {
    display: inline-block;
    margin-right: 1.5em;
  }
}
