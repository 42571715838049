.popular-times-button {
  @include landscape-down {
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }

  padding: 0;
  margin-right: 10px;
}

.date_schedule_explained {
  width: 100%;
}
