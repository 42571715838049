.cro-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  width: 100%;
}

.usp-text {
  padding: 40px 25px 0 10px;
}

.usp-item-wrapper {
  display: flex;
  flex-direction: row;
}

.usp-checkmark {
  margin-top: 2px;
  margin-right: 2px;
}

.usp-bullet-title {
  font-weight: bold !important; // override global style of TESO
}

.usp-basket-container {
  grid-column-start: 0;
  padding-left: 20px;
  z-index: 20;
  height: 100%;
}

.ticket-container {
  grid-column-start: 2;
  grid-column-end: 7;
}

.usp-variant {
  background-color: #f9f9f9;
  margin-right: -40px;
  height: 100%;
  padding: 15px;
}

.usp-item {
  padding-top: 15px;
}

.usp-title {
  font-weight: bold;
}

.basket-contents {
  padding: 10px;
}

.basket-list {
  list-style: none;
}

.basket-item {
  padding-top: 4px;
  padding-left: 18px;
}

.check-mark {
  margin-right: 5px;
}

// big variant, usps are rendered on the left
@media only screen and (min-width: 1080px) {
  .usp-text {
    padding-top: 20px;
  }

  .usp-variant__usp-mobile-only {
    display: none;
  }
}

// smaller variant, on top
@media only screen and (max-width: 1079px) {
  .cro-container {
    display: block;
  }

  .usp-container {
    display: block;
  }

  .usp-variant {
    width: calc(100% - 20px);
  }

  .usp-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: calc(16.66667% - 23.33333px + 20px);
  }

    .usp-variant__usp-desktop-only {
    display: none;
  }
}

// match padding calculation of form contents
@media only screen and (max-width: 899px) {
  .usp-text {
    padding-left: calc(12.5% - 22.5px + 20px);
  }
}

@media only screen and (max-width: 559px) {
  .usp-text {
    padding-left: 10px;
  }
}
