
@mixin form-block {
  @include content-theme;

  @include mobile {
    padding-bottom: 50px;
    padding-top: 35px;
    position: relative;
    //width: 100%;

    .form__fieldset-group {
      padding: 20px 0;

      + .form__fieldset-group {
        margin-top: 4px;
      }
    }

    .rich-text {
      + .form__row {
        margin-top: 30px;
      }
    }

    .errorlist {
      @include typography-list;

      font-weight: 600;
      color: $color-red;
      border-top: 2px solid currentColor;
    }

    .totalfee {
      @include typography-list;

      font-weight: 600;
      border-top: 2px solid currentColor;
      text-align: right;
    }
  }

  @include desktop {
    padding-bottom: 80px;
    padding-top: 45px;
  }
}

@mixin form__fieldset {
  @include clearfix;

  .form__legend {
    @include typography-intro;
  }

  .form__text {
    @include typography-list;
  }

  @include mobile {
    clear: both;

    .form__legend {
      display: block;
      float: left;
      width: 100%;
    }

    + .form__fieldset {
      margin-top: 20px;
    }
  }
}

@mixin form__row {
  @include clearfix;@include clearfix;

  @include mobile {
    clear: both;

    &.form__row--footer {
      @include clearfix;

      display: block;
      margin-top: 20px;
      flex-direction: row-reverse;
      font-size: 0;

      .button {
        min-width: 50px;
      }

      .button--submit,
      .button--skip {
        float: right;
      }

      .button--back {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .button {
      margin: 0;
      min-width: 25%;
    }

    .form__links {
      flex: 1 1 100%;
    }

    &--right {
      text-align: right;
    }

  }

  @include mobile-only {
    .form-field {
      + .form-field {
        margin-top: 10px;
      }
    }

    .button {
      margin-top: 10px;
      min-width: 100%;
    }
  }

  @include landscape {
    display: flex;
    flex-direction: row;

    .form-field {
      flex-grow: 2;
      min-width: 10%;
    }

    .button {
      margin-bottom: 0;
    }
  }
}

@mixin form-block-layout {
  @include layout(6, 12, 12, 12);
  @include column-padding-with-gutter(0, 1.5, 2, 2.5);

  @include mobile-only {
    $gutter: _retrieve-neat-setting($mobile-grid, gutter);

    padding-left: $gutter;
    padding-right: $gutter;
  }
}

.form-block {
  @include form-block-layout;
  @include form-block;

  .form__fieldset {
    @include form__fieldset;
  }

  .form__row {
    @include form__row;

    .no-margin {
      margin: 0 !important;
      height: 0;
    }

  }

  .rich-text {
    @include style-richtext;
    h2{
      margin-bottom: 50px;
    }
  }
}
