
@mixin layout($mobile-columns: $mobile-columns-count, $portrait-columns: $portrait-columns-count, $landscape-columns: $landscape-columns-count, $desktop-columns: $desktop-columns-count) {
  @include grid-media($mobile-grid) {
    @include grid-column($mobile-columns);
  }
  @include grid-media($portrait-grid) {
    @include grid-column($portrait-columns);
  }
  @include grid-media($landscape-grid) {
    @include grid-column($landscape-columns);
  }
  @include grid-media($desktop-grid) {
    @include grid-column($desktop-columns);
  }
}

@mixin offset($mobile-offset: 0, $portrait-offset: 0, $landscape-offset: 0, $desktop-offset: 0) {
  @include grid-media($mobile-grid) {
    @include grid-push($mobile-offset, $mobile-grid);
  }
  @include grid-media($portrait-grid) {
    @include grid-push($portrait-offset, $portrait-grid);
  }
  @include grid-media($landscape-grid) {
    @include grid-push($landscape-offset, $landscape-grid);
  }
  @include grid-media($desktop-grid) {
    @include grid-push($desktop-offset, $desktop-grid);
  }
}

// mixin to pad a container with X column widths
@mixin column_padding($mobile-padding: 0, $portrait-padding: 0, $landscape-padding: 0, $desktop-padding: 0) {
  @include grid-media($mobile-grid) {
    padding-left: calc(#{_neat-column-width($mobile-grid, $mobile-padding)});
    padding-right: calc(#{_neat-column-width($mobile-grid, $mobile-padding)});
  }
  @include grid-media($portrait-grid) {
    padding-left: calc(#{_neat-column-width($portrait-grid, $portrait-padding)});
    padding-right: calc(#{_neat-column-width($portrait-grid, $portrait-padding)});
  }
  @include grid-media($landscape-grid) {
    padding-left: calc(#{_neat-column-width($landscape-grid, $landscape-padding)});
    padding-right: calc(#{_neat-column-width($landscape-grid, $landscape-padding)});
  }
  @include grid-media($desktop-grid) {
    padding-left: calc(#{_neat-column-width($desktop-grid, $desktop-padding)});
    padding-right: calc(#{_neat-column-width($desktop-grid, $desktop-padding)});
  }
}

@mixin column-padding-with-gutter($mobile-padding: 0, $portrait-padding: 0, $landscape-padding: 0, $desktop-padding: 0, $factor:1) {

  $portrait-padding:$portrait-padding*$factor;
  $mobile-padding:$mobile-padding*$factor;
  $landscape-padding:$landscape-padding*$factor;
  $desktop-padding:$desktop-padding*$factor;

  @include grid-media($mobile-grid) {
    $gutter: _retrieve-neat-setting($mobile-grid, gutter);
    $gutter: $gutter*$factor;
    padding-left: calc(#{_neat-column-width($mobile-grid, $mobile-padding)} + #{$gutter});
    padding-right: calc(#{_neat-column-width($mobile-grid, $mobile-padding)} + #{$gutter});
  }

  @include grid-media($portrait-grid) {
    $gutter: _retrieve-neat-setting($portrait-grid, gutter);
    $gutter: $gutter*$factor;
    padding-left: calc(#{_neat-column-width($portrait-grid, $portrait-padding)} + #{$gutter});
    padding-right: calc(#{_neat-column-width($portrait-grid, $portrait-padding)} + #{$gutter});
  }

  @include grid-media($landscape-grid) {
    $gutter: _retrieve-neat-setting($landscape-grid, gutter);
    $gutter: $gutter*$factor;
    padding-left: calc(#{_neat-column-width($landscape-grid, $landscape-padding)} + #{$gutter});
    padding-right: calc(#{_neat-column-width($landscape-grid, $landscape-padding)} + #{$gutter});
  }

  @include grid-media($desktop-grid) {
    $gutter: _retrieve-neat-setting($desktop-grid, gutter);
    $gutter: $gutter*$factor;
    padding-left: calc(#{_neat-column-width($desktop-grid, $desktop-padding)} + #{$gutter});
    padding-right: calc(#{_neat-column-width($desktop-grid, $desktop-padding)} + #{$gutter});
  }
}

//mixin for generic margins
@mixin vertical_margin($mobile-margin: 0, $desktop-margin: 0) {
  @include grid-media($mobile-grid) {
    margin-bottom: $mobile-margin;
  }
  //@include grid-media($portrait-grid) {
  //  margin-bottom: $desktop-margin;
  //}
  @include grid-media($landscape-grid) {
    margin-bottom: $desktop-margin;
  }
  //@include grid-media($desktop-grid) {
  //  @include grid-push($desktop-offset, $desktop-grid);
  //}
}
//mixin for generic paddings
@mixin vertical_padding($mobile-margin: 0, $desktop-margin: 0) {
  @include grid-media($mobile-grid) {
    padding-bottom: $mobile-margin;
  }
  @include grid-media($landscape-grid) {
    padding-bottom: $desktop-margin;
  }
}

@mixin container {
  @include clearfix;
  margin-right: auto;
  padding: 0;

  @include grid-media($mobile-grid) {
    float: none;
    max-width: $mobile-max-width;
  }

  @include grid-media($portrait-grid) {
    float: none;
    max-width: $portrait-max-width;
  }

  @include grid-media($landscape-grid) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: $landscape-max-width;
    width: calc(100% - #{2 * $landscape-gutter});
  }

  @include grid-media($desktop-grid) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: $desktop-max-width;
    width: 100%;
  }

  @if $show-grid-visual == true {
    @include grid-media($mobile-grid) {
      @include grid-visual(rgba(50,234,234,.1), $mobile-grid);
    }

    @include grid-media($portrait-grid) {
      @include grid-visual(rgba(234,04,234,.1), $portrait-grid);
    }

    @include grid-media($landscape-grid) {
      @include grid-visual(rgba(34,234,34,.1), $landscape-grid);
    }

    @include grid-media($desktop-grid) {
      @include grid-visual(rgba(234,34,34,.1), $desktop-grid);
    }
  }
}

@mixin gutterless-container {
  @include clearfix;
  margin-right: auto;
  padding: 0;

  @include grid-media($mobile-grid--gutterless) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{$mobile-max-width} - #{4 * $mobile-gutter});
  }
  @include grid-media($portrait-grid--gutterless) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{$portrait-max-width} - #{4 * $portrait-gutter});
  }
  @include grid-media($landscape-grid--gutterless) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: $landscape-max-width - 2 * $landscape-gutter;
    width: calc(100% - #{4 * $landscape-gutter});
  }
  @include grid-media($desktop-grid--gutterless) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: $desktop-max-width - 2 * $desktop-gutter;
    width: calc(100% - #{4 * $desktop-gutter});
  }

  @if $show-grid-visual == true {
    @include grid-media($mobile-grid--gutterless) {
      @include grid-visual(pink, $mobile-grid--gutterless);
    }
    @include grid-media($portrait-grid--gutterless) {
      @include grid-visual(yellow, $portrait-grid--gutterless);
    }
    @include grid-media($landscape-grid--gutterless) {
      @include grid-visual(green, $landscape-grid--gutterless);
    }
    @include grid-media($desktop-grid--gutterless) {
      @include grid-visual(blue, $desktop-grid--gutterless);
    }
  }
}

@mixin gutterless-layout($mobile-columns: $mobile-columns-count, $portrait-columns: $portrait-columns-count, $landscape-columns: $landscape-columns-count, $desktop-columns: $desktop-columns-count, $max-width: false) {
  @include grid-media($mobile-grid--gutterless) {
    @include grid-column($mobile-columns);
    @if $max-width {
      max-width: calc(#{_neat-column-width($mobile-grid--gutterless, $mobile-columns)});
    }
  }
  @include grid-media($portrait-grid--gutterless) {
    @include grid-column($portrait-columns);
    @if $max-width {
      max-width: calc(#{_neat-column-width($portrait-grid--gutterless, $portrait-columns)});
    }
  }
  @include grid-media($landscape-grid--gutterless) {
    @include grid-column($landscape-columns);
    @if $max-width {
      max-width: calc(#{_neat-column-width($landscape-grid--gutterless, $landscape-columns)});
    }
  }
  @include grid-media($desktop-grid--gutterless) {
    @include grid-column($desktop-columns);
    @if $max-width {
      max-width: calc(#{_neat-column-width($desktop-grid--gutterless, $desktop-columns)});
    }
  }
}

@mixin gutterless-offset($mobile-columns: $mobile-columns-count, $portrait-columns: $portrait-columns-count, $landscape-columns: $landscape-columns-count, $desktop-columns: $desktop-columns-count) {
  @include grid-media($mobile-grid--gutterless) {
    @include grid-push($mobile-columns, $mobile-grid--gutterless);
  }
  @include grid-media($portrait-grid--gutterless) {
    @include grid-push($portrait-columns, $portrait-grid--gutterless);
  }
  @include grid-media($landscape-grid--gutterless) {
    @include grid-push($landscape-columns, $landscape-grid--gutterless);
  }
  @include grid-media($desktop-grid--gutterless) {
    @include grid-push($desktop-columns, $desktop-grid--gutterless);
  }
}

@mixin gutterless-offset-without-calc($mobile-offset: 0, $portrait-offset: 0, $landscape-offset: 0, $desktop-offset: 0) {
  @include grid-media($mobile-grid--gutterless) {
    $_margin-value: #{_neat-column-width($mobile-grid--gutterless, $mobile-offset)};
    margin-#{_neat-float-direction($mobile-grid--gutterless)}: $_margin-value;
  }
  @include grid-media($portrait-grid--gutterless) {
    $_margin-value: #{_neat-column-width($portrait-grid--gutterless, $portrait-offset)};
    margin-#{_neat-float-direction($portrait-grid--gutterless)}: $_margin-value;
  }
  @include grid-media($landscape-grid--gutterless) {
    $_margin-value: #{_neat-column-width($landscape-grid--gutterless, $landscape-offset)};
    margin-#{_neat-float-direction($landscape-grid--gutterless)}: $_margin-value;
  }
  @include grid-media($desktop-grid--gutterless) {
    $_margin-value: #{_neat-column-width($desktop-grid--gutterless, $desktop-offset)};
    margin-#{_neat-float-direction($desktop-grid--gutterless)}: $_margin-value;
  }
}

@mixin gutterless-push($columns, $grid) { /* Allows for transitions in IE */
  $_margin-value: #{_neat-column-width($grid, $columns)};
  margin-#{_neat-float-direction($grid)}: $_margin-value;
}
